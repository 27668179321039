import { useMsal } from '@azure/msal-react'
import {
  acquireAccessToken,
  CenteredDiv,
  CustomDataGrid,
  DownloadButton,
  ErrorMessage,
  parseJwt,
  RegularButton,
  useAuthContext,
  PersistantFilterDiv
} from '@wavetronix/common-components'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import UpdatesApi from '../../api/UpdatesApi'
import { env } from '../../index.js'
import compareAlphabetically from '../../utils/compareAlphabetically'
import getDeviceName from '../../utils/getDeviceName'
import UpdatesFilterDrawer, { DEFAULT_UPDATES_FILTER, filterUpdates } from '../drawers/UpdatesFilterDrawer'
import ConfirmModal from '../modals/ConfirmModal'
import EditUpdatesModal from '../modals/EditUpdatesModal'
import UpdateModal from '../modals/UpdateModal'
import UpdatesOverridesModal from '../modals/UpdatesOverridesModal'

export default function UpdatesPage(props) {
  const { user } = useAuthContext()
  const [mouseIsOverDownloadButton, setMouseIsOverDownloadButton] = useState(false)
  const { instance, accounts } = useMsal()
  const [downloadToken, setDownloadToken] = useState('')

  useEffect(() => {
    async function resetDownloadToken() {
      let token = await acquireAccessToken(instance, accounts, env)
      setDownloadToken(token)
    }

    if (!downloadToken || parseJwt(downloadToken).exp < Date.now() / 1000) {
      console.log('resetting token')
      resetDownloadToken()
    }
  }, [accounts, instance, downloadToken])

  const columns = [
    {
      field: 'baseProductId',
      headerName: 'Type',
      flex: 1,
      disableColumnMenu: true,
      sortComparator: compareAlphabetically,
      valueGetter: u => getDeviceName(u.value)
    },
    {
      field: 'itemNumber',
      headerName: 'Item No.',
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'version',
      headerName: 'Version No.',
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'releasePhase',
      headerName: 'Release Phase',
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'launchPhase',
      headerName: 'Launch Phase',
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'region',
      headerName: 'Region',
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'createdOn',
      headerName: 'Created On',
      flex: 1,
      disableColumnMenu: true,
      sortComparator: compareAlphabetically,
      valueGetter: u => dayjs(u.value).format('MM/DD/YYYY hh:mm A')
    },
    {
      field: 'lastModified',
      headerName: 'Last Modified',
      flex: 1,
      disableColumnMenu: true,
      sortComparator: compareAlphabetically,
      valueGetter: u => dayjs(u.value).format('MM/DD/YYYY hh:mm A')
    },
    {
      field: 'download',
      headerName: 'Download',
      disableColumnMenu: true,
      sortable: false,
      renderCell: u => {
        var downloadUrl = `${env.urls.updatesURL}/api/v1/downloads/admin/${u.id}`
        return (
          <DownloadButton
            id={`${u.id}DownloadButton`}
            url={downloadUrl}
            token={downloadToken}
            variant='icon'
            onMouseEnter={() => setMouseIsOverDownloadButton(true)}
            onMouseLeave={() => setMouseIsOverDownloadButton(false)}
          />
        )
      }
    }
  ]

  const [pageSize, setPageSize] = useState(100)
  const [filter, setFilter] = useState(DEFAULT_UPDATES_FILTER)

  const { data, isLoading, refetch, error } = useQuery({
    queryKey: ['updates'],
    queryFn: async () => await UpdatesApi.getUpdates(instance, accounts, filter.showHidden)
  })

  const [updateModalState, setUpdateModalState] = useState({
    open: false,
    update: null
  })
  const [overridesModalState, setOverridesModalState] = useState({
    open: false,
    updates: []
  })
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [hideUnhideModalOpen, setHideUnhideModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [rows, setRows] = useState([])

  const refreshSelectedRows = async () => {
    setSelectedRows([])
    await refetch()
  }

  useEffect(() => {
    setUpdateModalState(s => (s.update && s.update.id && data ? { ...s, update: data.find(u => u.id === s.update.id) } : s))
    setSelectedRows(rows =>
      data
        ? filterUpdates(
            filter,
            data.filter(u1 => rows.some(u2 => u1.id === u2.id)),
            user.id
          )
        : rows
    )
  }, [data, user.id, filter])

  useEffect(() => {
    if (data) {
      setRows(filterUpdates(filter, data, user.id))
    }
  }, [data, user.id, filter])

  useEffect(() => {
    setOverridesModalState(s =>
      data
        ? {
            ...s,
            updates: data.filter(u1 => s.updates.some(u2 => u1.id === u2.id))
          }
        : s
    )
  }, [data])

  if (error) {
    return (
      <CenteredDiv>
        <ErrorMessage error={error} />
      </CenteredDiv>
    )
  }

  return (
    <PersistantFilterDiv
      page={
        <>
          <UpdateModal
            open={updateModalState.open}
            onClose={() => setUpdateModalState({ open: false, update: null })}
            update={updateModalState.update}
            styles={props.styles}
            refetch={refetch}
          />
          <UpdatesOverridesModal
            open={overridesModalState.open}
            onClose={() => setOverridesModalState({ open: false, updates: [] })}
            updates={overridesModalState.updates}
            styles={props.styles}
            refetch={refetch}
          />
          <EditUpdatesModal
            open={editModalOpen}
            onClose={() => setEditModalOpen(false)}
            updates={selectedRows}
            styles={props.styles}
            refresh={refreshSelectedRows}
          />
          <ConfirmModal
            open={hideUnhideModalOpen}
            onClose={() => setHideUnhideModalOpen(false)}
            action={filter.showHidden ? 'Unhide' : 'Hide'}
            data={selectedRows}
            styles={props.styles}
            variant='updates'
            refresh={refreshSelectedRows}
          />
          <ConfirmModal
            open={deleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            action={'Delete'}
            data={selectedRows}
            styles={props.styles}
            variant='updates'
            refresh={refreshSelectedRows}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ float: 'left', margin: '16px' }}>
              <RegularButton
                id='updatesEditButton'
                disabled={selectedRows.length === 0}
                onClick={() =>
                  selectedRows.length === 1
                    ? setUpdateModalState({ open: true, update: selectedRows[0] })
                    : setEditModalOpen(true)
                }
              >
                Edit
              </RegularButton>
              &emsp;
              <RegularButton
                id='updatesOverridesButton'
                disabled={selectedRows.length === 0}
                onClick={() =>
                  selectedRows.length === 1
                    ? setUpdateModalState({ open: true, update: selectedRows[0] })
                    : setOverridesModalState({ open: true, updates: selectedRows })
                }
              >
                Overrides
              </RegularButton>
              &emsp;
              <RegularButton
                id='updatesHiddenButton'
                disabled={selectedRows.length === 0}
                onClick={() => setHideUnhideModalOpen(true)}
              >
                {filter.showHidden ? 'Unhide' : 'Hide'}
              </RegularButton>
              {filter.showHidden ? (
                <>
                  &emsp;
                  <RegularButton
                    id='updatesDeleteButton'
                    disabled={selectedRows.length === 0}
                    onClick={() => setDeleteModalOpen(true)}
                  >
                    Delete
                  </RegularButton>
                </>
              ) : null}
            </div>
          </div>

          <div style={{ width: '100%', paddingLeft: '15px' }}>
            <CustomDataGrid
              rows={rows}
              autoHeight
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={p => setPageSize(p.pageSize)}
              rowsPerPageOptions={[10, 20, 50, 100]}
              disableSelectionOnClick
              loading={isLoading}
              disableExtendRowFullWidth={true}
              onRowClick={e => (mouseIsOverDownloadButton ? null : setUpdateModalState({ open: true, update: e.row }))}
              onColumnHeaderClick={e =>
                e.field === '__check__'
                  ? setSelectedRows(selectedRows.length > 0 ? [] : filterUpdates(filter, data, user.id))
                  : null
              }
              checkboxSelection
              onSelectionModelChange={ids => {
                const selectedIDs = new Set(ids)
                const selectedRows = rows.filter(row => selectedIDs.has(row.id))
                setSelectedRows(selectedRows)
              }}
              cursor='pointer'
              getRowClassName={params => `isHidden-${params.row.isHidden}`}
            />
          </div>
        </>
      }
      drawer={<UpdatesFilterDrawer filter={filter} setFilter={setFilter} />}
      resetFilter={() => setFilter(DEFAULT_UPDATES_FILTER)}
    />
  )
}
