import { useMsal } from '@azure/msal-react'
import { Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, Switch, TextField } from '@mui/material'
import {
  CenteredDiv,
  ErrorMessage,
  SnackbarVariants,
  SlideUpDialog,
  PrimaryButton,
  PersistantFilterDiv
} from '@wavetronix/common-components'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import UpdatesApi from '../../api/UpdatesApi'
import UsersApi from '../../api/UsersApi'
import GroupsApi from '../../api/GroupsApi'
import { ACCESS_LEVEL_MAP } from '../../utils/AccessLevelMap'
import onUnload from '../../utils/onUnload'
import { RELEASE_PHASE_MAP, removeWhiteSpace } from '../../utils/ReleasePhaseMap'
import UsersModalUpdatesTable from './ModalTables/UsersModalUpdatesTable'
import UpdatesFilterDrawer, { DEFAULT_UPDATES_FILTER, filterUpdates } from '../drawers/UpdatesFilterDrawer'
import { env } from '../../index.js'

const classes = {
  userInfo: {
    width: '100%',
    marginTop: '15px'
  }
}

export default function UserModal(props) {
  const [buttonsDisabled, setButtonsDisabled] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [accessLevel, setAccessLevel] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [companyName, setCompanyName] = useState('')
  const { instance, accounts } = useMsal()
  const [usersGroupsOverrides, setUsersGroupsOverrides] = useState({})
  const [filter, setFilter] = useState(DEFAULT_UPDATES_FILTER)

  const { data, isLoading, refetch, error } = useQuery({
    queryKey: ['UserModalQuery'],
    queryFn: async () => await UpdatesApi.getUpdates(instance, accounts)
  })

  const { data: groupsData } = useQuery({
    queryKey: ['groupsAllQuery'],
    queryFn: async () => await GroupsApi.getGroups(instance, accounts)
  })
  async function updateUser(user, accessLevel) {
    await UsersApi.editUser(instance, accounts, user.id, accessLevel)
  }

  useEffect(() => {
    if (props.user.updatesAccessLevel && props.user.updatesAccessLevel[env.basicAuthentication.fromCompany] !== accessLevel) {
      window.onbeforeunload = onUnload
      return () => (window.onbeforeunload = undefined)
    }
  }, [props.user, accessLevel])

  useEffect(() => {
    if (props.user.updatesAccessLevel && props.user.updatesAccessLevel[env.basicAuthentication.fromCompany]) {
      setAccessLevel(props.user.updatesAccessLevel[env.basicAuthentication.fromCompany])
      setFirstName(props.user.givenName)
      setLastName(props.user.surname)
      setEmail(props.user.email)
      setCompanyName(props.user.company)
    }
  }, [props.user])

  useEffect(() => {
    if (groupsData && props.user && data) {
      var usersMap = {}
      for (let update of data) {
        //default user override to none
        usersMap[update.id] = 'none'

        //if user override on update set to updateAccess
        if (update.userOverrides[props.user.id]) {
          usersMap[update.id] = 'userOverrideAccess'
        }

        //if user is in a group with override access reset to groupOverrideAccess
        for (let group of groupsData) {
          if (props.user.groups && props.user.groups.includes(group.id) && group.updates.includes(update.id)) {
            usersMap[update.id] = 'groupOverrideAccess'
          }
        }
      }
      setUsersGroupsOverrides(usersMap)
    }
  }, [groupsData, props.user, data])

  const onClose = () => {
    if (buttonsDisabled === false) {
      setAccessLevel('')
      setFirstName('')
      setLastName('')
      setEmail('')
      setCompanyName('')
      props.onClose()
    }
  }

  async function addOverrides(id) {
    let key = enqueueSnackbar('Adding override...', SnackbarVariants.LOADING)
    await UpdatesApi.addOverride(instance, accounts, id, props.user.id)
      .then(async () => {
        await Promise.all([props.refetch(), refetch()])
        closeSnackbar(key)
        enqueueSnackbar('Successfully added override', SnackbarVariants.SUCCESS)
      })
      .catch(() => {
        closeSnackbar(key)
        enqueueSnackbar('An error occurred', SnackbarVariants.ERROR)
      })
  }

  async function removeOverrides(id) {
    let key = enqueueSnackbar('Removing override...', SnackbarVariants.LOADING)
    await UpdatesApi.removeOverride(instance, accounts, id, props.user.id)
      .then(async () => {
        await Promise.all([props.refetch(), refetch()])
        closeSnackbar(key)
        enqueueSnackbar('Successfully removed override', SnackbarVariants.SUCCESS)
      })
      .catch(() => {
        closeSnackbar(key)
        enqueueSnackbar('An error occurred', SnackbarVariants.ERROR)
      })
  }

  async function onSwitchChange(e, update) {
    setButtonsDisabled(true)
    if (e.target.checked) {
      await addOverrides(update.id)
    } else {
      await removeOverrides(update.id)
    }
    setButtonsDisabled(false)
  }

  if (error) {
    return (
      <CenteredDiv>
        <ErrorMessage error={error} />
      </CenteredDiv>
    )
  }

  return (
    <SlideUpDialog
      id='userModal'
      fullScreen
      open={props.open}
      onClose={onClose}
      title={<h3 style={{ margin: '0px' }}>Manage User</h3>}
    >
      <PersistantFilterDiv
        drawer={<UpdatesFilterDrawer filter={filter} setFilter={setFilter} />}
        resetFilter={() => setFilter(DEFAULT_UPDATES_FILTER)}
        page={
          <Grid container>
            <Grid xl={2} lg={3} md={12} sm={12} xs={12} item>
              <Card
                style={{
                  margin: '10px'
                }}
              >
                <CardContent>
                  <TextField
                    id='userFirstNameTextField'
                    label='First Name'
                    sx={classes.userInfo}
                    size='small'
                    value={firstName}
                    disabled
                  />
                  <TextField
                    id='userLastNameTextField'
                    label='Last Name'
                    sx={classes.userInfo}
                    size='small'
                    value={lastName}
                    disabled
                  />
                  <TextField id='userEmailTextField' label='Email' sx={classes.userInfo} size='small' value={email} disabled />
                  <TextField
                    id='userCompanyTextField'
                    label='Company'
                    sx={classes.userInfo}
                    size='small'
                    value={companyName}
                    disabled
                  />
                  <FormControl sx={classes.userInfo} variant='outlined' size='small'>
                    <InputLabel id='accessLevelSelectLabel'>Access Level</InputLabel>
                    <Select
                      labelId='accessLevelSelectLabel'
                      id='userAccessLevelSelect'
                      value={accessLevel}
                      onChange={e => setAccessLevel(e.target.value)}
                      label='Access Level'
                    >
                      {['General', 'FullRelease', 'Unreleased'].map(accessLevel => (
                        <MenuItem id={accessLevel} value={accessLevel} key={accessLevel}>
                          {accessLevel === 'FullRelease' ? 'QA Release' : accessLevel}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <CenteredDiv>
                    <PrimaryButton
                      id='userSaveButton'
                      style={{ ...classes.userInfo, width: '100px' }}
                      disabled={
                        (props.user.updatesAccessLevel &&
                          props.user.updatesAccessLevel[env.basicAuthentication.fromCompany] === accessLevel) ||
                        buttonsDisabled
                      }
                      onClick={async () => {
                        setButtonsDisabled(true)
                        let key = enqueueSnackbar('Updating user...', SnackbarVariants.LOADING)
                        await updateUser(props.user, accessLevel)
                          .then(async () => {
                            await props.refetch()
                            closeSnackbar(key)
                            enqueueSnackbar('Successfully updated user', SnackbarVariants.SUCCESS)
                          })
                          .catch(() => {
                            closeSnackbar(key)
                            enqueueSnackbar('Failed to update user', SnackbarVariants.ERROR)
                          })
                          .finally(() => setButtonsDisabled(false))
                      }}
                    >
                      Save
                    </PrimaryButton>
                  </CenteredDiv>
                </CardContent>
              </Card>
            </Grid>
            <Grid xl={10} lg={9} md={12} sm={12} xs={12} item>
              <div style={{ marginTop: '10px' }}>
                <UsersModalUpdatesTable
                  modalOpen={props.open}
                  refetch={refetch}
                  buttonsDisabled={buttonsDisabled}
                  setButtonsDisabled={setButtonsDisabled}
                  loading={isLoading}
                  onClose={onClose}
                  updates={
                    data && props.user && props.user.updatesAccessLevel
                      ? filterUpdates(
                          filter,
                          data.filter(
                            u =>
                              ACCESS_LEVEL_MAP[props.user.updatesAccessLevel[env.basicAuthentication.fromCompany]] <
                              RELEASE_PHASE_MAP[removeWhiteSpace(u.releasePhase)]
                          ),
                          props.user.id
                        )
                      : []
                  }
                  renderSwitch={update => {
                    return (
                      <Switch
                        id={`${update.id}UserOverrideSwitch`}
                        checked={
                          usersGroupsOverrides[update.id] === 'groupOverrideAccess' ||
                          usersGroupsOverrides[update.id] === 'userOverrideAccess'
                            ? true
                            : false
                        }
                        color='primary'
                        onChange={e => onSwitchChange(e, update)}
                        disabled={buttonsDisabled || usersGroupsOverrides[update.id] === 'groupOverrideAccess'}
                      />
                    )
                  }}
                  overridesComparator={(a, b) => {
                    if (usersGroupsOverrides[a] === usersGroupsOverrides[b]) {
                      return 0
                    } else if (
                      (usersGroupsOverrides[b] === 'groupOverrideAccess' && usersGroupsOverrides[a] === 'userOverrideAccess') ||
                      (usersGroupsOverrides[b] === 'groupOverrideAccess' && usersGroupsOverrides[a] === 'none') ||
                      (usersGroupsOverrides[b] === 'userOverrideAccess' && usersGroupsOverrides[a] === 'none')
                    ) {
                      return 1
                    } else {
                      return -1
                    }
                  }}
                />
              </div>
            </Grid>
          </Grid>
        }
      />
    </SlideUpDialog>
  )
}
