import { useMsal } from '@azure/msal-react'
import { CircularProgress, Switch, TextField } from '@mui/material'
import { CenteredDiv, CustomAccordion, CustomSelect, ListSelector } from '@wavetronix/common-components'
import { useQuery } from '@tanstack/react-query'
import MasterFeaturesApi from '../../api/MasterFeaturesApi'
import getDeviceName from '../../utils/getDeviceName'

export const DEFAULT_LICENSE_DEFINITIONS_FILTER = {
  id: '',
  name: '',
  region: 'All',
  language: 'All',
  showHidden: false,
  products: []
}

const classes = {
  accordionList: {
    marginTop: '10px',
    borderRadius: '5px',
    width: '100%',
    boxShadow: 'none',
    border: '1px solid rgb(196, 196, 196)',
    '&:before': {
      display: 'none'
    }
  },
  accordionSummary: {
    width: '100%',
    borderRadius: '5px',
    minHeight: '40px'
  },
  accordionDetails: {
    padding: '0px',
    margin: '0px',
    marginTop: '-10px'
  }
}

export const filterLicenseDefinitions = (filter, definitions) => {
  return definitions
    ? definitions.filter(d => {
        return (
          d.id.toLowerCase().includes(filter.id.toLowerCase()) &&
          d.name.toLowerCase().includes(filter.name.toLowerCase()) &&
          (filter.region === 'All' || d.region === filter.region) &&
          (filter.language === 'All' || d.language === filter.language) &&
          (filter.showHidden ? d.state === 'Hidden' || d.state === 'Published' : d.state !== 'Hidden') &&
          (d.products.filter(p => filter.products.map(fp => fp.id).includes(p.baseProductId)).length > 0 ||
            filter.products.length === 0)
        )
      })
    : []
}

export default function LicensesDefFilterDrawer({ filter, setFilter }) {
  const { instance, accounts } = useMsal()
  const { data, isLoading } = useQuery({
    queryKey: ['baseProducts'],
    queryFn: async () => await MasterFeaturesApi.getBaseProducts(instance, accounts)
  })
  const handleChange = (id, value) => setFilter(f => ({ ...f, [id]: value }))
  const onCheckChange = (id, checked) => setFilter(f => ({ ...f, [id]: checked }))
  return (
    <>
      <TextField
        id='licensesDefFilterItemNumberTextField'
        label='Item Number'
        onChange={e => handleChange('id', e.target.value)}
        value={filter.id}
        variant='outlined'
        size='small'
      />
      <TextField
        id='licensesDefFilterNameTextField'
        label='Name'
        style={{ marginTop: '10px' }}
        onChange={e => handleChange('name', e.target.value)}
        value={filter.name}
        variant='outlined'
        size='small'
      />
      <CustomSelect
        id='licensesDefFilterRegionSelect'
        style={{ marginTop: '10px', width: '100%' }}
        label='Region'
        value={filter.region}
        onChange={e => setFilter(f => ({ ...f, region: e.target.value }))}
        options={['All', 'USA', 'UK', 'France']}
      />
      <CustomSelect
        id='licensesDefFilterLanguageSelect'
        style={{ marginTop: '10px', width: '100%' }}
        label='Language'
        value={filter.language}
        onChange={e => setFilter(f => ({ ...f, language: e.target.value }))}
        options={['All', 'en-US', 'fr']}
      />
      {isLoading ? (
        <CenteredDiv>
          <CircularProgress />
        </CenteredDiv>
      ) : (
        <>
          <CustomAccordion
            children={
              <ListSelector
                selectedOptions={filter.products}
                options={data ? data.map(d => ({ id: d, value: getDeviceName(d) })) : []}
                onChange={e => setFilter({ ...filter, products: e })}
              />
            }
            title={'Products'}
            titleStyle={classes.accordionSummary}
            style={classes.accordionList}
            detailStyle={classes.accordionDetails}
          />
        </>
      )}
      <div style={{ marginTop: '10px', width: '100%' }}>
        <Switch
          id='licensesDefFilterHiddenSwitch'
          color='primary'
          checked={filter.showHidden}
          onChange={e => onCheckChange('showHidden', e.target.checked)}
        />
        Show Hidden
      </div>
    </>
  )
}
