import { useMsal } from '@azure/msal-react'
import { CircularProgress, DialogContent, Grid } from '@mui/material'
import {
  CenteredDiv,
  ErrorMessage,
  PrimaryButton,
  RegularButton,
  SnackbarVariants,
  SlideUpDialog
} from '@wavetronix/common-components'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import MasterFeaturesApi from '../../api/MasterFeaturesApi'
import LicenseDefinitionsApi from '../../api/LicenseDefinitionsApi'
import compareAlphabetically from '../../utils/compareAlphabetically'
import EditDefinitionDetails, { DEFAULT_FORM } from '../EditDefinitionDetails'
import LicenseDefFeaturesForm from '../LicenseDefFeaturesForm'
import ConfirmPublishModal from './ConfirmPublishModal'
import onUnload from '../../utils/onUnload'

const DEFAULT_PUBLISH_MODAL_STATE = {
  open: false,
  draft: undefined,
  onPublish: () => {}
}

function arraysAreEqual(a, b) {
  if (a === b) return true
  if (a == null || b == null) return false
  if (a.length !== b.length) return false
  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false
  }
  return true
}

export default function LicenseDefinitionModal(props) {
  const { instance, accounts } = useMsal()

  const [buttonsDisabled, setButtonsDisabled] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [history, setHistory] = useState([])
  const { data, isLoading, refetch, error, isFetching } = useQuery({
    queryKey: ['LicenseDefModalQuery'],
    queryFn: async () => {
      if (!props.licenseDef.baseProductId || !props.licenseDef.itemNumber) {
        setHistory([])
        return []
      }
      let featurePromise = MasterFeaturesApi.getAvailableFeatures(instance, accounts, props.licenseDef.baseProductId)
      let historyPromise = LicenseDefinitionsApi.getHistory(instance, accounts, props.licenseDef.itemNumber)
      return await Promise.all([featurePromise, historyPromise]).then(values => {
        setHistory(values[1])
        return values[0]
      })
    }
  })

  const [oldFeatureMap, setOldFeatureMap] = useState({})
  const [newFeatureMap, setNewFeatureMap] = useState({})
  const [form, setForm] = useState(DEFAULT_FORM)
  const [id, setId] = useState('')

  useEffect(() => {
    refetch()
  }, [props.licenseDef, refetch])

  // This makes it so the form and feature map only changes if the user is looking at a different update
  useEffect(() => {
    if (props.licenseDef.id !== id) {
      if (props.licenseDef.features) {
        let featureMap = props.licenseDef.features.reduce((a, b) => ({ ...a, [b.id]: { value: b.value, type: b.type } }), {})
        setOldFeatureMap(featureMap)
        setNewFeatureMap({ ...featureMap })
      }
      let form = { ...DEFAULT_FORM, applicableItemNumbers: [] }
      if (props.licenseDef.name) {
        form.name = props.licenseDef.name
      }
      if (props.licenseDef.region) {
        form.region = props.licenseDef.region
      }
      if (props.licenseDef.language) {
        form.language = props.licenseDef.language
      }
      if (props.licenseDef.description) {
        form.description = props.licenseDef.description
      }
      if (props.licenseDef.applicableItemNumbers) {
        form.applicableItemNumbers = props.licenseDef.applicableItemNumbers
      }
      setForm(form)
      setId(props.licenseDef.id)
    }
  }, [props.licenseDef, id])

  const saveButtonsDisabled =
    (Object.entries(newFeatureMap).every(
      pair =>
        (!pair[1].value && !oldFeatureMap[pair[0]]) ||
        (oldFeatureMap[pair[0]] && pair[1].value === oldFeatureMap[pair[0]].value && pair[1].type === oldFeatureMap[pair[0]].type)
    ) &&
      Object.entries(form).every(
        pair =>
          pair[1] === props.licenseDef[pair[0]] ||
          (pair[0] === 'applicableItemNumbers' &&
            (arraysAreEqual(pair[1], props.licenseDef.applicableItemNumbers) || pair[1].some(x => !x)))
      )) ||
    !form.name ||
    !form.description

  useEffect(() => {
    if (saveButtonsDisabled) {
      window.onbeforeunload = undefined
    } else {
      window.onbeforeunload = onUnload
    }
    return () => (window.onbeforeunload = undefined)
  }, [saveButtonsDisabled])

  function buildDraft() {
    let draft = { ...props.licenseDef, features: [] }
    // delete the row id which does not correspond to the actual id/itemNumber.
    delete draft.id
    for (let f of data) {
      if (newFeatureMap[f.id]) {
        if (newFeatureMap[f.id].type === 'Bool' && newFeatureMap[f.id].value === 'true') {
          draft.features.push({
            id: f.id,
            value: newFeatureMap[f.id].value,
            maxValue: f.maxValue,
            type: 'Bool'
          })
        } else if (newFeatureMap[f.id].value) {
          draft.features.push({
            id: f.id,
            value: newFeatureMap[f.id].value,
            maxValue: f.maxValue,
            type: newFeatureMap[f.id].type
          })
        }
      }
    }
    draft.features.sort((a, b) => compareAlphabetically(a.id, b.id))
    for (const key in form) {
      draft[key] = form[key]
    }
    return draft
  }

  function onClose() {
    if (!buttonsDisabled) {
      props.onClose()
      setId('')
    }
  }

  const [publishModalState, setPublishModalState] = useState(DEFAULT_PUBLISH_MODAL_STATE)

  async function publish(itemNumber) {
    setButtonsDisabled(true)
    let key = enqueueSnackbar('Publishing...', SnackbarVariants.LOADING)
    await LicenseDefinitionsApi.publish(instance, accounts, itemNumber)
      .then(async () => {
        await props.refetch()
        closeSnackbar(key)
        enqueueSnackbar('Successfully published draft', SnackbarVariants.SUCCESS)
        props.onClose()
        setId('')
      })
      .catch(() => {
        closeSnackbar(key)
        enqueueSnackbar('Failed to publish draft', SnackbarVariants.ERROR)
      })
      .finally(() => {
        setPublishModalState(DEFAULT_PUBLISH_MODAL_STATE)
        setButtonsDisabled(false)
      })
  }

  async function save(draft, publishBool) {
    setButtonsDisabled(true)
    let key = enqueueSnackbar('Saving...', SnackbarVariants.LOADING)
    await LicenseDefinitionsApi.updateDraft(instance, accounts, draft, publishBool)
      .then(async () => {
        await props.refetch()
        closeSnackbar(key)
        enqueueSnackbar('Successfully saved draft.', SnackbarVariants.SUCCESS)
        if (publishBool) {
          await publish(draft.itemNumber)
        } else {
          props.onClose()
          setId('')
          setButtonsDisabled(false)
        }
      })
      .catch(() => {
        closeSnackbar(key)
        enqueueSnackbar('Failed to save draft', SnackbarVariants.ERROR)
        setButtonsDisabled(false)
      })
  }

  if (error) {
    return (
      <CenteredDiv>
        <ErrorMessage error={error} />
      </CenteredDiv>
    )
  }

  return (
    <>
      <ConfirmPublishModal
        open={publishModalState.open}
        onClose={() => setPublishModalState(DEFAULT_PUBLISH_MODAL_STATE)}
        styles={props.styles}
        draft={publishModalState.draft}
        onPublish={publishModalState.onPublish}
        buttonsDisabled={buttonsDisabled}
      />
      <SlideUpDialog
        id='licenseDefModal'
        open={props.open}
        onClose={onClose}
        fullScreen
        title={<h3 style={{ margin: '0px' }}>Edit License Definition</h3>}
        actions={
          <div style={{ display: 'flex' }}>
            <PrimaryButton
              id='licenseDefSaveDraftButton'
              disabled={saveButtonsDisabled}
              onClick={() => save(buildDraft(), false)}
              style={{ marginRight: '10px' }}
            >
              Save Draft
            </PrimaryButton>
            {props.licenseDef.state === 'Draft' && saveButtonsDisabled ? (
              <RegularButton
                id='licenseDefPublishButton'
                style={{ marginRight: '10px' }}
                onClick={() =>
                  setPublishModalState({
                    open: true,
                    draft: buildDraft(),
                    onPublish: draft => publish(draft.itemNumber)
                  })
                }
              >
                Publish
              </RegularButton>
            ) : (
              <RegularButton
                id='licenseDefSavePublishButton'
                style={{ marginRight: '10px' }}
                disabled={saveButtonsDisabled}
                onClick={() =>
                  setPublishModalState({
                    open: true,
                    draft: buildDraft(),
                    onPublish: draft => save(draft, true)
                  })
                }
              >
                Save & Publish
              </RegularButton>
            )}
            <RegularButton id='licenseDefCancelButton' style={{ marginRight: '10px' }} onClick={onClose}>
              Cancel
            </RegularButton>
          </div>
        }
      >
        <DialogContent>
          <div>
            <Grid container spacing={2}>
              <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                <EditDefinitionDetails
                  form={form}
                  setForm={setForm}
                  variant='License'
                  definition={props.licenseDef}
                  history={history}
                  refetch={props.refetch}
                  onClose={onClose}
                />
              </Grid>
              <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                <>
                  {isLoading || (isFetching && data && data.length === 0) ? (
                    <CenteredDiv>
                      <CircularProgress />
                    </CenteredDiv>
                  ) : data ? (
                    <>
                      <CenteredDiv>
                        <h3 style={{ margin: '0px', paddingBottom: '18px', paddingTop: '35px' }}>Features</h3>
                      </CenteredDiv>
                      <LicenseDefFeaturesForm
                        featureMap={newFeatureMap}
                        setFeatureMap={setNewFeatureMap}
                        disabled={buttonsDisabled}
                        features={data}
                      />
                    </>
                  ) : (
                    <h2>No Features</h2>
                  )}
                </>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </SlideUpDialog>
    </>
  )
}
