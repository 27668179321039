import { DialogContent } from '@mui/material'
import { CustomAccordion, PrimaryButton, RegularButton, SlideUpDialog, WtxColors } from '@wavetronix/common-components'
import React from 'react'
import { js2xml } from 'xml-js'

const generateXmlPreview = product => {
  if (!product) {
    return ''
  }
  let xmlJson = {
    productDefinition: {
      itemNumber: product.itemNumber,
      version: product.version + 1,
      featureList: {
        feature: product.features
      },
      productBase: product.baseProductId,
      marketingInfoList: {
        marketingInfo: {
          tempName: product.name,
          description: product.description
        }
      }
    }
  }
  let xmlPreview = js2xml(xmlJson, { compact: true, ignoreComment: true, spaces: 2 })
    .replace(/\n *<name.*\/(name)?>/g, '')
    .replace('<marketingInfo>', `<marketingInfo langId="${product.language}">`)
    .replace('<productDefinition>', '<productDefinition sVer="1.0">')
    .replace(/tempName/g, 'name')
  return '<?xml version="1.0" encoding="utf-8"?>\n' + xmlPreview
}

export default function ConfirmPublishModal(props) {
  const onClose = () => (props.buttonsDisabled ? null : props.onClose())

  return (
    <SlideUpDialog
      id='confirmPublishModal'
      open={props.open}
      onClose={onClose}
      title={<h3 style={{ margin: '0px' }}>Confirm Publish</h3>}
      actions={
        <div>
          <PrimaryButton
            id='productDefPublishButton'
            onClick={() => props.onPublish(props.draft)}
            disabled={props.buttonsDisabled}
            style={{ marginRight: '10px' }}
          >
            Publish
          </PrimaryButton>

          <RegularButton id='productDefDeleteButton' onClick={props.onClose} disabled={props.buttonsDisabled}>
            Cancel
          </RegularButton>
        </div>
      }
    >
      <DialogContent>
        <div style={{ paddingBottom: '20px' }}>Are you sure you want to publish this product definition?</div>
        <CustomAccordion
          id='productDefXML'
          children={
            <div
              style={{
                marginTop: '15px',
                height: '55%',
                width: '100%',
                overflow: 'auto',
                backgroundColor: 'black',
                color: WtxColors.CONCRETE
              }}
            >
              <pre>{generateXmlPreview(props.draft)}</pre>
            </div>
          }
          title='XML Preview (for engineers)'
          key={'XML Accordion'}
        />
      </DialogContent>
    </SlideUpDialog>
  )
}
