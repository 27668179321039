import axios from 'axios'
import { env } from '../index.js'
import { acquireAccessToken } from '@wavetronix/common-components'

let instance = null

class LicenseDefinitionsApi {
  getLicenseDefinitions = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .get(`${env.urls.updatesURL}/api/v1/licensedefinitions?states=Hidden,Draft,Published`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  getOne = async (msalInstance, accounts, itemNumber) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return await axios
      .get(`${env.urls.updatesURL}/api/v1/licensedefinitions?itemNumber=${itemNumber}&states=Draft,Published,Archived,Hidden`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  getHistory = async (msalInstance, accounts, itemNumber) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return await axios
      .get(`${env.urls.updatesURL}/api/v1/licensedefinitions/history/${itemNumber}`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  publish = async (msalInstance, accounts, itemnumber) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return await axios
      .post(`${env.urls.updatesURL}/api/v1/licensedefinitions/${itemnumber}/state/published`, null, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res)
  }

  createLicense = async (msalInstance, accounts, license, publishBool) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return await axios
      .post(`${env.urls.updatesURL}/api/v1/licensedefinitions`, license, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(async res => (publishBool ? await this.publish(msalInstance, accounts, license.itemNumber) : res))
  }

  deleteDraft = async (msalInstance, accounts, itemNumber) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return await axios
      .delete(`${env.urls.updatesURL}/api/v1/licensedefinitions/draft/${itemNumber}`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res)
  }

  updateDraft = async (msalInstance, accounts, draft) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return await axios
      .put(`${env.urls.updatesURL}/api/v1/licensedefinitions`, draft, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res)
  }

  archive = async (msalInstance, accounts, id) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return await axios
      .post(`${env.urls.updatesURL}/api/v1/licensedefinitions/${id}/state/Archived`, null, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res)
  }

  hideUnhideDefinition = async (msalInstance, accounts, id, hide) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .post(`${env.urls.updatesURL}/api/v1/licensedefinitions/${id}/state/${hide ? 'Hidden' : 'Published'}`, null, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res)
  }
}

const getInstance = () => {
  if (instance == null) {
    instance = new LicenseDefinitionsApi()
  }
  return instance
}

export default getInstance()
