import { useMsal } from '@azure/msal-react'
import { CircularProgress, DialogContent, Step, StepLabel, Stepper, Switch, TextField, Grid } from '@mui/material'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@mui/icons-material'
import {
  CenteredDiv,
  PrimaryButton,
  RegularButton,
  SnackbarVariants,
  WtxColors,
  SlideUpDialog
} from '@wavetronix/common-components'
import { useSnackbar } from 'notistack'
import React, { useEffect, useRef, useState } from 'react'
import MasterFeaturesApi from '../../api/MasterFeaturesApi'
import ProductDefinitionsApi from '../../api/ProductDefinitionsApi'
import compareAlphabetically from '../../utils/compareAlphabetically'
import onUnload from '../../utils/onUnload'
import CreateDefinitionDetails from '../CreateDefinitionDetails'
import CreateDefinitionFirstStep from '../CreateDefinitionFirstStep'
import ProductDefFeaturesForm from '../ProductDefFeaturesForm'
import ConfirmPublishModal from './ConfirmPublishModal'

const DEFAULT_FORM = {
  baseProductId: '',
  itemNumber: '',
  name: '',
  region: '',
  language: '',
  description: ''
}

const STEPS = ['Define Product', 'Set Features', 'Confirm Definition']

export default function ProductDefCreateModal(props) {
  const { instance, accounts } = useMsal()
  const [activeStep, setActiveStep] = useState(0)
  const [buttonsDisabled, setButtonsDisabled] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [form, setForm] = useState(DEFAULT_FORM)
  const [features, setFeatures] = useState([])
  const [featureMap, setFeatureMap] = useState({})
  const [featureNameMap, setFeatureNameMap] = useState({})
  const [draft, setDraft] = useState({})

  useEffect(() => {
    if (activeStep > 0) {
      window.onbeforeunload = onUnload
      return () => (window.onbeforeunload = undefined)
    }
  }, [activeStep])

  useEffect(() => {
    setFeatureMap(features.reduce((a, b) => ({ ...a, [b.id]: '' }), {}))
    setFeatureNameMap(features.reduce((a, b) => ({ ...a, [b.id]: b.name }), {}))
  }, [features])

  function reset() {
    setActiveStep(0)
    setForm(DEFAULT_FORM)
    setFeatures([])
    setFeatureMap({})
    setFeatureNameMap({})
    setDraft({})
  }

  function onClose() {
    if (!buttonsDisabled) {
      props.onClose()
      reset()
    }
  }

  const nextButtonDisabled = () =>
    buttonsDisabled || !form.baseProductId || !form.name || !form.itemNumber || !form.region || !form.language

  const contentRef = useRef(null)

  async function handleNextStepClick() {
    if (activeStep === 0) {
      setButtonsDisabled(true)
      await ProductDefinitionsApi.getOne(instance, accounts, form.itemNumber)
        .then(() => enqueueSnackbar('A product with this item number already exists.', SnackbarVariants.ERROR))
        .catch(async e => {
          if (e.response.status === 404) {
            await MasterFeaturesApi.getAvailableFeatures(instance, accounts, form.baseProductId)
              .then(features => {
                setFeatures(features)
                setActiveStep(1)
              })
              .catch(() => enqueueSnackbar('An error occurred.', SnackbarVariants.ERROR))
          } else {
            enqueueSnackbar('An error occurred.', SnackbarVariants.ERROR)
          }
        })
        .finally(() => setButtonsDisabled(false))
    } else {
      // activeStep must equal 1
      let newDraft = { ...form, features: [] }
      for (let f of features) {
        if (f.possibleTypeList.includes('Bool') && featureMap[f.id] === 'true') {
          newDraft.features.push({
            id: f.id,
            value: featureMap[f.id],
            maxValue: f.maxValue,
            type: 'Bool'
          })
        } else if (featureMap[f.id]) {
          newDraft.features.push({
            id: f.id,
            value: featureMap[f.id],
            maxValue: f.maxValue,
            type: 'Set'
          })
        }
      }
      newDraft.features.sort((a, b) => compareAlphabetically(a.id, b.id))
      setDraft(newDraft)
      setActiveStep(2)
    }
    contentRef.current.scrollTo(0, 0)
  }

  async function saveDraft() {
    setButtonsDisabled(true)
    let key = enqueueSnackbar('Saving draft...', SnackbarVariants.LOADING)
    await ProductDefinitionsApi.createProduct(instance, accounts, draft, false)
      .then(async () => {
        await props.refetch()
        closeSnackbar(key)
        enqueueSnackbar('Successfully saved draft.', SnackbarVariants.SUCCESS)
        props.onClose()
        reset()
      })
      .catch(() => {
        closeSnackbar(key)
        enqueueSnackbar('Failed to save draft', SnackbarVariants.ERROR)
      })
      .finally(() => setButtonsDisabled(false))
  }

  const [publishModalOpen, setPublishModalOpen] = useState(false)

  async function publish() {
    setButtonsDisabled(true)
    let key = enqueueSnackbar('Publishing...', SnackbarVariants.LOADING)
    await ProductDefinitionsApi.createProduct(instance, accounts, draft, true)
      .then(async () => {
        await props.refetch()
        closeSnackbar(key)
        enqueueSnackbar('Successfully published product definition.', SnackbarVariants.SUCCESS)
        setPublishModalOpen(false)
        props.onClose()
        reset()
      })
      .catch(() => {
        closeSnackbar(key)
        enqueueSnackbar('Failed to publish product definition', SnackbarVariants.ERROR)
      })
      .finally(() => setButtonsDisabled(false))
  }

  return (
    <>
      <ConfirmPublishModal
        open={publishModalOpen}
        onClose={() => setPublishModalOpen(false)}
        styles={props.styles}
        draft={draft}
        onPublish={publish}
        buttonsDisabled={buttonsDisabled}
      />
      <SlideUpDialog
        id='productDefCreateModal'
        open={props.open}
        onClose={onClose}
        fullScreen
        title={<h3 style={{ margin: '0px' }}>New Product Definition</h3>}
        actions={
          <div style={{ display: 'flex' }}>
            {activeStep > 0 ? (
              <RegularButton
                id='productDefCreateBackButton'
                disabled={buttonsDisabled}
                onClick={() => setActiveStep(s => s - 1)}
                style={{ marginRight: '10px' }}
              >
                <ArrowLeftOutlined />
                Go Back
              </RegularButton>
            ) : null}
            <div style={{ flex: 1 }} />
            {activeStep < 2 ? (
              <PrimaryButton
                id='productDefCreateNextButton'
                disabled={nextButtonDisabled()}
                onClick={handleNextStepClick}
                style={{ marginRight: '10px' }}
              >
                Next Step
                {buttonsDisabled ? <CircularProgress size={24} /> : <ArrowRightOutlined />}
              </PrimaryButton>
            ) : (
              <>
                <PrimaryButton
                  id='productDefCreatePublishButton'
                  disabled={buttonsDisabled}
                  onClick={() => setPublishModalOpen(true)}
                  style={{ marginRight: '10px' }}
                >
                  Publish
                </PrimaryButton>
                <RegularButton
                  id='productDefCreateSaveDraftButton'
                  disabled={buttonsDisabled}
                  onClick={saveDraft}
                  style={{ marginRight: '10px' }}
                >
                  Save as Draft
                </RegularButton>
              </>
            )}
            <RegularButton id='productDefCreateCancelButton' onClick={onClose} disabled={buttonsDisabled}>
              Cancel
            </RegularButton>
          </div>
        }
      >
        <DialogContent ref={contentRef}>
          <div style={{ minHeight: '200px' }}>
            <Stepper activeStep={activeStep} sx={{ marginBottom: '25px' }}>
              {STEPS.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === 0 ? (
              <CreateDefinitionFirstStep form={form} setForm={setForm} disabled={buttonsDisabled} />
            ) : activeStep === 1 ? (
              <ProductDefFeaturesForm
                featureMap={featureMap}
                setFeatureMap={setFeatureMap}
                disabled={buttonsDisabled}
                features={features}
              />
            ) : (
              <Grid container spacing={2}>
                <Grid item xl={4} lg={4} md={3} sm={12} xs={12}>
                  <CreateDefinitionDetails draft={draft} />
                </Grid>
                <Grid item xl={8} lg={8} md={9} sm={12} xs={12}>
                  <CenteredDiv>
                    <h3 style={{ margin: '0px', paddingBottom: '18px', paddingTop: '35px' }}>Features</h3>
                  </CenteredDiv>
                  {draft.features.length === 0 ? (
                    <CenteredDiv>No features selected.</CenteredDiv>
                  ) : (
                    draft.features.map((f, i) => (
                      <div key={i} style={{ width: '50%', display: 'inline-flex' }}>
                        {f.type === 'Bool' ? (
                          <div style={{ width: '100%', marginTop: '5px', marginBottom: '5px' }}>
                            <Switch
                              id={`${f.id}productDefCreateFeatureSwitch`}
                              checked={f.value === 'true'}
                              style={{ color: WtxColors.IQ_BLUE }}
                              disabled
                            />
                            {featureNameMap[f.id]}
                          </div>
                        ) : (
                          <div style={{ width: '100%', marginTop: '5px', marginBottom: '5px' }}>
                            <TextField
                              id={`${f.id}productDefCreateFeatureTextField`}
                              label={featureNameMap[f.id]}
                              value={f.value}
                              disabled
                              size='small'
                              style={{ width: '80%' }}
                            />
                          </div>
                        )}

                        {i % 2 === 0 ? null : <br />}
                      </div>
                    ))
                  )}
                </Grid>
              </Grid>
            )}
          </div>
        </DialogContent>
      </SlideUpDialog>
    </>
  )
}
