export const ACCESS_LEVEL_MAP = {
  General: 0,
  FullRelease: 3,
  Unreleased: 4
}

export const LEVEL_ROLE_ENUM_HASH = {
  All: 'All',
  General: 'General',
  FullRelease: 'QA Release',
  Unreleased: 'Unreleased',
  ReadOnly: 'Read Only',
  Admin: 'Administrator'
}
