import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { env } from '../../index.js'
import { useEffect } from 'react'
import { CustomAccordion, ListSelector } from '@wavetronix/common-components'

export const DEFAULT_USERS_FILTER = {
  firstName: '',
  lastName: '',
  email: '',
  companyName: '',
  accessLevel: 'All',
  tags: []
}

const nullCheck = s => (s ? s : '')
export const COMPANY_TAGS = [
  { value: 'Wavetronix', id: 'wavetronix' },
  { value: 'Island Radar', id: 'islandradar' },
  { value: 'Fathom', id: 'fathom' }
]

export const filterUsers = (filter, users) =>
  users.filter(
    u =>
      nullCheck(u.givenName).toLowerCase().includes(filter.firstName.toLowerCase()) &&
      nullCheck(u.surname).toLowerCase().includes(filter.lastName.toLowerCase()) &&
      nullCheck(u.email).toLowerCase().includes(filter.email.toLowerCase()) &&
      nullCheck(u.company).toLowerCase().includes(filter.companyName.toLowerCase()) &&
      (filter.accessLevel === 'All' || u.updatesAccessLevel[env.basicAuthentication.fromCompany] === filter.accessLevel) &&
      (!u.tags || filter.tags.length === 0 || u.tags.some(t => filter.tags.map(g => g.id).includes(t)) || false)
  )

export default function UsersFilterDrawer({ filter, setFilter }) {
  useEffect(() => {
    setFilter(f => ({ ...f, tags: COMPANY_TAGS.filter(t => t.id === env.basicAuthentication.fromCompany) }))
  }, [setFilter])

  const handleChange = (id, value) => setFilter(f => ({ ...f, [id]: value }))

  return (
    <>
      <TextField
        id='usersFilterFirstNameTextField'
        label='First Name'
        onChange={e => handleChange('firstName', e.target.value)}
        value={filter.firstName}
        variant='outlined'
        size='small'
        style={{ width: '100%' }}
      />
      <TextField
        id='usersFilterLastNameTextField'
        label='Last Name'
        style={{ marginTop: '10px', width: '100%' }}
        onChange={e => handleChange('lastName', e.target.value)}
        value={filter.lastName}
        variant='outlined'
        size='small'
      />
      <TextField
        id='usersFilterEmailTextField'
        label='Email'
        style={{ marginTop: '10px', width: '100%' }}
        onChange={e => handleChange('email', e.target.value)}
        value={filter.email}
        variant='outlined'
        size='small'
      />
      <TextField
        id='usersFilterCompanyTextField'
        label='Company'
        style={{ marginTop: '10px', width: '100%' }}
        onChange={e => handleChange('companyName', e.target.value)}
        value={filter.companyName}
        variant='outlined'
        size='small'
      />
      <CustomAccordion title={'Company Tags'} style={{ marginTop: '15px' }} defaultExpanded>
        <ListSelector selectedOptions={filter.tags} options={COMPANY_TAGS} onChange={e => setFilter({ ...filter, tags: e })} />
      </CustomAccordion>
      <FormControl style={{ marginTop: '10px', width: '100%' }} variant='outlined' size='small'>
        <InputLabel id='accessLevelSelectLabel'>Access Level</InputLabel>
        <Select
          id='accessLevelSelect'
          labelId='accessLevelSelectLabel'
          value={filter.accessLevel}
          onChange={e => setFilter(f => ({ ...f, accessLevel: e.target.value }))}
          label='Access Level'
        >
          {['All', 'General', 'FullRelease', 'Unreleased'].map(accessLevel => (
            <MenuItem id={accessLevel} value={accessLevel} key={accessLevel}>
              {accessLevel === 'FullRelease' ? 'QA Release' : accessLevel}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}
