import { useMsal } from '@azure/msal-react'
import { CircularProgress, DialogContent, Step, StepLabel, Stepper, Grid, Card, CardContent } from '@mui/material'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@mui/icons-material'
import { CenteredDiv, PrimaryButton, RegularButton, SnackbarVariants, SlideUpDialog } from '@wavetronix/common-components'
import { useSnackbar } from 'notistack'
import React, { useEffect, useRef, useState } from 'react'
import LicenseV2DefinitionsApi from '../../api/LicenseV2DefinitionsApi'
import MasterFeaturesApi from '../../api/MasterFeaturesApi'
import CreateLicenseDefDetails from '../CreateLicenseDefDetails'
import CreateLicenseFirstStep from '../CreateLicenseFirstStep'
import LicenseV2DefFeaturesForm from '../LicenseV2DefFeaturesForm'
import ConfirmPublishLicenseDefModal from './ConfirmPublishLicenseDefModal'

const DEFAULT_FORM = {
  id: '',
  name: '',
  description: '',
  region: '',
  language: '',
  products: [],
  applicableItemNumbers: ['']
}

const STEPS = ['Define License', 'Set Features', 'Confirm Definition']

export default function LicenseV2DefCreateModal(props) {
  const { instance, accounts } = useMsal()
  const [publishModalOpen, setPublishModalOpen] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [buttonsDisabled, setButtonsDisabled] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [form, setForm] = useState(DEFAULT_FORM)
  const [products, setProducts] = useState([])
  const [features, setFeatures] = useState({})
  const [featureMap, setFeatureMap] = useState({})
  const [draft, setDraft] = useState({})

  useEffect(() => {
    let fmap = {}
    for (let f of Object.keys(features)) {
      fmap[f] = {
        id: f,
        type: features[f].possibleTypeList[0],
        maxValue: Math.max(...features[f].licenseBaseList.map(p => p.maxValue)),
        value: ''
      }
    }

    setFeatureMap(fmap)
  }, [features])

  useEffect(() => {
    if (features && products) {
      let pfMap = {}

      //base fill from master feature list
      for (let product of products) {
        pfMap[product] = {}
        for (let feature of Object.values(features)) {
          if (feature.licenseBaseList.map(b => b.id).includes(product)) {
            pfMap[product][feature.id] = {
              ...feature,
              value: '',
              maxValue: feature.licenseBaseList.filter(p => p.id === product)[0].maxValue,
              type: feature.possibleTypeList[0]
            }
          }
        }
      }
      setFeatureMap(pfMap)
    }
  }, [features, products])

  function reset() {
    setActiveStep(0)
    setForm(DEFAULT_FORM)
    setFeatures({})
    setFeatureMap({})
    setDraft({})
    setProducts([])
  }

  function onClose() {
    if (!buttonsDisabled) {
      props.onClose()
      reset()
    }
  }

  const nextButtonDisabled = () =>
    buttonsDisabled ||
    products.length < 1 ||
    !form.name ||
    !form.id ||
    !form.region ||
    !form.language ||
    form.applicableItemNumbers.some(x => !x) ||
    !form.description

  const contentRef = useRef(null)

  async function handleNextStepClick() {
    if (activeStep === 0) {
      setButtonsDisabled(true)
      await LicenseV2DefinitionsApi.getOne(instance, accounts, form.id)
        .then(() => enqueueSnackbar('A product with this item number already exists.', SnackbarVariants.ERROR))
        .catch(async e => {
          if (e.response.status === 404) {
            await MasterFeaturesApi.getAvailableFeaturesByProduct(instance, accounts, products)
              .then(result => {
                setFeatures(result)
                setActiveStep(1)
              })
              .catch(() => enqueueSnackbar('An error occurred.', SnackbarVariants.ERROR))
          } else {
            enqueueSnackbar('An error occurred.', SnackbarVariants.ERROR)
          }
        })
        .finally(() => setButtonsDisabled(false))
    } else {
      // activeStep must equal 1

      let draftProducts = []

      for (let p of products) {
        draftProducts.push({
          baseProductId: p,
          features: Object.values(featureMap[p])
            .filter(f => f.value !== '')
            .map(f => ({ id: f.id, type: f.type, value: f.value, maxValue: f.maxValue }))
        })
      }
      setDraft({ ...form, products: draftProducts })
      setActiveStep(2)
    }
    contentRef.current.scrollTo(0, 0)
  }

  async function publish() {
    setButtonsDisabled(true)
    let key = enqueueSnackbar('Publishing...', SnackbarVariants.LOADING)
    await LicenseV2DefinitionsApi.createLicense(instance, accounts, draft, true)
      .then(async () => {
        await props.refetch()
        closeSnackbar(key)
        enqueueSnackbar('Successfully published license definition.', SnackbarVariants.SUCCESS)
        props.onClose()
        setPublishModalOpen(false)
        reset()
      })
      .catch(() => enqueueSnackbar('Failed to publish license definition', SnackbarVariants.ERROR))
      .finally(() => setButtonsDisabled(false))
  }

  return (
    <>
      <ConfirmPublishLicenseDefModal
        open={publishModalOpen}
        onClose={() => setPublishModalOpen(false)}
        styles={props.styles}
        draft={draft}
        onPublish={publish}
        buttonsDisabled={buttonsDisabled}
      />
      <SlideUpDialog
        id='licenseV2DefCreateModal'
        open={props.open}
        onClose={onClose}
        fullScreen
        title={<h3 style={{ margin: '0px' }}>New License Definition</h3>}
        actions={
          <div style={{ display: 'flex' }}>
            {activeStep > 0 ? (
              <RegularButton
                id='licenseV2DefCreateBackButton'
                disabled={buttonsDisabled}
                onClick={() => setActiveStep(s => s - 1)}
                style={{ marginRight: '10px' }}
              >
                <ArrowLeftOutlined />
                Go Back
              </RegularButton>
            ) : null}
            <div style={{ flex: 1 }} />
            {activeStep < 2 ? (
              <PrimaryButton
                id='licenseV2DefCreateNextButton'
                disabled={nextButtonDisabled()}
                onClick={handleNextStepClick}
                style={{ marginRight: '10px' }}
              >
                Next Step
                {buttonsDisabled ? <CircularProgress size={24} /> : <ArrowRightOutlined />}
              </PrimaryButton>
            ) : (
              <>
                <PrimaryButton
                  id='licenseV2DefCreatePublishButton'
                  disabled={buttonsDisabled}
                  onClick={() => setPublishModalOpen(true)}
                  style={{ marginRight: '10px' }}
                >
                  Publish
                </PrimaryButton>
              </>
            )}
            <RegularButton id='licenseV2DefCreateCancelButton' onClick={onClose} disabled={buttonsDisabled}>
              Cancel
            </RegularButton>
          </div>
        }
      >
        <DialogContent ref={contentRef}>
          <div style={{ minHeight: '200px' }}>
            <Stepper activeStep={activeStep}>
              {STEPS.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === 0 ? (
              <CreateLicenseFirstStep
                form={form}
                setForm={setForm}
                products={products}
                setProducts={setProducts}
                disabled={buttonsDisabled}
              />
            ) : activeStep === 1 ? (
              Object.keys(features).length > 0 ? (
                <CenteredDiv>
                  <div style={{ width: '100%', marginTop: '15px' }}>
                    <LicenseV2DefFeaturesForm
                      disabled={buttonsDisabled}
                      products={products}
                      showTooltip={true}
                      setFeatureMap={setFeatureMap}
                      featureMap={featureMap}
                    />
                  </div>
                </CenteredDiv>
              ) : (
                <CenteredDiv>No available features.</CenteredDiv>
              )
            ) : (
              <Grid container spacing={2}>
                <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                  <Card sx={{ marginTop: '15px' }}>
                    <CardContent>
                      <CreateLicenseDefDetails draft={draft} />
                      <h4 style={{ margin: '0px', paddingTop: '15px', paddingBottom: '15px' }}>Applicable Item Numbers</h4>
                      {draft.applicableItemNumbers.map((n, i) => n + (i === draft.applicableItemNumbers.length - 1 ? '' : ', '))}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                  <CenteredDiv>
                    <h2>Products</h2>
                  </CenteredDiv>
                  {draft.products.length > 0 ? (
                    <LicenseV2DefFeaturesForm
                      disabled={true}
                      products={products}
                      showTooltip={false}
                      setFeatureMap={setFeatureMap}
                      featureMap={featureMap}
                    />
                  ) : (
                    <CenteredDiv>No features selected.</CenteredDiv>
                  )}
                </Grid>
              </Grid>
            )}
          </div>
        </DialogContent>
      </SlideUpDialog>
    </>
  )
}
