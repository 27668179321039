import { useMsal } from '@azure/msal-react'
import { CenteredDiv, CustomDataGrid, ErrorMessage, RegularButton, PersistantFilterDiv } from '@wavetronix/common-components'
import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import UsersApi from '../../api/UsersApi'
import UsersFilterDrawer, { DEFAULT_USERS_FILTER, filterUsers } from '../drawers/UsersFilterDrawer'
import EditUsersModal from '../modals/EditUsersModal'
import UserModal from '../modals/UserModal'
import UsersOverridesModal from '../modals/UsersOverridesModal'
import { LEVEL_ROLE_ENUM_HASH } from '../../utils/AccessLevelMap'
import { env } from '../../index.js'

export default function UsersPage(props) {
  const [pageSize, setPageSize] = useState(100)
  const [filter, setFilter] = useState(DEFAULT_USERS_FILTER)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [userModalState, setUserModalState] = useState({
    open: false,
    user: {}
  })
  const { instance, accounts } = useMsal()

  async function refreshSelectedRows() {
    setSelectedRows([])
    await refetch()
  }

  const columns = [
    {
      field: 'givenName',
      headerName: 'First Name',
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'surname',
      headerName: 'Last Name',
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'company',
      headerName: 'Company',
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'updatesAccessLevel',
      headerName: 'Access',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: x => LEVEL_ROLE_ENUM_HASH[x.row.updatesAccessLevel[env.basicAuthentication.fromCompany]]
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      disableColumnMenu: true
    }
  ]

  const { data, isLoading, refetch, error } = useQuery({
    queryKey: ['users'],
    queryFn: async () => await UsersApi.getUsers(instance, accounts)
  })

  const [overridesModalState, setOverridesModalState] = useState({
    open: false,
    users: []
  })
  const [rows, setRows] = useState([])

  useEffect(() => {
    setUserModalState(s => (s.user && s.user.id && data ? { ...s, user: data.find(u => u.id === s.user.id) } : s))
    setSelectedRows(rows =>
      data
        ? filterUsers(
            filter,
            data.filter(u1 => rows.some(u2 => u1.id === u2.id))
          )
        : rows
    )
  }, [data, filter])

  useEffect(() => {
    if (data) {
      setRows(filterUsers(filter, data))
    }
  }, [data, filter])

  useEffect(() => {
    setOverridesModalState(s =>
      data
        ? {
            ...s,
            users: data.filter(u1 => s.users.some(u2 => u1.id === u2.id))
          }
        : s
    )
  }, [data])

  if (error) {
    return (
      <CenteredDiv>
        <ErrorMessage error={error} />
      </CenteredDiv>
    )
  }

  return (
    <PersistantFilterDiv
      page={
        <>
          <UserModal
            open={userModalState.open}
            onClose={() => setUserModalState({ open: false, user: {} })}
            user={userModalState.user}
            styles={props.styles}
            refetch={refetch}
          />
          <EditUsersModal
            open={editModalOpen}
            onClose={() => setEditModalOpen(false)}
            users={selectedRows}
            styles={props.styles}
            refresh={refreshSelectedRows}
          />
          <UsersOverridesModal
            open={overridesModalState.open}
            onClose={() => setOverridesModalState({ open: false, users: [] })}
            users={overridesModalState.users}
            styles={props.styles}
            refetch={refetch}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ float: 'left', margin: '16px' }}>
              <RegularButton
                id='usersEditButton'
                disabled={selectedRows.length === 0}
                onClick={() =>
                  selectedRows.length === 1 ? setUserModalState({ open: true, user: selectedRows[0] }) : setEditModalOpen(true)
                }
              >
                Edit
              </RegularButton>
              &emsp;
              <RegularButton
                id='usersOverridesButton'
                disabled={selectedRows.length === 0}
                onClick={() =>
                  selectedRows.length === 1
                    ? setUserModalState({ open: true, user: selectedRows[0] })
                    : setOverridesModalState({ open: true, users: selectedRows })
                }
              >
                Overrides
              </RegularButton>
            </div>

            <div style={{ float: 'right' }}></div>
          </div>
          <div style={{ width: '100%', paddingLeft: '15px' }}>
            <CustomDataGrid
              rows={rows}
              autoHeight
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={p => setPageSize(p.pageSize)}
              rowsPerPageOptions={[10, 20, 50, 100]}
              disableSelectionOnClick
              loading={isLoading}
              disableExtendRowFullWidth={true}
              onRowClick={e => setUserModalState({ open: true, user: e.row })}
              onColumnHeaderClick={e =>
                e.field === '__check__' ? setSelectedRows(selectedRows.length > 0 ? [] : filterUsers(filter, data)) : null
              }
              checkboxSelection
              onSelectionModelChange={ids => {
                const selectedIDs = new Set(ids)
                const selectedRows = rows.filter(row => selectedIDs.has(row.id))
                setSelectedRows(selectedRows)
              }}
              cursor='pointer'
            />
          </div>
        </>
      }
      drawer={<UsersFilterDrawer setFilter={setFilter} filter={filter} />}
      resetFilter={() => setFilter(DEFAULT_USERS_FILTER)}
    />
  )
}
