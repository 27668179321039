import { IconButton, TextField, Card, CardContent, CircularProgress } from '@mui/material'
import { AddOutlined, DeleteOutlined } from '@mui/icons-material'
import { CenteredDiv, GhostButton, RegularButton, WtxColors } from '@wavetronix/common-components'
import React, { useState } from 'react'
import ItemTimeline from './ItemTimeline'
import SelectLanguage from './selects/SelectLanguage'
import SelectRegion from './selects/SelectRegion'
import ConfirmModalV2 from './modals/ConfirmModalV2'

export const DEFAULT_FORM = {
  id: '',
  name: '',
  description: '',
  region: '',
  language: '',
  products: [],
  applicableItemNumbers: ['']
}

export default function EditLicenseDefDetails({ variant, definition, form, setForm, history, refetch, onClose }) {
  const handleFormChange = (id, value) => setForm(f => ({ ...f, [id]: value }))

  const [hideUnhideModalState, setHideUnhideModalState] = useState({
    open: false,
    definition: {}
  })
  const [deleteModalState, setDeleteModalState] = useState({
    open: false,
    definition: {}
  })

  if (form.applicableItemNumbers) {
    return (
      <>
        <Card sx={{ margin: '10px' }}>
          <CardContent>
            <TextField
              id='editLicenseDefDetailsNameTextField'
              size='small'
              style={{ width: '100%', marginTop: '15px' }}
              label='Product Name'
              onChange={e => handleFormChange('name', e.target.value)}
              value={form.name}
              required
            />
            <TextField
              id='editLicenseDefDetailsItemNumberTextField'
              size='small'
              style={{ width: '100%', marginTop: '15px' }}
              label='Item Number'
              value={definition.id ? definition.id : ''}
              disabled
            />
            <SelectRegion
              id='editLicenseDefDetailsRegionSelect'
              onChange={e => setForm(f => ({ ...f, region: e.target.value }))}
              style={{ width: '100%', marginTop: '15px' }}
              value={form.region}
              required
            />
            <SelectLanguage
              id='editLicenseDefDetailsLanguageSelect'
              onChange={e => setForm(f => ({ ...f, language: e.target.value }))}
              style={{ width: '100%', marginTop: '15px' }}
              value={form.language}
              required
            />
            <TextField
              id='editLicenseDefDetailsDescriptionTextField'
              style={{ width: '100%', marginTop: '15px' }}
              label='Description'
              onChange={e => handleFormChange('description', e.target.value)}
              value={form.description}
              required
              multiline
              rows={4}
              variant='outlined'
            />
            <>
              <div style={{ width: '100%', marginTop: '15px', paddingBottom: '15px' }}>
                <b>Applicable Item Numbers</b>
              </div>
              {form.applicableItemNumbers.map((n, i) => (
                <CenteredDiv key={`applicableItemNumber-${i}`}>
                  <TextField
                    id={`${n}applicableItemNumberTextField`}
                    size='small'
                    onChange={e => {
                      let applicableItemNumbers = form.applicableItemNumbers
                      applicableItemNumbers[i] = e.target.value
                      setForm(f => ({
                        ...f,
                        applicableItemNumbers: applicableItemNumbers
                      }))
                    }}
                    style={{ width: '100%' }}
                    value={n}
                    required
                    label='Applicable Item Number'
                  />
                  <IconButton
                    id={`${n}applicableItemNumberDeleteButton`}
                    onClick={() => {
                      if (form.applicableItemNumbers.length > 1) {
                        let applicableItemNumbers = [...form.applicableItemNumbers]
                        applicableItemNumbers.splice(i, 1)
                        setForm(f => ({
                          ...f,
                          applicableItemNumbers: applicableItemNumbers
                        }))
                      }
                    }}
                    disabled={form.applicableItemNumbers.length < 2}
                    size='large'
                  >
                    <DeleteOutlined
                      style={{
                        color: form.applicableItemNumbers.length > 1 ? WtxColors.INNOVATION_RED : WtxColors.CONCRETE
                      }}
                    />
                  </IconButton>
                </CenteredDiv>
              ))}
              <CenteredDiv>
                <RegularButton
                  id='addApplicableItemNumberButton'
                  style={{ width: '100%', marginTop: '15px' }}
                  startIcon={<AddOutlined />}
                  onClick={() =>
                    setForm(f => ({
                      ...f,
                      applicableItemNumbers: [...form.applicableItemNumbers, '']
                    }))
                  }
                >
                  Add Applicable Item Number
                </RegularButton>
              </CenteredDiv>
            </>

            <div style={{ marginTop: '15px' }}>
              <CenteredDiv>
                {definition.state === 'Hidden' ? (
                  <div style={{ marginTop: '15px' }}>
                    <GhostButton
                      id='editLicenseDeleteHiddenButton'
                      style={{
                        backgroundColor: WtxColors.INNOVATION_RED,
                        borderColor: WtxColors.INNOVATION_RED,
                        marginRight: '10px'
                      }}
                      onClick={() => setDeleteModalState({ open: true, definition: definition })}
                    >
                      Delete
                    </GhostButton>
                    <RegularButton
                      id='editLicenseHideButton'
                      onClick={() => setHideUnhideModalState({ open: true, definition: definition })}
                    >
                      {definition.state === 'Hidden' ? 'Unhide' : 'Hide'}
                    </RegularButton>
                  </div>
                ) : definition.state === 'Draft' ? (
                  <GhostButton
                    id='editLicenseDeleteDraftButton'
                    style={{
                      backgroundColor: WtxColors.INNOVATION_RED,
                      borderColor: WtxColors.INNOVATION_RED,
                      float: 'right',
                      marginLeft: '15px'
                    }}
                    onClick={() => setDeleteModalState({ open: true, definition: definition })}
                  >
                    Delete
                  </GhostButton>
                ) : (
                  <RegularButton
                    id='editLicenseHideDraftButton'
                    style={{ float: 'right' }}
                    onClick={() => setHideUnhideModalState({ open: true, definition: definition })}
                  >
                    {definition.state === 'Hidden' ? 'Unhide' : 'Hide'}
                  </RegularButton>
                )}
              </CenteredDiv>
            </div>
          </CardContent>
        </Card>
        <Card sx={{ margin: '10px' }}>
          <CardContent>
            <h3 style={{ margin: '0px' }}>History</h3>
            <ItemTimeline history={history} />
          </CardContent>
        </Card>
        <ConfirmModalV2
          open={hideUnhideModalState.open}
          onClose={() => {
            setHideUnhideModalState(s => ({
              ...s,
              open: false,
              definition: {}
            }))
            onClose()
          }}
          action={definition.state === 'Hidden' ? 'Unhide' : 'Hide'}
          data={[hideUnhideModalState.definition]}
          variant={'license definitions'}
          refresh={refetch}
        />
        <ConfirmModalV2
          open={deleteModalState.open}
          onClose={() => {
            setDeleteModalState(s => ({ open: false, definition: {} }))
            onClose()
          }}
          action={'Delete'}
          data={[deleteModalState.definition]}
          variant={'license definitions'}
          refresh={refetch}
        />
      </>
    )
  } else {
    return (
      <CenteredDiv>
        <CircularProgress />
      </CenteredDiv>
    )
  }
}
