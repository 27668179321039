import axios from 'axios'
import { env } from '../index.js'
import { acquireAccessToken } from '@wavetronix/common-components'
import { removeWhiteSpace } from '../utils/ReleasePhaseMap'

let instance = null

class UpdatesApi {
  getUpdates = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .get(`${env.urls.updatesURL}/api/v1/updates`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  removeOverride = async (msalInstance, accounts, updateId, userId) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return axios
      .delete(`${env.urls.updatesURL}/api/v1/updates/overrides/${updateId}/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  addOverride = async (msalInstance, accounts, updateId, userId) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .post(
        `${env.urls.updatesURL}/api/v1/updates/overrides/${updateId}`,
        {
          userId: `${userId}`
        },
        {
          headers: { Authorization: `Bearer ${token}` },
          timeout: 30000
        }
      )
      .then(res => res.data)
  }

  hideOrUnhideUpdate = async (msalInstance, accounts, updateId, value) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .patch(
        `${env.urls.updatesURL}/api/v1/updates/${updateId}`,
        [
          {
            value,
            path: 'isHidden',
            op: 'replace'
          }
        ],
        {
          headers: { Authorization: `Bearer ${token}` },
          timeout: 30000
        }
      )
      .then(res => res.data)
  }

  getUpdate = async (msalInstance, accounts, updateId) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .get(`${env.urls.updatesURL}/api/v1/updates/${updateId}`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  editUpdate = async (msalInstance, accounts, update) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    update.releasePhase = removeWhiteSpace(update.releasePhase)
    return axios
      .put(`${env.urls.updatesURL}/api/v1/updates/${update.id}`, update, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  deleteUpdate = async (msalInstance, accounts, updateId) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .delete(`${env.urls.updatesURL}/api/v1/updates/${updateId}`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  permanentDeleteUpdate = async (msalInstance, accounts, updateId) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .delete(`${env.urls.updatesURL}/api/v1/updates/permanentdelete/${updateId}`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }
}

const getInstance = () => {
  if (instance == null) {
    instance = new UpdatesApi()
  }
  return instance
}

export default getInstance()
