import { CustomDataGrid } from '@wavetronix/common-components'
import React, { useState } from 'react'
import getDeviceName from '../../../utils/getDeviceName'

export default function UpdatesManagerTable({ updates }) {
  const columns = [
    {
      field: 'baseProductId',
      headerName: 'Type',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: u => getDeviceName(u.value)
    },
    { field: 'itemNumber', headerName: 'Item No.', flex: 1, disableColumnMenu: true },
    { field: 'version', headerName: 'Version No.', flex: 1, disableColumnMenu: true },
    { field: 'releasePhase', headerName: 'Release Phase', flex: 1, disableColumnMenu: true },
    { field: 'region', headerName: 'Region', flex: 1, disableColumnMenu: true },
    { field: 'language', headerName: 'Language', flex: 1, disableColumnMenu: true }
  ]

  const [pageSize, setPageSize] = useState(50)

  return (
    <div style={{ margin: '10px 10px 10px 0px' }}>
      <CustomDataGrid
        rows={updates ? updates : []}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={p => setPageSize(p.pageSize)}
        rowsPerPageOptions={[10, 20, 50, 100]}
        disableSelectionOnClick
        disableExtendRowFullWidth={true}
      />
    </div>
  )
}
