import { Chip } from '@mui/material'
import { CustomDataGrid, WtxColors, RegularButton, PersistantFilterDiv } from '@wavetronix/common-components'
import React, { useState } from 'react'
import { AddOutlined } from '@mui/icons-material'
import compareAlphabetically from '../../utils/compareAlphabetically'
import getDeviceName from '../../utils/getDeviceName'
import DefinitionsFilterDrawer, { DEFAULT_DEFINITIONS_FILTER, filterDefinitions } from '../drawers/DefinitionsFilterDrawer'
import ConfirmModal from '../modals/ConfirmModal'
import dayjs from 'dayjs'

export default function DefinitionsPage(props) {
  const [pageSize, setPageSize] = useState(100)
  const [filter, setFilter] = useState(DEFAULT_DEFINITIONS_FILTER)
  const [hideUnhideModalState, setHideUnhideModalState] = useState({
    open: false,
    definition: {}
  })
  const [deleteModalState, setDeleteModalState] = useState({
    open: false,
    definition: {}
  })
  const [mouseIsOverActionButton] = useState(false)

  const columns = [
    {
      field: 'baseProductId',
      headerName: 'Type',
      flex: 1,
      disableColumnMenu: true,
      sortComparator: compareAlphabetically,
      valueGetter: p => getDeviceName(p.value)
    },
    {
      field: 'itemNumber',
      headerName: 'Item No.',
      flex: 1,
      disableColumnMenu: true,
      sortComparator: compareAlphabetically,
      renderCell: p => (
        <>
          {p.value}&nbsp;&nbsp;
          {p.row.state === 'Draft' ? (
            <Chip
              label={'Draft'}
              variant='outlined'
              style={{
                color: WtxColors.INNOVATION_RED,
                borderColor: WtxColors.INNOVATION_RED,
                cursor: 'pointer'
              }}
            />
          ) : (
            ''
          )}
        </>
      )
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'version',
      headerName: 'Version',
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'region',
      headerName: 'Region',
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'language',
      headerName: 'Language',
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'publishDate',
      headerName: 'Publish Date',
      flex: 1,
      disableColumnMenu: true,
      sortComparator: compareAlphabetically,
      valueGetter: u => dayjs(u.value).format('MM/DD/YYYY hh:mm A')
    }
  ]

  return (
    <>
      <ConfirmModal
        open={hideUnhideModalState.open}
        onClose={() =>
          setHideUnhideModalState(s => ({
            ...s,
            open: false,
            definition: {}
          }))
        }
        action={filter.showHidden ? 'Unhide' : 'Hide'}
        data={[hideUnhideModalState.definition]}
        styles={props.styles}
        variant={props.variant}
        refresh={props.refetch}
      />
      <ConfirmModal
        open={deleteModalState.open}
        onClose={() => setDeleteModalState(s => ({ open: false, definition: {} }))}
        action={'Delete'}
        data={[deleteModalState.definition]}
        styles={props.styles}
        variant={props.variant}
        refresh={props.refetch}
      />
      <PersistantFilterDiv
        page={
          <div>
            {props.variant !== 'license definitions' ? (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ float: 'left', margin: '16px' }}>
                  <RegularButton id='definitionCreateButton' onClick={props.openCreateModal}>
                    <AddOutlined />
                    &nbsp; Create New
                  </RegularButton>
                </div>

                <div style={{ float: 'right' }}></div>
              </div>
            ) : null}

            <div style={{ width: '100%', paddingLeft: '15px' }}>
              <CustomDataGrid
                rows={
                  props.data
                    ? filterDefinitions(filter, props.data).map((p, i) => ({
                        ...p,
                        id: i
                      }))
                    : []
                }
                autoHeight
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={p => setPageSize(p.pageSize)}
                rowsPerPageOptions={[10, 20, 50, 100]}
                checkboxSelection={false}
                disableSelectionOnClick
                onRowClick={e => (mouseIsOverActionButton ? null : props.onRowClick(e))}
                loading={props.loading}
                disableExtendRowFullWidth={true}
                cursor='pointer'
              />
            </div>
          </div>
        }
        drawer={<DefinitionsFilterDrawer filter={filter} setFilter={setFilter} />}
        resetFilter={() => setFilter(DEFAULT_DEFINITIONS_FILTER)}
      />
    </>
  )
}
