import { DialogContent } from '@mui/material'
import { CustomAccordion, PrimaryButton, RegularButton, SlideUpDialog, WtxColors } from '@wavetronix/common-components'
import React from 'react'
import { js2xml } from 'xml-js'
import getDeviceName from '../../utils/getDeviceName'

const generateXmlPreview = (license, product) => {
  if (!license || !product) {
    return ''
  }

  let xmlJson = {
    productDefinition: {
      itemNumber: license.id,
      version: '1.0',
      featureList: {
        feature: product.features
      },
      productBase: product.baseProductId,
      marketingInfoList: {
        marketingInfo: {
          tempName: license.name,
          description: license.description
        }
      }
    }
  }
  let xmlPreview = js2xml(xmlJson, { compact: true, ignoreComment: true, spaces: 2 })
    .replace(/\n *<name.*\/(name)?>/g, '')
    .replace('<marketingInfo>', `<marketingInfo langId="${license.language}">`)
    .replace('<productDefinition>', '<productDefinition sVer="1.0">')
    .replace(/tempName/g, 'name')
  return '<?xml version="1.0" encoding="utf-8"?>\n' + xmlPreview
}

export default function ConfirmPublishLicenseDefModal(props) {
  const onClose = () => (props.buttonsDisabled ? null : props.onClose())

  return (
    <SlideUpDialog
      id='confirmPublishLicenseDefModal'
      open={props.open}
      onClose={onClose}
      title={<h3 style={{ margin: '0px' }}>Confirm Publish</h3>}
      actions={
        <div>
          <PrimaryButton
            id='licenseDefPublishButton'
            onClick={() => props.onPublish(props.draft)}
            disabled={props.buttonsDisabled}
            style={{ marginRight: '10px' }}
          >
            Publish
          </PrimaryButton>

          <RegularButton id='licenseDefCancelButton' onClick={props.onClose} disabled={props.buttonsDisabled}>
            Cancel
          </RegularButton>
        </div>
      }
    >
      <DialogContent>
        <div style={{ paddingBottom: '20px' }}>Are you sure you want to publish this license definition?</div>
        {props.draft && props.draft.products
          ? props.draft.products.map(product => {
              return (
                <CustomAccordion
                  id={product.id}
                  children={
                    <div
                      style={{
                        marginTop: '15px',
                        height: '55%',
                        width: '100%',
                        overflow: 'auto',
                        backgroundColor: 'black',
                        color: WtxColors.CONCRETE
                      }}
                    >
                      <pre>
                        {generateXmlPreview(props.draft, { features: product.features, baseProductId: product.baseProductId })}
                      </pre>
                    </div>
                  }
                  title={`XML Preview - ${getDeviceName(product.baseProductId)}`}
                  key={product.baseProductId}
                />
              )
            })
          : null}
      </DialogContent>
    </SlideUpDialog>
  )
}
