import { CssBaseline } from '@mui/material'
import { MenuAppBar, NotFoundPage } from '@wavetronix/common-components'
import React, { useState, useEffect } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { env } from '../../index.js'
import PageNavbar from '../PageNavbar'
import LicenseDefinitionsPage from './LicenseDefinitionsPage'
import ProductDefinitionsPage from './ProductDefinitionsPage'
import UpdatesPage from './UpdatesPage'
import UsersPage from './UsersPage'
import BaseProductHashPage from './BaseProductHashPage'
import version from '../../env/version.json'

const classes = {
  paper: {
    width: 300
  },
  dataGrid: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none'
    }
  },
  filterTitle: {
    fontFamily: 'Klavika',
    fontSize: 22
  },
  title: {
    fontFamily: 'Klavika',
    fontSize: 26
  },
  inlineTitle: {
    fontFamily: 'Klavika',
    fontSize: '26px',
    display: 'inline'
  }
}

const URL_MAP = {
  wavetronix: { updates: 0, users: 1, products: 2, licenses: 3, baseproducthash: 4, '': 0 },
  islandradar: { updates: 0, users: 1, baseproducthash: 2, '': 0 },
  fathom: { updates: 0, users: 1, baseproducthash: 2, '': 0 }
}

export default function MainPage() {
  const [state, setState] = useState({
    current: window.location.pathname.substring(1)
  })
  const [selectedState, setSelectedState] = useState(0)

  useEffect(() => {
    if (state) {
      setSelectedState(URL_MAP[env.basicAuthentication.fromCompany][state.current])
    }
  }, [state])

  return (
    <>
      <CssBaseline />
      <MenuAppBar env={env} fromCompany={env.basicAuthentication.fromCompany} />
      <BrowserRouter>
        <div style={{ width: '100%' }}>
          <Switch>
            <Route path='/' exact>
              <Redirect to='/updates' />
            </Route>
            <Route
              path='/updates'
              render={() => (
                <div className='content' style={{ display: 'flex' }}>
                  <PageNavbar value={selectedState} onChangeCallback={id => setState(s => ({ ...s, current: id }))} />
                  <div style={{ width: '100%' }}>
                    <UpdatesPage styles={classes} />
                  </div>
                </div>
              )}
              exact
            />
            <Route
              path='/users'
              render={() => (
                <div className='content' style={{ display: 'flex' }}>
                  <PageNavbar value={selectedState} onChangeCallback={id => setState(s => ({ ...s, current: id }))} />
                  <div style={{ width: '100%' }}>
                    <UsersPage styles={classes} />
                  </div>
                </div>
              )}
              exact
            />
            {env.basicAuthentication.fromCompany === 'wavetronix' ? (
              <Route
                path='/products'
                render={() => (
                  <div className='content' style={{ display: 'flex' }}>
                    <PageNavbar value={selectedState} onChangeCallback={id => setState(s => ({ ...s, current: id }))} />
                    <div style={{ width: '100%' }}>
                      <ProductDefinitionsPage styles={classes} />
                    </div>
                  </div>
                )}
                exact
              />
            ) : null}
            {env.basicAuthentication.fromCompany === 'wavetronix' ? (
              <Route
                path='/licenses'
                render={() => (
                  <div className='content' style={{ display: 'flex' }}>
                    <PageNavbar value={selectedState} onChangeCallback={id => setState(s => ({ ...s, current: id }))} />
                    <div style={{ width: '100%' }}>
                      <LicenseDefinitionsPage styles={classes} />
                    </div>
                  </div>
                )}
                exact
              />
            ) : null}

            <Route
              path='/baseproducthash'
              render={() => (
                <div className='content' style={{ display: 'flex' }}>
                  <PageNavbar value={selectedState} onChangeCallback={id => setState(s => ({ ...s, current: id }))} />
                  <div style={{ width: '100%' }}>
                    <BaseProductHashPage styles={classes} />
                  </div>
                </div>
              )}
              exact
            />
            <Route path='/version' render={() => <div id='version'>{version.version}</div>} exact />
            {/* This wildcard route needs to stay at the bottom */}
            <Route path='*' component={NotFoundPage} />
          </Switch>
        </div>
      </BrowserRouter>
    </>
  )
}
