import { CustomDataGrid, RegularButton, PersistantFilterDiv } from '@wavetronix/common-components'
import React, { useState } from 'react'
import { AddOutlined } from '@mui/icons-material'
import compareAlphabetically from '../../utils/compareAlphabetically'
import getDeviceName from '../../utils/getDeviceName'
import LicensesDefFilterDrawer, {
  DEFAULT_LICENSE_DEFINITIONS_FILTER,
  filterLicenseDefinitions
} from '../drawers/LicensesDefFilterDrawer'
import ConfirmModal from '../modals/ConfirmModal'
import dayjs from 'dayjs'

export default function LicenseDefinitions(props) {
  const [pageSize, setPageSize] = useState(100)
  const [filter, setFilter] = useState(DEFAULT_LICENSE_DEFINITIONS_FILTER)
  const [hideUnhideModalState, setHideUnhideModalState] = useState({
    open: false,
    definition: {}
  })
  const [deleteModalState, setDeleteModalState] = useState({
    open: false,
    definition: {}
  })
  const [mouseIsOverActionButton] = useState(false)

  const columns = [
    {
      field: 'id',
      headerName: 'Item No.',
      flex: 1,
      disableColumnMenu: true,
      sortComparator: compareAlphabetically,
      valueGetter: u => getDeviceName(u.value)
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'region',
      headerName: 'Region',
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'language',
      headerName: 'Language',
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'products',
      headerName: 'Products',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: u => u.row.products.map(p => getDeviceName(p.baseProductId)).join(', ')
    },
    {
      field: 'publishDate',
      headerName: 'Publish Date',
      flex: 1,
      disableColumnMenu: true,
      sortComparator: compareAlphabetically,
      valueGetter: u => dayjs(u.value).format('MM/DD/YYYY hh:mm A')
    }
  ]

  return (
    <>
      <ConfirmModal
        open={hideUnhideModalState.open}
        onClose={() =>
          setHideUnhideModalState(s => ({
            ...s,
            open: false,
            definition: {}
          }))
        }
        action={filter.showHidden ? 'Unhide' : 'Hide'}
        data={[hideUnhideModalState.definition]}
        styles={props.styles}
        variant={props.variant}
        refresh={props.refetch}
      />
      <ConfirmModal
        open={deleteModalState.open}
        onClose={() => setDeleteModalState(s => ({ open: false, definition: {} }))}
        action={'Delete'}
        data={[deleteModalState.definition]}
        styles={props.styles}
        variant={props.variant}
        refresh={props.refetch}
      />
      <PersistantFilterDiv
        page={
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ float: 'left', margin: '16px' }}>
                <RegularButton id='licenseDefCreateButton' onClick={props.openCreateModal}>
                  <AddOutlined />
                  &nbsp; Create New
                </RegularButton>
              </div>

              <div style={{ float: 'right' }}></div>
            </div>
            <div style={{ width: '100%', paddingLeft: '15px' }}>
              <CustomDataGrid
                rows={props.data ? filterLicenseDefinitions(filter, props.data) : []}
                autoHeight
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={p => setPageSize(p.pageSize)}
                rowsPerPageOptions={[10, 20, 50, 100]}
                checkboxSelection={false}
                disableSelectionOnClick
                onRowClick={e => (mouseIsOverActionButton ? null : props.onRowClick(e))}
                loading={props.loading}
                disableExtendRowFullWidth={true}
                cursor='pointer'
                getRowClassName={params => `isHidden-${params.row.state === 'Hidden' ? true : false}`}
              />
            </div>
          </div>
        }
        drawer={<LicensesDefFilterDrawer filter={filter} setFilter={setFilter} />}
        resetFilter={() => setFilter(DEFAULT_LICENSE_DEFINITIONS_FILTER)}
      />
    </>
  )
}
