import { HelpOutline } from '@mui/icons-material'
import LinkIcon from '@mui/icons-material/Link'
import { Switch, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Tooltip, IconButton } from '@mui/material'
import { CenteredDiv, CustomAccordion, CustomSelect, SnackbarVariants, WtxColors } from '@wavetronix/common-components'
import { useSnackbar } from 'notistack'
import React from 'react'
import getDeviceName from '../utils/getDeviceName'
import { DeleteOutlined } from '@mui/icons-material'

const classes = {
  accordionList: {
    marginTop: '15px',
    borderRadius: '5px',
    width: '100%',
    boxShadow: 'none',
    border: '1px solid rgb(196, 196, 196)',
    '&:before': {
      display: 'none'
    }
  },
  accordionSummary: {
    width: '100%',
    borderRadius: '5px',
    minHeight: '40px'
  },
  accordionDetails: {
    padding: '0px',
    margin: '0px',
    marginTop: '-15px'
  }
}

export default function LicenseV2DefFeaturesForm({
  disabled,
  products,
  showTooltip,
  setFeatureMap,
  featureMap,
  removeProduct,
  editOnly = false
}) {
  const { enqueueSnackbar } = useSnackbar()

  const handleFeatureSwitchValueChange = (productId, featureId, value, type) =>
    disabled ? null : updateProductFeature(productId, featureId, value ? 'true' : '', type)

  function handleFeatureInputValueChange(productId, featureId, value, type) {
    if (value === '') {
      updateProductFeature(productId, featureId, value, type)
    } else {
      if (!Number.isNaN(Number(value))) {
        let feature = featureMap[productId][featureId]
        if (Number(value) <= feature.maxValue) {
          updateProductFeature(productId, featureId, value, type)
        } else {
          enqueueSnackbar(`Max value for ${feature.name} is ${feature.maxValue}`, SnackbarVariants.ERROR)
        }
      }
    }
  }

  const updateProductFeature = (productId, featureId, value, type) => {
    let newMap = { ...featureMap }
    for (let product of products) {
      if (featureId in newMap[product]) {
        newMap[product][featureId] = { ...newMap[product][featureId], value: value, type: type }
      }
    }

    setFeatureMap(newMap)
  }

  return Object.keys(featureMap).length > 0 ? (
    <>
      {showTooltip === true ? (
        <h4>
          {' '}
          <LinkIcon style={{ marginRight: '5px', marginBottom: '-7px' }} />
          Feature overlaps between products will default to the most recently entered value
        </h4>
      ) : null}
      {Object.keys(featureMap).map(p => {
        return (
          <div key={p} style={{ display: 'flex' }}>
            <CustomAccordion
              defaultExpanded
              title={getDeviceName(p)}
              style={classes.accordionList}
              titleStyle={classes.accordionSummary}
              detailStyle={classes.accordionDetails}
              children={
                <TableContainer>
                  <Table>
                    <TableBody>
                      {Object.values(featureMap[p]).map((f, i) => {
                        let overlapProducts = featureMap[p][f.id].licenseBaseList
                          .filter(b => products.includes(b.id))
                          .map(b => b.id)

                        return (
                          <TableRow key={i}>
                            {showTooltip === true ? (
                              <TableCell style={{ width: '15px' }}>
                                <Tooltip title={f.description}>
                                  <HelpOutline style={{ marginLeft: '5px', marginBottom: '-7px' }} />
                                </Tooltip>
                              </TableCell>
                            ) : null}

                            <TableCell style={{ minWidth: '75%' }}>
                              <div style={{ display: 'flex' }}>
                                {f.type === 'Bool' ? (
                                  <div>
                                    <Switch
                                      id={`${f.id}Switch`}
                                      disabled={disabled}
                                      color='primary'
                                      checked={f.value === 'true'}
                                      onChange={e => handleFeatureSwitchValueChange(p, f.id, e.target.checked, f.type)}
                                    />
                                    {f.name}
                                  </div>
                                ) : (
                                  <div style={{ width: '75%' }}>
                                    <TextField
                                      id={`${f.id}TextField`}
                                      disabled={disabled}
                                      onChange={e => handleFeatureInputValueChange(p, f.id, e.target.value, f.type)}
                                      label={f.name}
                                      value={f.value}
                                      size='small'
                                      style={{ width: '100%' }}
                                    />
                                  </div>
                                )}
                              </div>
                            </TableCell>
                            {showTooltip === true && overlapProducts.length > 1 ? (
                              <TableCell style={{ width: '15px' }}>
                                <Tooltip
                                  title={overlapProducts
                                    .map(op => getDeviceName(op))
                                    .sort()
                                    .join(', ')}
                                >
                                  <LinkIcon style={{ marginLeft: '5px', marginBottom: '-7px' }} />
                                </Tooltip>
                              </TableCell>
                            ) : null}
                            <TableCell style={{ width: '25%' }}>
                              <CustomSelect
                                id={`${f.id}Select`}
                                style={{ width: '100%' }}
                                onChange={e => updateProductFeature(p, f.id, f.value, e.target.value)}
                                label='Type'
                                value={f.type}
                                options={f.possibleTypeList}
                                disabled={f.possibleTypeList.length < 2 || disabled}
                              />
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              }
            />
            {editOnly ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <IconButton
                  id='licenseRemoveProductButton'
                  onClick={() => {
                    removeProduct(p)
                  }}
                  size='large'
                >
                  <DeleteOutlined
                    style={{
                      color: WtxColors.INNOVATION_RED
                    }}
                  />
                </IconButton>
              </div>
            ) : null}
          </div>
        )
      })}
    </>
  ) : (
    <CenteredDiv>No features selected.</CenteredDiv>
  )
}
