import { useMsal } from '@azure/msal-react'
import { CenteredDiv, ErrorMessage } from '@wavetronix/common-components'
import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import LicenseDefinitionsApi from '../../api/LicenseDefinitionsApi'
import LicenseDefCreateModal from '../modals/LicenseDefCreateModal'
import LicenseDefinitionModal from '../modals/LicenseDefinitionModal'
import DefinitionsPage from './DefinitionsPage'
import LicenseV2DefinitionsApi from '../../api/LicenseV2DefinitionsApi'
import LicenseV2DefCreateModal from '../modals/LicenseV2DefCreateModal'
import LicenseV2DefinitionModal from '../modals/LicenseV2DefinitionModal'
import LicenseDefinitions from './LicenseDefinitions'
import { Switch } from '@mui/material'

export default function LicenseDefinitionsPage(props) {
  const { instance, accounts } = useMsal()
  const [licenseDefModalState, setLicenseDefModalState] = useState({ open: false, licenseDef: {} })
  const [licenseDefCreateModalOpen, setLicenseDefCreateModalOpen] = useState(false)
  const [licenseDefV2ModalState, setLicenseDefV2ModalState] = useState({ open: false, licenseDef: {} })
  const [licenseDefV2CreateModalOpen, setLicenseDefV2CreateModalOpen] = useState(false)
  const [useDeprecated, setUseDeprecated] = useState(false)
  const { data, refetch, isLoading, error } = useQuery({
    queryKey: ['licenseDefs'],
    queryFn: async () => await LicenseDefinitionsApi.getLicenseDefinitions(instance, accounts)
  })

  useEffect(
    () =>
      setLicenseDefModalState(s => {
        if (s.licenseDef && s.licenseDef.itemNumber && data) {
          let licenseDef = data.find(ld => ld.itemNumber === s.licenseDef.itemNumber && ld.state === s.licenseDef.state)
          return { ...s, licenseDef: licenseDef ? licenseDef : {} }
        } else {
          return s
        }
      }),
    [data]
  )

  const {
    data: dataV2,
    refetch: refetchV2,
    isLoading: isLoadingV2,
    error: errorV2
  } = useQuery({
    queryKey: ['licenseDefsV2'],
    queryFn: async () => await LicenseV2DefinitionsApi.getLicenseDefinitions(instance, accounts)
  })

  useEffect(
    () =>
      setLicenseDefV2ModalState(s => {
        if (s.licenseDef && s.licenseDef.itemNumber && dataV2) {
          let licenseDef = dataV2.find(ld => ld.itemNumber === s.licenseDef.itemNumber && ld.state === s.licenseDef.state)
          return { ...s, licenseDef: licenseDef ? licenseDef : {} }
        } else {
          return s
        }
      }),
    [dataV2]
  )

  if (error) {
    return (
      <CenteredDiv>
        <ErrorMessage error={error} />
      </CenteredDiv>
    )
  }

  if (errorV2) {
    return (
      <CenteredDiv>
        <ErrorMessage error={errorV2} />
      </CenteredDiv>
    )
  }

  return (
    <>
      <div style={{ width: '100%', margin: '10px', fontStyle: 'italic' }}>
        <Switch
          id='licenseDefVersionSwitch'
          color='primary'
          checked={useDeprecated}
          onChange={e => setUseDeprecated(e.target.checked)}
        />
        Use License V1 (Deprecated)
      </div>
      {useDeprecated === true ? (
        <>
          <LicenseDefinitionModal
            open={licenseDefModalState.open}
            onClose={() => setLicenseDefModalState({ open: false, licenseDef: {} })}
            licenseDef={licenseDefModalState.licenseDef}
            styles={props.styles}
            refetch={refetch}
          />
          <LicenseDefCreateModal
            open={licenseDefCreateModalOpen}
            onClose={() => setLicenseDefCreateModalOpen(false)}
            styles={props.styles}
            refetch={refetch}
          />
          <DefinitionsPage
            openCreateModal={() => setLicenseDefCreateModalOpen(true)}
            styles={props.styles}
            refetch={refetch}
            variant='license definitions'
            onRowClick={e => setLicenseDefModalState({ open: true, licenseDef: e.row })}
            data={data}
            loading={isLoading}
          />
        </>
      ) : (
        <>
          <LicenseV2DefinitionModal
            open={licenseDefV2ModalState.open}
            onClose={() => setLicenseDefV2ModalState({ open: false, licenseDef: {} })}
            setLicenseDef={setLicenseDefV2ModalState}
            licenseDef={licenseDefV2ModalState.licenseDef}
            styles={props.styles}
            refetch={refetchV2}
          />
          <LicenseV2DefCreateModal
            open={licenseDefV2CreateModalOpen}
            onClose={() => setLicenseDefV2CreateModalOpen(false)}
            styles={props.styles}
            refetch={refetchV2}
          />
          <LicenseDefinitions
            openCreateModal={() => setLicenseDefV2CreateModalOpen(true)}
            styles={props.styles}
            refetch={refetchV2}
            variant='license definitions'
            onRowClick={e => setLicenseDefV2ModalState({ open: true, licenseDef: e.row })}
            data={dataV2}
            loading={isLoadingV2}
          />
        </>
      )}
    </>
  )
}
