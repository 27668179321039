import { TextField } from '@mui/material'
import React from 'react'
import getDeviceName from '../utils/getDeviceName'

export default function CreateDefinitionDetails({ draft }) {
  return (
    <>
      <TextField
        id='createDefDetailsTypeTextField'
        label='Type'
        value={getDeviceName(draft.baseProductId)}
        disabled
        size='small'
        style={{ width: '100%', marginTop: '15px' }}
      />
      <TextField
        id='createDefDetailsItemNumberTextField'
        label='Item Number'
        value={draft.itemNumber}
        disabled
        size='small'
        style={{ width: '100%', marginTop: '15px' }}
      />
      <TextField
        id='createDefDetailsNameTextField'
        label='Product Name'
        value={draft.name}
        disabled
        size='small'
        style={{ width: '100%', marginTop: '15px' }}
      />
      <TextField
        id='createDefDetailsDescriptionTextField'
        label='Description'
        value={draft.description}
        disabled
        size='small'
        style={{ width: '100%', marginTop: '15px' }}
        multiline
        rows={4}
      />
      <TextField
        id='createDefDetailsRegionTextField'
        label='Region'
        value={draft.region}
        disabled
        size='small'
        style={{ width: '100%', marginTop: '15px' }}
      />
      <TextField
        id='createDefDetailsLanguageTextField'
        label='Language'
        value={draft.language}
        disabled
        size='small'
        style={{ width: '100%', marginTop: '15px' }}
      />
    </>
  )
}
