import { useMsal } from '@azure/msal-react'
import { useQuery } from '@tanstack/react-query'
import { Switch, TextField } from '@mui/material'
import { CustomSelect } from '@wavetronix/common-components'
import LaunchPhaseApi from '../../api/LaunchPhaseApi'
import { insertWhiteSpace } from '../../utils/ReleasePhaseMap'
import SelectBaseProduct from '../selects/SelectBaseProduct'

export const DEFAULT_UPDATES_FILTER = {
  baseProductId: '',
  itemNumber: '',
  version: '',
  description: '',
  releasePhase: 'All',
  launchPhase: 'All',
  showHidden: false,
  showOverrides: false
}

const containsSubstring = (original, substring) => {
  if (original && substring) {
    if (!String.prototype.includes) {
      return original.toLowerCase().indexOf(substring.toLowerCase()) !== -1
    } else {
      return original.toLowerCase().includes(substring.toLowerCase())
    }
  } else {
    return false
  }
}

export const filterUpdates = (filter, updates, userId) => {
  return updates
    ? updates.filter(u => {
        u.releasePhase = insertWhiteSpace(u.releasePhase)
        return (
          (filter.baseProductId === '' || u.baseProductId === filter.baseProductId) &&
          u.itemNumber.toLowerCase().includes(filter.itemNumber.toLowerCase()) &&
          u.version.includes(filter.version) &&
          (filter.description === '' || containsSubstring(u.description, filter.description)) &&
          (filter.releasePhase === 'All' || u.releasePhase === filter.releasePhase) &&
          (filter.launchPhase === 'All' || u.launchPhase === filter.launchPhase) &&
          !(filter.showHidden === false && u.isHidden === true) &&
          (!filter.showOverrides || u.userOverrides[userId])
        )
      })
    : []
}

export default function UpdatesFilterDrawer({ filter, setFilter }) {
  const handleChange = (id, value) => setFilter(f => ({ ...f, [id]: value }))

  const onCheckChange = (id, checked) => setFilter(f => ({ ...f, [id]: checked }))

  const { instance, accounts } = useMsal()
  const { data: phasesData } = useQuery({
    queryKey: ['phasesFilterQuery'],
    queryFn: async () => await LaunchPhaseApi.getAllLaunchPhases(instance, accounts)
  })

  return (
    <>
      <SelectBaseProduct
        onChange={e => setFilter(f => ({ ...f, baseProductId: e.target.value }))}
        value={filter.baseProductId}
        label='Type'
        style={{ width: '100%' }}
        variant='outlined'
        size='small'
      />
      <TextField
        id='updatesFilterItemNumberTextField'
        label='Item Number'
        style={{ marginTop: '10px', width: '100%' }}
        onChange={e => handleChange('itemNumber', e.target.value)}
        value={filter.itemNumber}
        variant='outlined'
        size='small'
      />
      <TextField
        id='updatesFilterVersionTextField'
        label='Version Number'
        style={{ marginTop: '10px', width: '100%' }}
        onChange={e => handleChange('version', e.target.value)}
        value={filter.version}
        variant='outlined'
        size='small'
      />
      <TextField
        id='updatesFilterDescriptionTextField'
        label='Description'
        style={{ marginTop: '10px', width: '100%' }}
        onChange={e => handleChange('description', e.target.value)}
        value={filter.description}
        variant='outlined'
        size='small'
      />
      <CustomSelect
        id='updatesFilterReleasePhaseSelect'
        style={{ marginTop: '10px', width: '100%' }}
        label='Release Phase'
        value={filter.releasePhase}
        onChange={e => setFilter(f => ({ ...f, releasePhase: e.target.value }))}
        options={['All', 'Unreleased', 'Engineering', 'Beta Candidate', 'Beta', 'General']}
      />
      <CustomSelect
        id='updatesFilterLaunchPhaseSelect'
        style={{ marginTop: '10px', width: '100%' }}
        label='Launch Phase'
        value={filter.launchPhase}
        onChange={e => {
          setFilter(f => ({ ...f, launchPhase: e.target.value }))
        }}
        options={phasesData ? ['All', ...phasesData] : ['All']}
      />
      <div style={{ marginTop: '10px', width: '100%' }}>
        <Switch
          id='updatesFilterHiddenSwitch'
          color='primary'
          checked={filter.showHidden}
          onChange={e => onCheckChange('showHidden', e.target.checked)}
        />
        Show Hidden
      </div>
      <div style={{ marginTop: '10px', width: '100%' }}>
        <Switch
          id='updatesFilterOverridesSwitch'
          color='primary'
          checked={filter.showOverrides}
          onChange={e => onCheckChange('showOverrides', e.target.checked)}
        />
        Show Overrides
      </div>
    </>
  )
}
