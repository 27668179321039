import axios from 'axios'
import { env } from '../index.js'
import { acquireAccessToken } from '@wavetronix/common-components'

let instance = null

class MasterFeaturesApi {
  getMasterFeatureList = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .get(`${env.urls.updatesURL}/api/v1/masterfeatures`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res.data)
  }

  getBaseProducts = async (msalInstance, accounts) =>
    await this.getMasterFeatureList(msalInstance, accounts).then(masterFeatureList => masterFeatureList.licenseBaseIdList)

  getBaseProductsHash = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .get(`${env.urls.updatesURL}/api/v1/masterfeatures/baseproductid/hash`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res.data)
  }

  updateBaseProductHash = async (msalInstance, accounts, hash) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .post(`${env.urls.updatesURL}/api/v1/masterfeatures/baseproductid/hash`, hash, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res.data)
  }

  createBaseProductHash = async (msalInstance, accounts, hash) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .put(`${env.urls.updatesURL}/api/v1/masterfeatures/baseproductid/hash/create`, hash, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res.data)
  }

  existsBaseProductHash = async (msalInstance, accounts, hashId) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .get(`${env.urls.updatesURL}/api/v1/masterfeatures/baseproductid/hash/exists/${hashId}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res.data)
  }

  deleteBaseProductHash = async (msalInstance, accounts, hashId) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .delete(`${env.urls.updatesURL}/api/v1/masterfeatures/baseproductid/hash/${hashId}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res.data)
  }

  getAvailableFeatures = async (msalInstance, accounts, baseProductId) =>
    await this.getMasterFeatureList(msalInstance, accounts).then(data => {
      let featureDefList = data.featureDefList
      let features = []

      for (let i = 0; i < featureDefList.length; i++) {
        for (let j = 0; j < featureDefList[i].licenseBaseList.length; j++) {
          if (featureDefList[i].licenseBaseList[j].id === baseProductId) {
            features.push({
              ...featureDefList[i],
              maxValue: featureDefList[i].licenseBaseList[j].maxValue
            })
          }
        }
      }

      return features
    })

  getAvailableFeaturesByProduct = async (msalInstance, accounts, products) =>
    await this.getMasterFeatureList(msalInstance, accounts).then(data => {
      let featureDefList = data.featureDefList
      let featureDefMap = {}

      for (let featureDef of featureDefList) {
        if (featureDef.licenseBaseList.filter(base => products.includes(base.id)).length > 0)
          featureDefMap[featureDef.id] = { ...featureDef }
      }

      return featureDefMap
    })
}

const getInstance = () => {
  if (instance == null) {
    instance = new MasterFeaturesApi()
  }
  return instance
}

export default getInstance()
