import { TextField, Box, Grow, Autocomplete, IconButton, Tooltip } from '@mui/material'
import CategoryIcon from '@mui/icons-material/Category'
import { WtxColors } from '@wavetronix/common-components'
import MasterFeaturesApi from '../../api/MasterFeaturesApi'
import { useMsal } from '@azure/msal-react'
import { useQuery } from '@tanstack/react-query'
import getDeviceName from '../../utils/getDeviceName'

export default function SelectBaseProductAutocomplete({ addProductState, addProduct, setAddProductState }) {
  const { instance, accounts } = useMsal()
  const { data } = useQuery({
    queryKey: ['masterFeatureList'],
    queryFn: async () => await MasterFeaturesApi.getMasterFeatureList(instance, accounts)
  })

  return (
    <Box sx={{ display: 'flex', direction: 'row', marginBottom: '10px' }}>
      <Box sx={{ flex: '1 1 auto' }} />
      <Grow in={addProductState}>
        <Autocomplete
          id='baseProductAutocomplete'
          sx={{ maxWidth: '300px', minWidth: '50%', marginRight: '5px' }}
          size='small'
          onChange={(event, value) => {
            if (value) {
              let product = { baseProductId: value.id, features: [], productIdentifierPrefix: null }
              let featuresTemp = []
              for (let feature of data.featureDefList) {
                if (feature.licenseBaseList.map(productBase => productBase.id).includes(value.id)) {
                  let f = {
                    id: feature.id,
                    type: feature.possibleTypeList[0],
                    maxValue: Math.max(...feature.licenseBaseList.map(p => p.maxValue)),
                    value: ''
                  }
                  featuresTemp.push(f)
                }
              }
              product.features = [...featuresTemp]
              addProduct(product)
            }
          }}
          options={data ? data.licenseBaseIdList.map(product => ({ label: `${getDeviceName(product)}`, id: product })) : []}
          isOptionEqualToValue={(option, someValue) => option.id === someValue.id}
          renderInput={params => <TextField {...params} label='Product' />}
        />
      </Grow>
      <Tooltip title='Add Products'>
        <IconButton
          id='addProductAutocompleteButton'
          onClick={() => setAddProductState(s => s === false)}
          sx={{ marginRight: '10px' }}
        >
          <CategoryIcon sx={{ color: addProductState === true ? WtxColors.IQ_BLUE : 'default' }} />
        </IconButton>
      </Tooltip>
    </Box>
  )
}
