import { Switch, TextField } from '@mui/material'
import { CustomSelect } from '@wavetronix/common-components'
import SelectBaseProduct from '../selects/SelectBaseProduct'

export const DEFAULT_DEFINITIONS_FILTER = {
  baseProductId: '',
  itemNumber: '',
  name: '',
  region: 'All',
  language: 'All',
  showHidden: false
}

export const filterDefinitions = (filter, definitions) =>
  definitions
    ? definitions.filter(
        p =>
          (filter.baseProductId === '' || p.baseProductId === filter.baseProductId) &&
          p.itemNumber.toLowerCase().includes(filter.itemNumber.toLowerCase()) &&
          p.name.toLowerCase().includes(filter.name.toLowerCase()) &&
          (filter.region === 'All' || p.region === filter.region) &&
          (filter.language === 'All' || p.language === filter.language) &&
          (filter.showHidden ? p.state === 'Hidden' : p.state !== 'Hidden')
      )
    : []

export default function DefinitionsFilterDrawer({ filter, setFilter }) {
  const handleChange = (id, value) => setFilter(f => ({ ...f, [id]: value }))
  const onCheckChange = (id, checked) => setFilter(f => ({ ...f, [id]: checked }))

  return (
    <>
      <SelectBaseProduct
        onChange={e => setFilter(f => ({ ...f, baseProductId: e.target.value }))}
        value={filter.baseProductId}
        label='Base Product'
        style={{ width: '100%' }}
      />
      <TextField
        id='defFilterItemNumberTextField'
        label='Item Number'
        style={{ marginTop: '10px', width: '100%' }}
        onChange={e => handleChange('itemNumber', e.target.value)}
        value={filter.itemNumber}
        variant='outlined'
        size='small'
      />
      <TextField
        id='defFilterNameTextField'
        label='Name'
        style={{ marginTop: '10px', width: '100%' }}
        onChange={e => handleChange('name', e.target.value)}
        value={filter.name}
        variant='outlined'
        size='small'
      />
      <CustomSelect
        id='defFilterRegionSelect'
        style={{ marginTop: '10px', width: '100%' }}
        label='Region'
        value={filter.region}
        onChange={e => setFilter(f => ({ ...f, region: e.target.value }))}
        options={['All', 'USA', 'UK', 'France']}
      />
      <CustomSelect
        id='defFilterLanguageSelect'
        style={{ marginTop: '10px', width: '100%' }}
        label='Language'
        value={filter.language}
        onChange={e => setFilter(f => ({ ...f, language: e.target.value }))}
        options={['All', 'en-US', 'fr']}
      />
      <div style={{ marginTop: '10px', width: '100%' }}>
        <Switch
          id='defFilterHiddenSwitch'
          color='primary'
          checked={filter.showHidden}
          onChange={e => onCheckChange('showHidden', e.target.checked)}
        />
        Show Hidden
      </div>
    </>
  )
}
