import { useMsal } from '@azure/msal-react'
import { CustomDataGrid, PersistantFilterDiv, CenteredDiv, ErrorMessage, RegularButton } from '@wavetronix/common-components'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import MasterFeaturesApi from '../../api/MasterFeaturesApi'
import BaseProductHashModal from '../modals/BaseProductHashModal'
import CreateBaseProductHashModal from '../modals/CreateBaseProductHashModal'
import ProductFilterDrawer, { DEFAULT_PRODUCTS_FILTER, filterProducts } from '../drawers/ProductFilterDrawer'
import { Box, Stack } from '@mui/material'
import { AddOutlined } from '@mui/icons-material'

const DEFAULT_PRODUCT = {
  id: '',
  productHashName: '',
  isSoftware: false,
  order: 1,
  image: 'default.png',
  fromCompany: ''
}

export default function BaseProductHashPage() {
  const { instance, accounts } = useMsal()
  const [editProductOpen, setEditProductOpen] = useState(false)
  const [addProductOpen, setAddProductOpen] = useState(false)

  const [selectedProduct, setSelectedProduct] = useState(DEFAULT_PRODUCT)
  const [filter, setFilter] = useState(DEFAULT_PRODUCTS_FILTER)

  const { data, isLoading, refetch, error } = useQuery({
    queryKey: ['productHash'],
    queryFn: async () => await MasterFeaturesApi.getBaseProductsHash(instance, accounts)
  })

  const columns = [
    {
      field: 'id',
      headerName: 'Base Product Id',
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'productHashName',
      headerName: 'Hash Name',
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'isSoftware',
      headerName: 'Type',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: u => (u.value === true ? 'Sofware' : 'Firmware')
    },
    {
      field: 'order',
      headerName: 'Order',
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'fromCompany',
      headerName: 'Company',
      flex: 1,
      disableColumnMenu: true
    }
  ]

  if (error) {
    return (
      <CenteredDiv>
        <ErrorMessage error={error} />
      </CenteredDiv>
    )
  }

  return (
    <>
      <BaseProductHashModal
        open={editProductOpen}
        onClose={() => setEditProductOpen(false)}
        product={selectedProduct}
        productRefetch={refetch}
      />
      <CreateBaseProductHashModal
        open={addProductOpen}
        onClose={() => setAddProductOpen(false)}
        product={DEFAULT_PRODUCT}
        productRefetch={refetch}
      />

      <PersistantFilterDiv
        drawer={<ProductFilterDrawer filter={filter} setFilter={setFilter} />}
        resetFilter={() => setFilter(DEFAULT_PRODUCTS_FILTER)}
        page={
          <Stack sx={{ marginTop: '10px', marginLeft: '10px' }}>
            <Box sx={{ marginBottom: '10px', display: 'flex' }}>
              <div>
                To add a product to this list, please edit the Master Features file in schemas or to add a product for My Software
                admins can also manually add a product.
              </div>
              <Box sx={{ flex: '1 1 auto' }} />
              <RegularButton
                id='baseProductIdAddButton'
                startIcon={<AddOutlined />}
                style={{ float: 'right' }}
                onClick={() => setAddProductOpen(true)}
              >
                Add Product
              </RegularButton>
            </Box>
            <CustomDataGrid
              cursor='pointer'
              rows={filterProducts(filter, data).sort((a, b) => (a.order > b.order ? 1 : -1))}
              columns={columns}
              isLoading={isLoading}
              onRowClick={e => {
                setSelectedProduct(e.row)
                setEditProductOpen(true)
              }}
            />
          </Stack>
        }
      />
    </>
  )
}
