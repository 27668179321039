import axios from 'axios'
import { env } from '../index.js'
import { acquireAccessToken } from '@wavetronix/common-components'
import { Buffer } from 'buffer'

let instance = null

class NexusAssetsApi {
  getAssets = async (msalInstance, accounts) => {
    let credentials = Buffer.from(
      `${env.basicAuthentication.gatekeeperUserName}:${env.basicAuthentication.gatekeeperPassword}`
    ).toString('base64')
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .get(`${env.urls.gatekeeperURL}/api/nexusassets/all/products`, {
        headers: { Authorization: `Basic ${credentials}`, CustomGatekeeperToken: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }
}

const getInstance = () => {
  if (instance == null) {
    instance = new NexusAssetsApi()
  }
  return instance
}

export default getInstance()
