import { useMsal } from '@azure/msal-react'
import { Switch } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { CustomDataGrid } from '@wavetronix/common-components'
import { useQuery } from '@tanstack/react-query'
import MasterFeaturesApi from '../../api/MasterFeaturesApi'
import UpdatesApi from '../../api/UpdatesApi'
import getDeviceName from '../../utils/getDeviceName'

export default function SelectMultiBaseProducts({ style, required, onChange, value, showAll, disabled }) {
  const { instance, accounts } = useMsal()
  const { enqueueSnackbar } = useSnackbar()
  const [pageSize, setPageSize] = useState(50)
  const { data, isLoading } = useQuery({
    queryKey: ['baseProducts'],
    queryFn: async () =>
      showAll
        ? await MasterFeaturesApi.getBaseProducts(instance, accounts)
        : await UpdatesApi.getUpdates(instance, accounts, false)
            .then(updates => Array.from(new Set(updates.map(u => u.baseProductId))))
            .catch(() => enqueueSnackbar('Error while retrieving base product ids.'))
  })

  const onCheckChange = (e, id) => {
    if (e.target.checked) {
      onChange([...value, id])
    } else {
      onChange(value.filter(v => v !== id))
    }
  }

  const columns = [
    {
      field: 'id',
      headerName: 'Add',
      flex: 0.5,
      disableColumnMenu: true,
      renderCell: row => {
        return (
          <Switch
            id={`${row.id}AddSwitch`}
            checked={value && value.includes(row.id) ? true : false}
            onChange={e => onCheckChange(e, row.id)}
            disabled={disabled}
            color='primary'
          />
        )
      }
    },
    {
      field: 'baseProductId',
      headerName: 'Type',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: row => getDeviceName(row.value)
    }
  ]

  return (
    <>
      <div style={{ width: '100%', margin: '15px' }}>
        <b>Products</b>
      </div>
      <CustomDataGrid
        rows={data ? data.map(d => ({ id: d, baseProductId: d })) : []}
        isLoading={isLoading}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={p => setPageSize(p.pageSize)}
        rowsPerPageOptions={[10, 20, 50, 100]}
        disableSelectionOnClick
        disableExtendRowFullWidth={true}
      />
    </>
  )
}
