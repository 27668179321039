import { Grid } from '@mui/material'
import React from 'react'
import { SlideUpDialog } from '@wavetronix/common-components'

// This component consolidates the common layout used by both the EditUsersModal and the
// EditUpdates modal, and is utilized by both.
export default function EditModal(props) {
  return (
    <SlideUpDialog
      id={props.id}
      open={props.open}
      onClose={props.onClose}
      fullScreen
      title={<h3 style={{ margin: '0px' }}>{props.title}</h3>}
    >
      <Grid container>
        <Grid item md={3}>
          {props.leftContent}
        </Grid>
        <Grid item md={9}>
          {props.rightContent}
        </Grid>
      </Grid>
    </SlideUpDialog>
  )
}
