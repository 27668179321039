import { TextField } from '@mui/material'
import { CustomSelect } from '@wavetronix/common-components'

export const COMPANIES = ['wavetronix', 'islandradar', 'fathom']

export const DEFAULT_PRODUCTS_FILTER = {
  productHashName: '',
  type: 'All Types'
}

const nullCheck = s => (s ? s : '')

export const filterProducts = (filter, products) =>
  products
    ? products.filter(
        p =>
          nullCheck(p.productHashName).toLowerCase().includes(filter.productHashName.toLowerCase()) &&
          (filter.type === 'All Types' ||
            (filter.type === 'Software' && p.isSoftware === true) ||
            (filter.type === 'Firmware' && p.isSoftware === false))
      )
    : []

export default function ProductFilterDrawer({ filter, setFilter }) {
  const handleChange = (id, value) => setFilter(f => ({ ...f, [id]: value }))

  return (
    <>
      <TextField
        id='productFilterHashNameTextField'
        label='Product Hash Name'
        onChange={e => handleChange('productHashName', e.target.value)}
        value={filter.productHashName}
        variant='outlined'
        size='small'
        style={{ width: '100%' }}
      />
      <CustomSelect
        id='productFilterTypeSelect'
        style={{ marginTop: '10px', width: '100%' }}
        label='Product Type'
        value={filter.type}
        onChange={e => handleChange('type', e.target.value)}
        options={['All Types', 'Software', 'Firmware']}
      />
    </>
  )
}
