import { IconButton, TextField } from '@mui/material'
import { AddOutlined, DeleteOutlined } from '@mui/icons-material'
import { CenteredDiv, RegularButton, WtxColors } from '@wavetronix/common-components'
import React, { useEffect } from 'react'
import onUnload from '../utils/onUnload'
import SelectBaseProduct from './selects/SelectBaseProduct'
import SelectLanguage from './selects/SelectLanguage'
import SelectRegion from './selects/SelectRegion'

export default function CreateDefinitionFirstStep({ form, setForm, disabled, variant }) {
  const handleFormChange = (id, value) => setForm(f => ({ ...f, [id]: value }))

  useEffect(() => {
    for (let key in form) {
      if (
        (key !== 'applicableLicenseNumbers' && form[key]) ||
        (key === 'applicableLicenseNumbers' && (form[key].length > 1 || form[key][0]))
      ) {
        window.onbeforeunload = onUnload
        return () => (window.onbeforeunload = undefined)
      }
    }
    window.onbeforeunload = undefined
  }, [form])

  return (
    <>
      <CenteredDiv>
        <SelectBaseProduct
          onChange={e => handleFormChange('baseProductId', e.target.value)}
          value={form.baseProductId}
          label='Base Product'
          style={{ width: '75%', marginTop: '20px' }}
          required
          disabled={disabled}
          showAll
        />
      </CenteredDiv>
      <br />
      <CenteredDiv>
        <TextField
          id='createDefFirstStepNameTextField'
          style={{ width: '75%' }}
          size='small'
          label='Product Name'
          onChange={e => handleFormChange('name', e.target.value)}
          value={form.name}
          required
          disabled={disabled}
        />
      </CenteredDiv>
      <br />
      <CenteredDiv>
        <TextField
          id='createDefFirstStepItemNumberTextField'
          style={{ width: '75%' }}
          size='small'
          label='Item Number'
          value={form.itemNumber}
          onChange={e => handleFormChange('itemNumber', e.target.value)}
          required
          disabled={disabled}
        />
      </CenteredDiv>
      <br />
      <CenteredDiv>
        <SelectRegion
          id='createDefFirstStepRegionSelect'
          onChange={e => setForm(f => ({ ...f, region: e.target.value }))}
          style={{ width: '75%' }}
          value={form.region}
          required
          disabled={disabled}
        />
      </CenteredDiv>
      <br />
      <CenteredDiv>
        <SelectLanguage
          id='createDefFirstStepLanguageSelect'
          onChange={e => setForm(f => ({ ...f, language: e.target.value }))}
          style={{ width: '75%' }}
          value={form.language}
          required
          disabled={disabled}
        />
      </CenteredDiv>
      {variant === 'license definitions' ? (
        <>
          <CenteredDiv>
            <div style={{ width: '75%', margin: '15px' }}>
              <b>Applicable Item Numbers</b>
            </div>
          </CenteredDiv>
          {form.applicableItemNumbers.map((n, i) => (
            <CenteredDiv key={`applicableItemNumber-${i}`}>
              <TextField
                id={`${n}ApplicableItemNumberTextField`}
                size='small'
                onChange={e => {
                  let applicableItemNumbers = form.applicableItemNumbers
                  applicableItemNumbers[i] = e.target.value
                  setForm(f => ({ ...f, applicableItemNumbers: applicableItemNumbers }))
                }}
                style={{ width: '70%' }}
                value={n}
                required
                disabled={disabled}
                label='Applicable Item Number'
              />
              <IconButton
                id={`${n}ApplicableItemNumberButton`}
                style={{ float: 'right' }}
                onClick={() => {
                  if (form.applicableItemNumbers.length > 1) {
                    let applicableItemNumbers = form.applicableItemNumbers
                    applicableItemNumbers.splice(i, 1)
                    setForm(f => ({ ...f, applicableItemNumbers: applicableItemNumbers }))
                  }
                }}
                disabled={form.applicableItemNumbers.length < 2}
                size='large'
              >
                <DeleteOutlined
                  style={{ color: form.applicableItemNumbers.length > 1 ? WtxColors.INNOVATION_RED : WtxColors.CONCRETE }}
                />
              </IconButton>
            </CenteredDiv>
          ))}
          <br />
          <CenteredDiv>
            <RegularButton
              id='createDefFirstStepAddItemNumberButton'
              startIcon={<AddOutlined />}
              onClick={() => setForm(f => ({ ...f, applicableItemNumbers: [...form.applicableItemNumbers, ''] }))}
            >
              Add Applicable Item Number
            </RegularButton>
          </CenteredDiv>
        </>
      ) : null}
      <CenteredDiv>
        <TextField
          id='createDefFirstStepDescriptionTextField'
          style={{ width: '75%', marginTop: '20px' }}
          label='Description'
          onChange={e => handleFormChange('description', e.target.value)}
          value={form.description}
          required
          multiline
          rows={4}
          variant='outlined'
          disabled={disabled}
        />
      </CenteredDiv>
    </>
  )
}
