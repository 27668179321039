import { useMsal } from '@azure/msal-react'
import { InputLabel, MenuItem, Select, Card, FormControl } from '@mui/material'
import { CenteredDiv, SnackbarVariants, PrimaryButton } from '@wavetronix/common-components'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import UsersApi from '../../api/UsersApi'
import onUnload from '../../utils/onUnload'
import EditModal from './EditModal'
import UsersManagerTable from './ModalTables/UsersManagerTable'
import { env } from '../../index.js'

export default function EditUsersModal(props) {
  const [buttonsDisabled, setButtonsDisabled] = useState(false)
  const [accessLevel, setAccessLevel] = useState('')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { instance, accounts } = useMsal()
  async function updateUsers(users, accessLevel) {
    await Promise.all(
      users.map(u => {
        if (u.updatesAccessLevel[env.basicAuthentication.fromCompany] !== accessLevel) {
          return UsersApi.editUser(instance, accounts, u.id, accessLevel)
        } else {
          return null
        }
      })
    )
  }

  useEffect(() => {
    if (props.users && props.users.length > 0 && accessLevel) {
      window.onbeforeunload = onUnload
      return () => (window.onbeforeunload = undefined)
    }
  }, [props.users, accessLevel])

  const onClose = () => {
    if (buttonsDisabled === false) {
      setAccessLevel('')
      props.onClose()
    }
  }

  return (
    <EditModal
      id='editUsersModal'
      onClose={onClose}
      buttonsDisabled={buttonsDisabled}
      open={props.open}
      title={'Edit Users'}
      rightContent={<UsersManagerTable users={props.users ? props.users : []} />}
      leftContent={
        <Card sx={{ margin: '10px' }}>
          <div style={{ width: '100%', padding: '15px' }}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel id='editUsersAccessPhaseLabel' style={{ marginTop: '0px' }}>
                Access Phase
              </InputLabel>
              <Select
                labelId='editUsersAccessPhaseLabel'
                id='editUsersAccessPhaseSelect'
                label={'Access Phase'}
                value={accessLevel}
                onChange={e => setAccessLevel(e.target.value)}
                size='small'
                style={{ width: '100%', marginTop: '4px' }}
              >
                {['General', 'FullRelease', 'Unreleased'].map(level => (
                  <MenuItem id={level} value={level} key={level}>
                    {level === 'FullRelease' ? 'QA Release' : level}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <CenteredDiv>
            <PrimaryButton
              id='editUsersSaveButton'
              style={{ marginBottom: '15px' }}
              disabled={buttonsDisabled || !accessLevel}
              onClick={async () => {
                setButtonsDisabled(true)
                let key = enqueueSnackbar('Updating users...', SnackbarVariants.LOADING)
                await updateUsers(props.users, accessLevel)
                  .then(async () => {
                    await props.refresh()
                    closeSnackbar(key)
                    enqueueSnackbar('Successfully updated users.', SnackbarVariants.SUCCESS)
                  })
                  .catch(() => {
                    closeSnackbar(key)
                    enqueueSnackbar('An error occurred', SnackbarVariants.ERROR)
                  })
                  .finally(() => {
                    props.onClose()
                    setAccessLevel('')
                    setButtonsDisabled(false)
                  })
              }}
            >
              Save
            </PrimaryButton>
          </CenteredDiv>
        </Card>
      }
    />
  )
}
