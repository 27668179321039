import axios from 'axios'
import { env } from '../index.js'
import { acquireAccessToken } from '@wavetronix/common-components'

let instance = null

class ProductDefinitionsApi {
  getProductDefinitions = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return await axios
      .get(`${env.urls.updatesURL}/api/v1/productdefinitions/?states=Hidden,Draft,Published`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  getOne = async (msalInstance, accounts, itemNumber) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .get(`${env.urls.updatesURL}/api/v1/productdefinitions?itemNumber=${itemNumber}&states=Draft,Published,Archived,Hidden`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  createProduct = async (msalInstance, accounts, product, publishBool) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .post(`${env.urls.updatesURL}/api/v1/productdefinitions`, product, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(async response => (publishBool ? await this.publish(msalInstance, accounts, product.itemNumber) : response))
  }

  updateDraft = async (msalInstance, accounts, draft) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .put(`${env.urls.updatesURL}/api/v1/productdefinitions`, draft, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res)
  }

  publish = async (msalInstance, accounts, itemNumber) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .post(`${env.urls.updatesURL}/api/v1/productdefinitions/${itemNumber}/state/Published`, null, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 10000
      })
      .then(res => res)
  }

  hideUnhideDefinition = async (msalInstance, accounts, itemNumber, hide) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .post(`${env.urls.updatesURL}/api/v1/productdefinitions/${itemNumber}/state/${hide ? 'Hidden' : 'Published'}`, null, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res)
  }

  deleteDraft = async (msalInstance, accounts, itemNumber) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .delete(`${env.urls.updatesURL}/api/v1/productdefinitions/draft/${itemNumber}`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res)
  }

  archive = async (msalInstance, accounts, itemNumber) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .post(`${env.urls.updatesURL}/api/v1/productdefinitions/${itemNumber}/state/Archived`, null, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res)
  }

  getHistory = async (msalInstance, accounts, itemNumber) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .get(`${env.urls.updatesURL}/api/v1/productdefinitions/history/${itemNumber}`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }
}

const getInstance = () => {
  if (instance == null) {
    instance = new ProductDefinitionsApi()
  }
  return instance
}

export default getInstance()
