import { CenteredDiv, ErrorMessage } from '@wavetronix/common-components'
import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import ProductDefinitionsApi from '../../api/ProductDefinitionsApi'
import ProductDefCreateModal from '../modals/ProductDefCreateModal'
import ProductDefinitionModal from '../modals/ProductDefinitionModal'
import DefinitionsPage from './DefinitionsPage'
import { useMsal } from '@azure/msal-react'

export default function ProductDefinitionsPage(props) {
  const { instance, accounts } = useMsal()
  const { data, refetch, isLoading, error } = useQuery({
    queryKey: ['productdefs'],
    queryFn: async () => await ProductDefinitionsApi.getProductDefinitions(instance, accounts)
  })
  const [productDefModalState, setProductDefModalState] = useState({
    open: false,
    productDef: {}
  })

  useEffect(
    () =>
      setProductDefModalState(s => {
        if (s.productDef && s.productDef.itemNumber && data) {
          let productDef = data.find(p => p.itemNumber === s.productDef.itemNumber && p.state === s.productDef.state)
          return { ...s, productDef: productDef ? productDef : {} }
        } else {
          return s
        }
      }),
    [data]
  )

  const [productDefCreateModalOpen, setProductDefCreateModalOpen] = useState(false)

  if (error) {
    return (
      <CenteredDiv>
        <ErrorMessage error={error} />
      </CenteredDiv>
    )
  }

  return (
    <>
      <ProductDefinitionModal
        open={productDefModalState.open}
        onClose={() => setProductDefModalState({ open: false, productDef: {} })}
        productDef={productDefModalState.productDef}
        styles={props.styles}
        refetch={refetch}
        variant='product definitions'
      />
      <ProductDefCreateModal
        open={productDefCreateModalOpen}
        onClose={() => setProductDefCreateModalOpen(false)}
        styles={props.styles}
        refetch={refetch}
      />
      <DefinitionsPage
        openCreateModal={() => setProductDefCreateModalOpen(true)}
        styles={props.styles}
        refetch={refetch}
        variant='product definitions'
        onRowClick={e => setProductDefModalState({ open: true, productDef: e.row })}
        data={data}
        loading={isLoading}
      />
    </>
  )
}
