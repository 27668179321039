import { useMsal } from '@azure/msal-react'
import { DialogContent, Grid, CircularProgress } from '@mui/material'
import {
  CenteredDiv,
  ErrorMessage,
  PrimaryButton,
  RegularButton,
  SnackbarVariants,
  SlideUpDialog
} from '@wavetronix/common-components'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import MasterFeaturesApi from '../../api/MasterFeaturesApi'
import LicenseV2DefinitionsApi from '../../api/LicenseV2DefinitionsApi'
import EditLicenseDefDetails, { DEFAULT_FORM } from '../EditLicenseDefDetails'
import LicenseV2DefFeaturesForm from '../LicenseV2DefFeaturesForm'
import ConfirmPublishModal from './ConfirmPublishModal'
import SelectBaseProductAutocomplete from '../selects/SelectBaseProductAutocomplete'

const DEFAULT_PUBLISH_MODAL_STATE = {
  open: false,
  draft: undefined,
  onPublish: () => {}
}

function arraysAreEqual(a, b) {
  if (a === b) return true
  if (a == null || b == null) return false
  if (a.length !== b.length) return false
  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false
  }
  return true
}

function mapValuesAreEqual(newMap, oldMap) {
  let newFeatures = getMapFeatures(newMap)
  let oldFeatures = getMapFeatures(oldMap)

  if (
    arraysAreEqual(
      newFeatures.map(f => f.value),
      oldFeatures.map(f => f.value)
    ) &&
    arraysAreEqual(
      newFeatures.map(f => f.type),
      oldFeatures.map(f => f.type)
    )
  )
    return true
  else return false
}

function getMapFeatures(aMap) {
  let newFeatures = []

  for (let val of Object.values(aMap)) {
    for (let val2 of Object.values(val)) {
      newFeatures.push(val2)
    }
  }

  return newFeatures
}

export default function LicenseV2DefinitionModal({ licenseDef, setLicenseDef, onClose, open, refetch, styles }) {
  const { instance, accounts } = useMsal()
  const [buttonsDisabled, setButtonsDisabled] = useState(false)
  const [saveButtonsDisabled, setSaveButtonsDisabled] = useState(true)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [productMap, setProductMap] = useState({})
  const [oldProductMap, setOldProductMap] = useState({})
  const [form, setForm] = useState(DEFAULT_FORM)
  const [id, setId] = useState('')
  const [publishModalState, setPublishModalState] = useState(DEFAULT_PUBLISH_MODAL_STATE)
  const [addProductState, setAddProductState] = useState(false)

  const {
    data,
    isLoading,
    refetch: licenseRefetch,
    error
  } = useQuery({
    queryKey: ['LicenseDefModalQuery', licenseDef],
    queryFn: async () => {
      if (licenseDef && licenseDef.products) {
        return await MasterFeaturesApi.getAvailableFeaturesByProduct(
          instance,
          accounts,
          licenseDef.products.map(p => p.baseProductId)
        )
      } else {
        return []
      }
    }
  })

  useEffect(() => {
    licenseRefetch()
  }, [licenseDef, licenseRefetch])

  useEffect(() => {
    if (data && licenseDef && licenseDef.products) {
      let pfMap = {}

      //base fill from master feature list
      for (let product of licenseDef.products.map(p => p.baseProductId)) {
        pfMap[product] = {}
        for (let feature of Object.values(data)) {
          if (feature.licenseBaseList.map(b => b.id).includes(product)) {
            pfMap[product][feature.id] = {
              ...feature,
              value: '',
              maxValue: feature.licenseBaseList.filter(p => p.id === product)[0].maxValue,
              type: feature.possibleTypeList[0]
            }
          }
        }
      }

      //fill value from licenseDef
      for (let product of licenseDef.products) {
        for (let feature of product.features) {
          pfMap[product.baseProductId][feature.id] = {
            ...pfMap[product.baseProductId][feature.id],
            value: feature.value,
            type: feature.type
          }
        }
      }

      setProductMap(pfMap)
      if (Object.keys(oldProductMap).length === 0) {
        setOldProductMap(pfMap)
      }
    }
  }, [data, licenseDef, oldProductMap])

  // This makes it so the form and feature map only changes if the user is looking at a different update
  useEffect(() => {
    if (licenseDef.id !== id) {
      setForm({ ...licenseDef })
      setId(licenseDef.id)
    }
  }, [licenseDef, id])

  useEffect(() => {
    if (licenseDef && form) {
      if (
        licenseDef.name !== form.name ||
        licenseDef.region !== form.region ||
        licenseDef.language !== form.language ||
        licenseDef.description !== form.description ||
        arraysAreEqual(licenseDef.applicableItemNumbers, form.applicableItemNumbers) === false ||
        mapValuesAreEqual(productMap, oldProductMap) === false
      ) {
        setSaveButtonsDisabled(false)
      } else {
        setSaveButtonsDisabled(true)
      }
    }
  }, [licenseDef, form, productMap, oldProductMap])

  function buildDraft() {
    let draftProducts = []

    for (let p of licenseDef.products.map(p => p.baseProductId)) {
      draftProducts.push({
        baseProductId: p,
        features: Object.values(productMap[p])
          .filter(f => f.value !== '')
          .map(f => ({ id: f.id, type: f.type, value: f.value, maxValue: f.maxValue }))
      })
    }
    let draft = { ...form, products: draftProducts }
    return draft
  }

  function closeModal() {
    if (!buttonsDisabled) {
      onClose()
      setForm(DEFAULT_FORM)
      setId('')
    }
  }

  function addProduct(product) {
    if (!licenseDef.products.map(p => p.baseProductId).includes(product.baseProductId)) {
      setLicenseDef(f => ({ ...f, licenseDef: { ...f.licenseDef, products: [...f.licenseDef.products, product] } }))
    } else {
      enqueueSnackbar('Product already included in license definition.', SnackbarVariants.ERROR)
    }
  }

  function removeProduct(product) {
    setLicenseDef(f => {
      return {
        ...f,
        licenseDef: { ...f.licenseDef, products: f.licenseDef.products.filter(prod => prod.baseProductId !== product) }
      }
    })
  }

  async function save(draft) {
    setButtonsDisabled(true)
    let key = enqueueSnackbar('Updating...', SnackbarVariants.LOADING)
    await LicenseV2DefinitionsApi.updateLicense(instance, accounts, draft)
      .then(async () => {
        await refetch()
        closeSnackbar(key)
        enqueueSnackbar('Successfully updated license.', SnackbarVariants.SUCCESS)
        onClose()
        setId('')
        setButtonsDisabled(false)
      })
      .catch(() => {
        closeSnackbar(key)
        enqueueSnackbar('Failed to update license', SnackbarVariants.ERROR)
        setButtonsDisabled(false)
      })
  }

  if (error) {
    return (
      <CenteredDiv>
        <ErrorMessage error={error} />
      </CenteredDiv>
    )
  }

  return (
    <>
      <ConfirmPublishModal
        open={publishModalState.open}
        onClose={() => setPublishModalState(DEFAULT_PUBLISH_MODAL_STATE)}
        styles={styles}
        draft={publishModalState.draft}
        onPublish={publishModalState.onPublish}
        buttonsDisabled={buttonsDisabled}
      />
      <SlideUpDialog
        id='licenseV2DefModal'
        open={open}
        onClose={closeModal}
        fullScreen
        title={<h3 style={{ margin: '0px' }}>Edit License Definition</h3>}
        actions={
          <div style={{ display: 'flex' }}>
            {licenseDef.state !== 'Hidden' ? (
              <PrimaryButton
                id='licenseV2DefUpdateButton'
                style={{ marginRight: '10px' }}
                disabled={saveButtonsDisabled}
                onClick={() => save(buildDraft())}
              >
                Update
              </PrimaryButton>
            ) : null}
            <RegularButton id='licenseV2DefCancelButton' style={{ marginRight: '10px' }} onClick={closeModal}>
              Cancel
            </RegularButton>
          </div>
        }
      >
        <DialogContent>
          <div>
            <Grid container spacing={2}>
              <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                <EditLicenseDefDetails
                  form={form}
                  setForm={setForm}
                  definition={licenseDef}
                  history={licenseDef.history ? licenseDef.history : []}
                  refetch={refetch}
                  onClose={closeModal}
                />
              </Grid>
              <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                <CenteredDiv>
                  <h2>Products</h2>
                </CenteredDiv>
                <SelectBaseProductAutocomplete
                  addProductState={addProductState}
                  addProduct={addProduct}
                  setAddProductState={setAddProductState}
                />
                {isLoading ? (
                  <CenteredDiv>
                    <CircularProgress />
                  </CenteredDiv>
                ) : Object.keys(productMap).length > 0 ? (
                  <LicenseV2DefFeaturesForm
                    disabled={buttonsDisabled}
                    products={licenseDef.products ? licenseDef.products.map(p => p.baseProductId) : []}
                    showTooltip={true}
                    setFeatureMap={setProductMap}
                    featureMap={productMap}
                    removeProduct={removeProduct}
                    editOnly={true}
                  />
                ) : (
                  <CenteredDiv>No features selected.</CenteredDiv>
                )}
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </SlideUpDialog>
    </>
  )
}
