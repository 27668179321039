import { IconButton, TextField, Grid } from '@mui/material'
import { AddOutlined, DeleteOutlined } from '@mui/icons-material'
import { CenteredDiv, RegularButton, WtxColors } from '@wavetronix/common-components'
import React, { useEffect } from 'react'
import onUnload from '../utils/onUnload'
import SelectMultiBaseProducts from './selects/SelectMultiBaseProducts'
import SelectLanguage from './selects/SelectLanguage'
import SelectRegion from './selects/SelectRegion'

export default function CreateDefinitionFirstStep({ form, setForm, products, setProducts, disabled }) {
  const handleFormChange = (id, value) => setForm(f => ({ ...f, [id]: value }))

  useEffect(() => {
    for (let key in form) {
      if (
        (key !== 'applicableLicenseNumbers' && form[key]) ||
        (key === 'applicableLicenseNumbers' && (form[key].length > 1 || form[key][0]))
      ) {
        window.onbeforeunload = onUnload
        return () => (window.onbeforeunload = undefined)
      }
    }
    window.onbeforeunload = undefined
  }, [form])

  return (
    <Grid container spacing={2}>
      <Grid item md={6} sm={12}>
        <TextField
          id='createLicenseFirstStepNameTextField'
          style={{ width: '100%', marginTop: '15px' }}
          size='small'
          label='Product Name'
          onChange={e => handleFormChange('name', e.target.value)}
          value={form.name}
          required
          disabled={disabled}
        />
        <TextField
          id='createLicenseFirstStepItemNumberTextField'
          style={{ width: '100%', marginTop: '15px' }}
          size='small'
          label='Item Number'
          value={form.id}
          onChange={e => handleFormChange('id', e.target.value)}
          required
          disabled={disabled}
        />
        <SelectRegion
          id='createLicenseFirstStepRegionSelect'
          onChange={e => setForm(f => ({ ...f, region: e.target.value }))}
          style={{ width: '100%', marginTop: '15px' }}
          value={form.region}
          required
          disabled={disabled}
        />
        <SelectLanguage
          id='createLicenseFirstStepLanguageSelect'
          onChange={e => setForm(f => ({ ...f, language: e.target.value }))}
          style={{ width: '100%', marginTop: '15px' }}
          value={form.language}
          required
          disabled={disabled}
        />
        <>
          <div style={{ width: '100%', margin: '15px' }}>
            <b>Applicable Item Numbers</b>
          </div>
          {form.applicableItemNumbers.map((n, i) => (
            <CenteredDiv key={`applicableItemNumber-${i}`}>
              <TextField
                id={`${n}ApplicableItemNumberTextField`}
                size='small'
                onChange={e => {
                  let applicableItemNumbers = form.applicableItemNumbers
                  applicableItemNumbers[i] = e.target.value
                  setForm(f => ({ ...f, applicableItemNumbers: applicableItemNumbers }))
                }}
                style={{ width: '85%' }}
                value={n}
                required
                disabled={disabled}
                label='Applicable Item Number'
              />
              <IconButton
                id={`${n}ApplicableItemNumberDeleteButton`}
                style={{ float: 'right' }}
                onClick={() => {
                  if (form.applicableItemNumbers.length > 1) {
                    let applicableItemNumbers = form.applicableItemNumbers
                    applicableItemNumbers.splice(i, 1)
                    setForm(f => ({ ...f, applicableItemNumbers: applicableItemNumbers }))
                  }
                }}
                disabled={form.applicableItemNumbers.length < 2}
                size='large'
              >
                <DeleteOutlined
                  style={{ color: form.applicableItemNumbers.length > 1 ? WtxColors.INNOVATION_RED : WtxColors.CONCRETE }}
                />
              </IconButton>
            </CenteredDiv>
          ))}
          <CenteredDiv>
            <RegularButton
              id='createLicenseFirstStepAddItemNumberButton'
              style={{ marginTop: '15px' }}
              startIcon={<AddOutlined />}
              onClick={() => setForm(f => ({ ...f, applicableItemNumbers: [...form.applicableItemNumbers, ''] }))}
            >
              Add Applicable Item Number
            </RegularButton>
          </CenteredDiv>
          <TextField
            id='createLicenseFirstStepDescriptionTextField'
            style={{ width: '100%', marginTop: '15px' }}
            label='Description'
            onChange={e => handleFormChange('description', e.target.value)}
            value={form.description}
            required
            multiline
            rows={4}
            variant='outlined'
            disabled={disabled}
          />
        </>
      </Grid>
      <Grid item md={6} sm={12}>
        <SelectMultiBaseProducts
          style={{ width: '100%', marginTop: '20px' }}
          required
          onChange={e => setProducts(e)}
          value={products}
          showAll
          disabled={disabled}
        />
      </Grid>
    </Grid>
  )
}
