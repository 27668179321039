import { Switch, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Tooltip } from '@mui/material'
import { HelpOutline } from '@mui/icons-material'
import { CenteredDiv, SnackbarVariants, CustomSelect } from '@wavetronix/common-components'
import { useSnackbar } from 'notistack'
import React from 'react'

export default function LicenseDefFeaturesForm({ featureMap, setFeatureMap, disabled, features }) {
  const { enqueueSnackbar } = useSnackbar()

  const setFeatureMapValue = (id, value, defaultType) =>
    setFeatureMap(m => ({ ...m, [id]: { value: value, type: m[id] && m[id].type ? m[id].type : defaultType } }))

  const handleFeatureSwitchValueChange = (id, checked, defaultType) =>
    disabled ? null : setFeatureMapValue(id, checked ? 'true' : '', defaultType)

  function handleFeatureInputValueChange(id, value, defaultType) {
    if (value === '') {
      setFeatureMapValue(id, value)
    } else {
      if (!Number.isNaN(Number(value))) {
        let feature = features.find(f => f.id === id)
        if (Number(value) <= feature.maxValue) {
          setFeatureMapValue(id, value, defaultType)
        } else {
          enqueueSnackbar(`Max value for ${feature.name} is ${feature.maxValue}`, SnackbarVariants.ERROR)
        }
      }
    }
  }

  return features.length > 0 ? (
    <TableContainer>
      <Table>
        <TableBody>
          {features.map((f, i) => (
            <TableRow key={i}>
              <TableCell style={{ width: '75%' }}>
                <div>
                  {f.possibleTypeList.includes('Bool') ? (
                    <div>
                      <Switch
                        id={`${f.id}Switch`}
                        // getting rid of the ternery operator here and just leaving the boolean clause
                        // will result in material ui warnings and errors.
                        checked={featureMap[f.id] && featureMap[f.id].value === 'true' ? true : false}
                        color='primary'
                        onChange={e => handleFeatureSwitchValueChange(f.id, e.target.checked, f.possibleTypeList[0])}
                        disabled={disabled}
                      />
                      {f.name}
                      <Tooltip title={f.description}>
                        <HelpOutline style={{ marginLeft: '5px', marginBottom: '-7px' }} />
                      </Tooltip>
                    </div>
                  ) : (
                    <div style={{ width: '100%' }}>
                      <TextField
                        id={`${f.id}TextField`}
                        label={f.name}
                        style={{ width: '60%' }}
                        size='small'
                        value={featureMap[f.id] && featureMap[f.id].value ? featureMap[f.id].value : ''}
                        onChange={e => handleFeatureInputValueChange(f.id, e.target.value, f.possibleTypeList[0])}
                        disabled={disabled}
                      />
                      <Tooltip title={f.description}>
                        <HelpOutline style={{ marginLeft: '5px', marginBottom: '-17px' }} />
                      </Tooltip>
                    </div>
                  )}
                </div>
              </TableCell>
              <TableCell style={{ width: '25%' }}>
                <CustomSelect
                  id={`${f.id}Select`}
                  style={{ width: '100%' }}
                  label='Type'
                  value={
                    f.possibleTypeList.length > 0
                      ? f.possibleTypeList[0]
                      : featureMap[f.id] && featureMap[f.id].type
                      ? featureMap[f.id].type
                      : f.possibleTypeList[0]
                  }
                  options={f.possibleTypeList}
                  onChange={e => setFeatureMap(m => ({ ...m, [f.id]: { ...m[f.id], type: e.target.value } }))}
                  disabled={f.possibleTypeList.length < 2 || disabled}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <CenteredDiv>No available features.</CenteredDiv>
  )
}
