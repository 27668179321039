import { useMsal } from '@azure/msal-react'
import { Switch, Tooltip, Grid, Card, CardContent } from '@mui/material'
import { CenteredDiv, ErrorMessage, SnackbarVariants, SlideUpDialog, PersistantFilterDiv } from '@wavetronix/common-components'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import UpdatesApi from '../../api/UpdatesApi'
import UsersApi from '../../api/UsersApi'
import GroupsApi from '../../api/GroupsApi'
import { ACCESS_LEVEL_MAP } from '../../utils/AccessLevelMap'
import { RELEASE_PHASE_MAP, removeWhiteSpace } from '../../utils/ReleasePhaseMap'
import UpdatesManagerTable from './ModalTables/UpdatesManagerTable'
import UpdatesModalUsersTable from './ModalTables/UpdatesModalUsersTable'
import UsersFilterDrawer, { DEFAULT_USERS_FILTER, filterUsers } from '../drawers/UsersFilterDrawer'
import { env } from '../../index.js'

export default function UpdatesOverridesModal(props) {
  const { instance, accounts } = useMsal()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [buttonsDisabled, setButtonsDisabled] = useState(false)
  const [usersGroupsOverrides, setUsersGroupsOverrides] = useState({})
  const [mostSecretUpdate, setMostSecretUpdate] = useState({})
  const [filter, setFilter] = useState(DEFAULT_USERS_FILTER)

  const { data, isLoading, refetch, error } = useQuery({
    queryKey: ['UpdateOverridesModalQuery'],
    queryFn: async () => await UsersApi.getUsers(instance, accounts)
  })

  const { data: groupsData } = useQuery({
    queryKey: ['groupsAllQuery'],
    queryFn: async () => await GroupsApi.getGroups(instance, accounts)
  })

  useEffect(() => {
    if (props.updates) {
      let update = [...props.updates].sort(
        (a, b) => RELEASE_PHASE_MAP[removeWhiteSpace(b.releasePhase)] - RELEASE_PHASE_MAP[removeWhiteSpace(a.releasePhase)]
      )[0]
      setMostSecretUpdate(update)
    } else {
      setMostSecretUpdate({})
    }
  }, [props.updates])

  useEffect(() => {
    if (groupsData && props.updates.length > 0 && data) {
      var usersMap = {}
      for (let user of data) {
        //default user override to none
        usersMap[user.id] = 'none'
        var userHas = []

        //check if user is on update overrides
        for (let update of props.updates) {
          if (update.userOverrides[user.id]) {
            userHas.push(update.id)
          }
        }

        if (userHas.length === props.updates.length) {
          usersMap[user.id] = 'userOverrideAccessAll'
        } else if (userHas.length > 0 && userHas.length < props.updates.length) {
          usersMap[user.id] = 'userOverrideAccessSome'
        }

        //if user is in a group with override access reset to groupOverrideAccess
        var groupsHas = []
        for (let group of groupsData) {
          for (let update of props.updates) {
            if (
              groupsHas.includes(update.id) === false &&
              user.groups &&
              user.groups.includes(group.id) &&
              group.updates.includes(update.id)
            ) {
              groupsHas.push(update.id)
            }
          }
        }

        if (groupsHas.length === props.updates.length) {
          usersMap[user.id] = 'groupOverrideAccessAll'
        } else if (groupsHas.length > 0 && groupsHas.length < props.updates.length) {
          usersMap[user.id] = 'groupOverrideAccessSome'
        }
      }
      setUsersGroupsOverrides(usersMap)
    }
  }, [groupsData, props.updates, data])

  const onClose = () => (buttonsDisabled ? null : props.onClose())

  async function addOverrides(id) {
    let key = enqueueSnackbar('Adding overrides...', SnackbarVariants.LOADING)
    await Promise.all(props.updates.map(u => (u.userOverrides[id] ? null : UpdatesApi.addOverride(instance, accounts, u.id, id))))
      .then(async () => {
        await Promise.all([props.refetch(), refetch()])
        closeSnackbar(key)
        enqueueSnackbar('Successfully added overrides', SnackbarVariants.SUCCESS)
      })
      .catch(() => {
        closeSnackbar(key)
        enqueueSnackbar('An error occurred', SnackbarVariants.ERROR)
      })
  }

  async function removeOverrides(id) {
    let key = enqueueSnackbar('Removing overrides...', SnackbarVariants.LOADING)
    await Promise.all(
      props.updates.map(u => (u.userOverrides[id] ? UpdatesApi.removeOverride(instance, accounts, u.id, id) : null))
    )
      .then(async () => {
        await Promise.all([props.refetch(), refetch()])
        closeSnackbar(key)
        enqueueSnackbar('Successfully removed overrides', SnackbarVariants.SUCCESS)
      })
      .catch(() => {
        closeSnackbar(key)
        enqueueSnackbar('An error occurred', SnackbarVariants.ERROR)
      })
  }

  async function onSwitchChange(e, id) {
    setButtonsDisabled(true)
    if (e.target.checked) {
      await addOverrides(id)
    } else {
      await removeOverrides(id)
    }
    setButtonsDisabled(false)
  }

  if (error) {
    return (
      <CenteredDiv>
        <ErrorMessage error={error} />
      </CenteredDiv>
    )
  }

  return (
    <SlideUpDialog
      id='updatesOverridesModal'
      fullScreen
      open={props.open}
      onClose={onClose}
      title={<h3 style={{ margin: '0px' }}>Manage Updates</h3>}
    >
      <PersistantFilterDiv
        drawer={<UsersFilterDrawer filter={filter} setFilter={setFilter} />}
        resetFilter={() => setFilter(DEFAULT_USERS_FILTER)}
        page={
          <Grid container>
            <Grid xl={4} lg={4} md={12} sm={12} xs={12} item>
              <Card
                style={{
                  margin: '10px'
                }}
              >
                <CardContent>
                  <UpdatesManagerTable updates={props.updates ? props.updates : []} />
                </CardContent>
              </Card>
            </Grid>
            <Grid xl={8} lg={8} md={12} sm={12} xs={12} item>
              <div style={{ marginTop: '10px' }}>
                <UpdatesModalUsersTable
                  styles={props.styles}
                  modalOpen={props.open}
                  refetch={refetch}
                  buttonsDisabled={buttonsDisabled}
                  setButtonsDisabled={setButtonsDisabled}
                  loading={isLoading}
                  onClose={onClose}
                  users={
                    data && mostSecretUpdate
                      ? filterUsers(
                          filter,
                          data.filter(
                            u =>
                              ACCESS_LEVEL_MAP[u.updatesAccessLevel[env.basicAuthentication.fromCompany]] <
                              RELEASE_PHASE_MAP[removeWhiteSpace(mostSecretUpdate.releasePhase)]
                          )
                        )
                      : []
                  }
                  renderSwitch={id => {
                    var isChecked = false
                    var tooltip = ''
                    if (usersGroupsOverrides[id] === 'groupOverrideAccessAll') {
                      isChecked = true
                      tooltip = 'User has access to all selected updates through groups. Go to group managment to change.'
                    } else if (usersGroupsOverrides[id] === 'groupOverrideAccessSome') {
                      isChecked = true
                      tooltip = 'User has access to some selected updates through groups. Go to group managment to change.'
                    } else if (usersGroupsOverrides[id] === 'userOverrideAccessAll') {
                      isChecked = true
                      tooltip = 'User has access to all selected updates through the Admin Tool.'
                    } else if (usersGroupsOverrides[id] === 'userOverrideAccessSome') {
                      isChecked = true
                      tooltip = 'User has access to some selected updates through the Admin Tool.'
                    }
                    return (
                      <Tooltip title={tooltip}>
                        <span>
                          <Switch
                            id={`${id}UpdatesUsersSwitch`}
                            checked={isChecked}
                            color={usersGroupsOverrides[id] && usersGroupsOverrides[id].includes('All') ? 'primary' : 'secondary'}
                            onChange={e => onSwitchChange(e, id)}
                            disabled={buttonsDisabled || (usersGroupsOverrides[id] && usersGroupsOverrides[id].includes('group'))}
                          />
                        </span>
                      </Tooltip>
                    )
                  }}
                  overridesComparator={(a, b) => {
                    if (usersGroupsOverrides[a] === usersGroupsOverrides[b]) {
                      return 0
                    } else if (
                      (usersGroupsOverrides[b] === 'groupOverrideAccessAll' &&
                        usersGroupsOverrides[a] === 'groupOverrideAccessSome') ||
                      (usersGroupsOverrides[b] === 'groupOverrideAccessAll' &&
                        usersGroupsOverrides[a] === 'userOverrideAccessAll') ||
                      (usersGroupsOverrides[b] === 'groupOverrideAccessAll' &&
                        usersGroupsOverrides[a] === 'userOverrideAccessSome') ||
                      (usersGroupsOverrides[b] === 'groupOverrideAccessAll' && usersGroupsOverrides[a] === 'none') ||
                      (usersGroupsOverrides[b] === 'groupOverrideAccessSome' &&
                        usersGroupsOverrides[a] === 'userOverrideAccessAll') ||
                      (usersGroupsOverrides[b] === 'groupOverrideAccessSome' &&
                        usersGroupsOverrides[a] === 'userOverrideAccessSome') ||
                      (usersGroupsOverrides[b] === 'groupOverrideAccessSome' && usersGroupsOverrides[a] === 'none') ||
                      (usersGroupsOverrides[b] === 'userOverrideAccessAll' &&
                        usersGroupsOverrides[a] === 'userOverrideAccessSome') ||
                      (usersGroupsOverrides[b] === 'userOverrideAccessAll' && usersGroupsOverrides[a] === 'none') ||
                      (usersGroupsOverrides[b] === 'userOverrideAccessSome' && usersGroupsOverrides[a] === 'none')
                    ) {
                      return 1
                    } else {
                      return -1
                    }
                  }}
                />
              </div>
            </Grid>
          </Grid>
        }
      />
    </SlideUpDialog>
  )
}
