import axios from 'axios'
import { env } from '../index.js'
import { acquireAccessToken } from '@wavetronix/common-components'

let instance = null

class LicenseV2DefinitionsApi {
  getLicenseDefinitions = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .get(`${env.urls.updatesURL}/api/v2/licensedefinitionsv2/all?states=Hidden,Published`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  getOne = async (msalInstance, accounts, itemNumber) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return await axios
      .get(`${env.urls.updatesURL}/api/v2/licensedefinitionsv2?itemNumber=${itemNumber}`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  publish = async (msalInstance, accounts, itemnumber) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return await axios
      .post(`${env.urls.updatesURL}/api/v2/licensedefinitionsv2/${itemnumber}/state/Published`, null, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res)
  }

  createLicense = async (msalInstance, accounts, license, publishBool) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return await axios
      .post(`${env.urls.updatesURL}/api/v2/licensedefinitionsv2`, license, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(async res => (publishBool ? await this.publish(msalInstance, accounts, license.id) : res))
  }

  updateLicense = async (msalInstance, accounts, license) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return await axios
      .put(`${env.urls.updatesURL}/api/v2/licensedefinitionsv2`, license, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(async res => res)
  }

  archive = async (msalInstance, accounts, id) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return await axios
      .post(`${env.urls.updatesURL}/api/v2/licensedefinitionsv2/${id}/state/Archived`, null, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res)
  }

  hideUnhideDefinition = async (msalInstance, accounts, id, hide) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .post(`${env.urls.updatesURL}/api/v2/licensedefinitionsv2/${id}/state/${hide ? 'Hidden' : 'Published'}`, null, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res)
  }
}

const getInstance = () => {
  if (instance == null) {
    instance = new LicenseV2DefinitionsApi()
  }
  return instance
}

export default getInstance()
