import { useMsal } from '@azure/msal-react'
import { Switch, Tooltip, Grid, Card, CardContent, CircularProgress } from '@mui/material'
import { CenteredDiv, ErrorMessage, SnackbarVariants, SlideUpDialog, PersistantFilterDiv } from '@wavetronix/common-components'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import UpdatesApi from '../../api/UpdatesApi'
import GroupsApi from '../../api/GroupsApi'
import { ACCESS_LEVEL_MAP } from '../../utils/AccessLevelMap'
import { RELEASE_PHASE_MAP, removeWhiteSpace } from '../../utils/ReleasePhaseMap'
import UsersManagerTable from './ModalTables/UsersManagerTable'
import UsersModalUpdatesTable from './ModalTables/UsersModalUpdatesTable'
import UpdatesFilterDrawer, { DEFAULT_UPDATES_FILTER, filterUpdates } from '../drawers/UpdatesFilterDrawer'
import { env } from '../../index.js'

export default function UsersOverridesModal(props) {
  const [buttonsDisabled, setButtonsDisabled] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [usersGroupsOverrides, setUsersGroupsOverrides] = useState({})
  const [mostRestrictedUser, setMostRestrictedUser] = useState({})
  const [filter, setFilter] = useState(DEFAULT_UPDATES_FILTER)

  const { instance, accounts } = useMsal()

  const { data: groupsData } = useQuery({
    queryKey: ['groupsAllQuery'],
    queryFn: async () => await GroupsApi.getGroups(instance, accounts)
  })
  const { data, isLoading, refetch, error } = useQuery({
    queryKey: ['UserOverridesModalQuery'],
    queryFn: async () => await UpdatesApi.getUpdates(instance, accounts)
  })

  async function addUsersOverrides(users, id, userOverrides) {
    for (let i = 0; i < users.length; i++) {
      if (!userOverrides[users[i].id]) {
        await UpdatesApi.addOverride(instance, accounts, id, users[i].id)
      }
    }
  }

  async function removeUsersOverrides(users, id, userOverrides) {
    for (let i = 0; i < users.length; i++) {
      if (userOverrides[users[i].id]) {
        await UpdatesApi.removeOverride(instance, accounts, id, users[i].id)
      }
    }
  }

  useEffect(() => {
    if (props.users) {
      let user = [...props.users].sort(
        (a, b) =>
          ACCESS_LEVEL_MAP[a.updatesAccessLevel[env.basicAuthentication.fromCompany]] -
          ACCESS_LEVEL_MAP[b.updatesAccessLevel[env.basicAuthentication.fromCompany]]
      )[0]
      setMostRestrictedUser(user)
    } else {
      setMostRestrictedUser({})
    }
  }, [props.users])

  useEffect(() => {
    if (groupsData && props.users.length > 0 && data) {
      var usersMap = {}
      for (let update of data) {
        //default user override to none
        usersMap[update.id] = 'none'
        var userHas = []

        //check if user is on update overrides
        for (let user of props.users) {
          if (update.userOverrides[user.id]) {
            userHas.push(user.id)
          }
        }

        if (userHas.length === props.users.length) {
          usersMap[update.id] = 'userOverrideAccessAll'
        } else if (userHas.length > 0 && userHas.length < props.users.length) {
          usersMap[update.id] = 'userOverrideAccessSome'
        }

        //how many overrides for th update do our users have - groupOverrideAccess
        var groupsHas = []
        for (let group of groupsData) {
          for (let user of props.users) {
            if (
              groupsHas.includes(user.id) === false &&
              user.groups &&
              user.groups.includes(group.id) &&
              group.updates.includes(update.id)
            ) {
              groupsHas.push(user.id)
            }
          }
        }

        if (groupsHas.length === props.users.length) {
          usersMap[update.id] = 'groupOverrideAccessAll'
        } else if (groupsHas.length > 0 && groupsHas.length < props.users.length) {
          usersMap[update.id] = 'groupOverrideAccessSome'
        }
      }

      setUsersGroupsOverrides(usersMap)
    }
  }, [groupsData, props.users, data])

  const onClose = () => (buttonsDisabled ? null : props.onClose())

  async function addOverrides(id, userOverrides) {
    let key = enqueueSnackbar('Adding overrides...', SnackbarVariants.LOADING)
    await addUsersOverrides(props.users, id, userOverrides)
      .then(async () => {
        await Promise.all([props.refetch(), refetch()])
        closeSnackbar(key)
        enqueueSnackbar('Successfully added overrides', SnackbarVariants.SUCCESS)
      })
      .catch(() => {
        closeSnackbar(key)
        enqueueSnackbar('An error occurred', SnackbarVariants.ERROR)
      })
  }

  async function removeOverrides(id, userOverrides) {
    let key = enqueueSnackbar('Removing overrides...', SnackbarVariants.LOADING)
    await removeUsersOverrides(props.users, id, userOverrides)
      .then(async () => {
        await Promise.all([props.refetch(), refetch()])
        closeSnackbar(key)
        enqueueSnackbar('Successfully removed overrides', SnackbarVariants.SUCCESS)
      })
      .catch(() => {
        closeSnackbar(key)
        enqueueSnackbar('An error occurred', SnackbarVariants.ERROR)
      })
  }

  async function onSwitchChange(e, update) {
    setButtonsDisabled(true)
    if (e.target.checked) {
      await addOverrides(update.id, update.userOverrides)
    } else {
      await removeOverrides(update.id, update.userOverrides)
    }
    setButtonsDisabled(false)
  }

  if (error) {
    return (
      <CenteredDiv>
        <ErrorMessage error={error} />
      </CenteredDiv>
    )
  }

  return (
    <SlideUpDialog
      id='usersOverridesModal'
      fullScreen
      open={props.open}
      onClose={onClose}
      title={<h3 style={{ margin: '0px' }}>Manage Users</h3>}
    >
      <PersistantFilterDiv
        drawer={<UpdatesFilterDrawer filter={filter} setFilter={setFilter} />}
        resetFilter={() => setFilter(DEFAULT_UPDATES_FILTER)}
        page={
          <Grid container>
            <Grid xl={3} lg={4} md={12} sm={12} xs={12} item>
              <Card
                style={{
                  margin: '10px'
                }}
              >
                <CardContent>
                  <UsersManagerTable users={props.users ? props.users : []} />
                </CardContent>
              </Card>
            </Grid>
            <Grid xl={9} lg={8} md={12} sm={12} xs={12} item>
              <div style={{ marginTop: '10px' }}>
                {mostRestrictedUser &&
                mostRestrictedUser.updatesAccessLevel &&
                mostRestrictedUser.updatesAccessLevel[env.basicAuthentication.fromCompany] ? (
                  <UsersModalUpdatesTable
                    modalOpen={props.open}
                    refetch={refetch}
                    buttonsDisabled={buttonsDisabled}
                    setButtonsDisabled={setButtonsDisabled}
                    loading={isLoading}
                    onClose={onClose}
                    updates={
                      data && mostRestrictedUser
                        ? filterUpdates(
                            filter,
                            data.filter(
                              u =>
                                ACCESS_LEVEL_MAP[mostRestrictedUser.updatesAccessLevel[env.basicAuthentication.fromCompany]] <
                                RELEASE_PHASE_MAP[removeWhiteSpace(u.releasePhase)]
                            ),
                            mostRestrictedUser.id
                          )
                        : []
                    }
                    renderSwitch={update => {
                      var isChecked = false
                      var tooltip = ''
                      if (usersGroupsOverrides[update.id] === 'groupOverrideAccessAll') {
                        isChecked = true
                        tooltip = 'Both users have access to this update through groups. Go to group managment to change.'
                      } else if (usersGroupsOverrides[update.id] === 'groupOverrideAccessSome') {
                        isChecked = true
                        tooltip = 'Some users have access to this update through groups. Go to group managment to change.'
                      } else if (usersGroupsOverrides[update.id] === 'userOverrideAccessAll') {
                        isChecked = true
                        tooltip = 'Users have access to this update through the Admin Tool.'
                      } else if (usersGroupsOverrides[update.id] === 'userOverrideAccessSome') {
                        isChecked = true
                        tooltip = 'Some users have access to this update through the Admin Tool.'
                      }
                      return (
                        <Tooltip title={tooltip}>
                          <span>
                            <Switch
                              id={`${update.id}UserOverridesSwitch`}
                              checked={isChecked}
                              color={
                                usersGroupsOverrides[update.id] && usersGroupsOverrides[update.id].includes('All')
                                  ? 'primary'
                                  : 'secondary'
                              }
                              onChange={e => onSwitchChange(e, update)}
                              disabled={
                                buttonsDisabled ||
                                (usersGroupsOverrides[update.id] && usersGroupsOverrides[update.id].includes('group'))
                              }
                            />
                          </span>
                        </Tooltip>
                      )
                    }}
                    overridesComparator={(a, b) => {
                      if (usersGroupsOverrides[a] === usersGroupsOverrides[b]) {
                        return 0
                      } else if (
                        (usersGroupsOverrides[b] === 'groupOverrideAccessAll' &&
                          usersGroupsOverrides[a] === 'groupOverrideAccessSome') ||
                        (usersGroupsOverrides[b] === 'groupOverrideAccessAll' &&
                          usersGroupsOverrides[a] === 'userOverrideAccessAll') ||
                        (usersGroupsOverrides[b] === 'groupOverrideAccessAll' &&
                          usersGroupsOverrides[a] === 'userOverrideAccessSome') ||
                        (usersGroupsOverrides[b] === 'groupOverrideAccessAll' && usersGroupsOverrides[a] === 'none') ||
                        (usersGroupsOverrides[b] === 'groupOverrideAccessSome' &&
                          usersGroupsOverrides[a] === 'userOverrideAccessAll') ||
                        (usersGroupsOverrides[b] === 'groupOverrideAccessSome' &&
                          usersGroupsOverrides[a] === 'userOverrideAccessSome') ||
                        (usersGroupsOverrides[b] === 'groupOverrideAccessSome' && usersGroupsOverrides[a] === 'none') ||
                        (usersGroupsOverrides[b] === 'userOverrideAccessAll' &&
                          usersGroupsOverrides[a] === 'userOverrideAccessSome') ||
                        (usersGroupsOverrides[b] === 'userOverrideAccessAll' && usersGroupsOverrides[a] === 'none') ||
                        (usersGroupsOverrides[b] === 'userOverrideAccessSome' && usersGroupsOverrides[a] === 'none')
                      ) {
                        return 1
                      } else {
                        return -1
                      }
                    }}
                  />
                ) : (
                  <CenteredDiv>
                    <CircularProgress />
                  </CenteredDiv>
                )}
              </div>
            </Grid>
          </Grid>
        }
      />
    </SlideUpDialog>
  )
}
