export const localEnv = {
  wavetronix: {
    windowURL: 'softwaremanagement.wtxdev.com',
    runMode: 'dev',
    clientId: 'b9116090-72fc-41d7-aa60-eb7b5dc00524',
    urls: {
      gatekeeperURL: 'https://wtx-gatekeeper-rest.wtxdev.com',
      // gatekeeperURL: 'http://localhost:5001',
      updatesURL: 'https://wt-updatesmanagement-rest.wtxdev.com'
      // updatesURL: 'http://localhost:5002'
    },
    basicAuthentication: {}
  },
  islandradar: {
    windowURL: 'softwaremanagement.islandradardev.com',
    runMode: 'dev',
    clientId: 'b9116090-72fc-41d7-aa60-eb7b5dc00524',
    urls: {
      gatekeeperURL: 'https://wtx-gatekeeper-rest.wtxdev.com/islandradar',
      updatesURL: 'https://wt-updatesmanagement-rest.wtxdev.com/islandradar'
      // updatesURL: 'http://localhost:5002/islandradar'
    },
    basicAuthentication: {}
  },
  fathom: {
    windowURL: 'softwaremanagement.fathomradar.com',
    runMode: 'prod',
    clientId: '39996ca5-0f5a-4a8e-99b6-99b35aedf217',
    urls: {
      gatekeeperURL: 'https://wtx-gatekeeper-rest.wavetronix.com/fathom',
      updatesURL: 'https://wt-updatesmanagement-rest.wavetronix.com/fathom'
      // updatesURL: 'http://localhost:5002/fathom'
    },
    basicAuthentication: {}
  }
}
