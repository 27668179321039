import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab'
import { WtxColors } from '@wavetronix/common-components'
import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'

export default function ItemTimeline({ history }) {
  const [reversedHistory, setReversedHistory] = useState([])

  useEffect(() => {
    if (history) {
      setReversedHistory(history.reverse())
    }
  }, [history])

  return (
    <Timeline position='alternate'>
      {reversedHistory.map((item, i) => (
        <TimelineItem key={`timeline-${i}`}>
          <TimelineSeparator>
            <TimelineDot />
            {i < reversedHistory.length - 1 ? <TimelineConnector /> : null}
          </TimelineSeparator>
          <TimelineContent>
            {dayjs(item.occurredOn).format('MM/DD/YYYY hh:mm A')}
            <div style={{ color: WtxColors.IQ_BLUE }}>
              {item.modificationType} by {item.requestingEntityName}
            </div>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  )
}
