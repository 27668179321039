import { useMsal } from '@azure/msal-react'
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
  InputLabel,
  FormControl
} from '@mui/material'
import { PublishOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  acquireAccessToken,
  CenteredDiv,
  ErrorMessage,
  PrimaryButton,
  RegularButton,
  SnackbarVariants,
  SlideUpDialog,
  PersistantFilterDiv
} from '@wavetronix/common-components'
import axios from 'axios'
import fileDownload from 'js-file-download'
import { useSnackbar } from 'notistack'
import Upload from 'rc-upload'
import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import UpdatesApi from '../../api/UpdatesApi'
import LaunchPhaseApi from '../../api/LaunchPhaseApi'
import UsersApi from '../../api/UsersApi'
import GroupsApi from '../../api/GroupsApi'
import { env } from '../../index.js'
import { ACCESS_LEVEL_MAP } from '../../utils/AccessLevelMap'
import getDeviceName from '../../utils/getDeviceName'
import { RELEASE_PHASE_MAP, removeWhiteSpace } from '../../utils/ReleasePhaseMap'
import ItemTimeline from '../ItemTimeline'
import ConfirmModal from './ConfirmModal'
import UpdatesModalUsersTable from './ModalTables/UpdatesModalUsersTable'
import UsersFilterDrawer, { DEFAULT_USERS_FILTER, filterUsers } from '../drawers/UsersFilterDrawer'

function parseJwt(token) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export default function UpdateModal({ refetch: refetchUpdates, ...props }) {
  const [buttonsDisabled, setButtonsDisabled] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { instance, accounts } = useMsal()
  const [baseProductId, setBaseProductId] = useState('')
  const [itemNumber, setItemNumber] = useState('')
  const [version, setVersion] = useState('')
  const [description, setDescription] = useState('')
  const [releasePhase, setReleasePhase] = useState('')
  const [launchPhase, setLaunchPhase] = useState('')
  const [region, setRegion] = useState('')
  const [language, setLanguage] = useState('')
  const [history, setHistory] = useState()
  const [isHidden, setIsHidden] = useState(false)
  const [userOverrides, setUserOverrides] = useState({})
  const [usersGroupsOverrides, setUsersGroupsOverrides] = useState({})
  const [filter, setFilter] = useState(DEFAULT_USERS_FILTER)

  const { data, isLoading, refetch, error } = useQuery({
    queryKey: ['UpdateModalQuery'],
    queryFn: async () => await UsersApi.getUsers(instance, accounts)
  })

  const {
    data: phasesData,
    isLoading: phasesLoading,
    refetch: refetchPhases
  } = useQuery({
    queryKey: ['phasesAllQuery'],
    queryFn: async () => await LaunchPhaseApi.getAllLaunchPhases(instance, accounts)
  })

  const { data: groupsData } = useQuery({
    queryKey: ['groupsAllQuery'],
    queryFn: async () => await GroupsApi.getGroups(instance, accounts)
  })

  const [href, setHref] = useState('')
  const [hideUnhideModalOpen, setHideUnhideModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [uploadToken, setUploadToken] = useState('')
  const [uploading, setUploading] = useState(false)
  const [downloadingNotes, setDownloadingNotes] = useState(false)
  // The following state variable is a fix for the issue where after uploading release notes for the first time it doesn't
  // immediately show up in the UI.  Do not remove this or change how this is used unless you have accounted for this issue.
  const [releaseNoteJustUploaded, setReleaseNoteJustUploaded] = useState(false)

  useEffect(() => {
    setReleaseNoteJustUploaded(false)
  }, [props.update])

  useEffect(() => {
    if (releaseNoteJustUploaded === true) {
      refetchUpdates()
    }
  }, [releaseNoteJustUploaded, refetchUpdates])

  useEffect(() => {
    if (groupsData && props.update && data && userOverrides) {
      var usersMap = {}
      for (let user of data) {
        //default user override to none
        usersMap[user.id] = 'none'

        //if user override on update set to updateAccess
        if (userOverrides[user.id]) {
          usersMap[user.id] = 'userOverrideAccess'
        }

        //if user is in a group with override access reset to groupOverrideAccess
        for (let group of groupsData) {
          if (user.groups && user.groups.includes(group.id) && group.updates.includes(props.update.id)) {
            usersMap[user.id] = 'groupOverrideAccess'
          }
        }
      }

      setUsersGroupsOverrides(usersMap)
    }
  }, [groupsData, props.update, data, userOverrides])

  useEffect(() => {
    async function resetUploadToken() {
      let token = await acquireAccessToken(instance, accounts, env)
      setUploadToken(token)
    }

    if (!uploadToken || parseJwt(uploadToken).exp < Date.now() / 1000) {
      console.log('resetting token')
      resetUploadToken()
    }
  }, [accounts, instance, uploadToken])

  useEffect(() => {
    const buildHref = async id => {
      const url = '/api/v1/releasenotes'
      setHref(`${env.urls.updatesURL}${url}/${id}`)
    }

    if (props.update) {
      buildHref(props.update.id)
      setBaseProductId(props.update.baseProductId)
      setItemNumber(props.update.itemNumber)
      setVersion(props.update.version)
      setDescription(props.update.description)
      setReleasePhase(props.update.releasePhase)
      setLaunchPhase(props.update.launchPhase)
      setRegion(props.update.region)
      setLanguage(props.update.language)
      setHistory(props.update.history)
      setIsHidden(props.update.isHidden)
      setUserOverrides(props.update.userOverrides)
    }
  }, [props.update])

  useEffect(() => setButtonsDisabled(uploading), [uploading])

  const onClose = () => {
    if (buttonsDisabled === false) {
      setBaseProductId('')
      setItemNumber('')
      setVersion('')
      setDescription('')
      setReleasePhase('')
      setLaunchPhase('')
      setRegion('')
      setLanguage('')
      setHistory()
      setIsHidden(false)
      setUserOverrides({})
      props.onClose()
    }
  }

  async function openReleaseNote() {
    let token = await acquireAccessToken(instance, accounts, env)
    setDownloadingNotes(true)
    await axios({
      url: href,
      method: 'GET',
      responseType: 'blob',
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(response => {
        fileDownload(response.data, `${version}.pdf`, 'application/pdf')
      })
      .finally(() => {
        setDownloadingNotes(false)
      })
  }

  async function addOverride(userId) {
    let key = enqueueSnackbar('Adding override...', SnackbarVariants.LOADING)
    await UpdatesApi.addOverride(instance, accounts, props.update.id, userId)
      .then(async () => {
        await Promise.all([refetchUpdates(), refetch()])
        closeSnackbar(key)
        enqueueSnackbar('Successfully added override', SnackbarVariants.SUCCESS)
      })
      .catch(() => {
        closeSnackbar(key)
        enqueueSnackbar('Failed to add override', SnackbarVariants.ERROR)
      })
  }

  async function removeOverride(userId) {
    let key = enqueueSnackbar('Removing override...', SnackbarVariants.LOADING)
    await UpdatesApi.removeOverride(instance, accounts, props.update.id, userId)
      .then(async () => {
        await Promise.all([refetchUpdates(), refetch()])
        closeSnackbar(key)
        enqueueSnackbar('Successfully removed override', SnackbarVariants.SUCCESS)
      })
      .catch(() => {
        closeSnackbar(key)
        enqueueSnackbar('Failed to remove override', SnackbarVariants.ERROR)
      })
  }

  async function onCheckChange(e, userId) {
    setButtonsDisabled(true)
    if (e.target.checked) {
      await addOverride(userId)
    } else {
      await removeOverride(userId)
    }
    refetch()
    setButtonsDisabled(false)
  }

  if (error) {
    return (
      <CenteredDiv>
        <ErrorMessage error={error} />
      </CenteredDiv>
    )
  }

  return (
    <>
      <ConfirmModal
        open={hideUnhideModalOpen}
        onClose={() => setHideUnhideModalOpen(false)}
        action={isHidden ? 'Unhide' : 'Hide'}
        data={props.update ? [props.update] : []}
        styles={props.styles}
        variant='updates'
        refresh={refetchUpdates}
      />
      <ConfirmModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        action={'Delete'}
        data={props.update ? [props.update] : []}
        styles={props.styles}
        variant='updates'
        refresh={async () => {
          await refetchUpdates()
          onClose()
        }}
      />
      <SlideUpDialog
        id='updateModal'
        open={props.open}
        onClose={onClose}
        fullScreen
        title={<h3 style={{ margin: '0px' }}>Manage Update</h3>}
      >
        <PersistantFilterDiv
          drawer={<UsersFilterDrawer filter={filter} setFilter={setFilter} />}
          resetFilter={() => setFilter(DEFAULT_USERS_FILTER)}
          page={
            <Grid container>
              <Grid xl={4} lg={4} md={12} sm={12} xs={12} item>
                <Card
                  style={{
                    margin: '10px'
                  }}
                >
                  <CardContent>
                    <TextField
                      label='Type'
                      value={getDeviceName(baseProductId)}
                      disabled
                      size='small'
                      style={{ width: '100%' }}
                    />
                    <TextField
                      label='Item Number'
                      value={itemNumber}
                      disabled
                      size='small'
                      style={{ width: '100%', marginTop: '15px' }}
                    />
                    <TextField
                      label='Version'
                      value={version}
                      disabled
                      size='small'
                      style={{ width: '100%', marginTop: '15px' }}
                    />
                    <TextField
                      label='Description'
                      style={{ width: '100%', marginTop: '15px' }}
                      onChange={e => {
                        setDescription(e.target.value)
                      }}
                      value={description}
                      variant='outlined'
                      size='small'
                      multiline
                      rows={4}
                    />
                    <FormControl style={{ width: '100%' }}>
                      <InputLabel id='demo-simple-select-label' style={{ marginTop: '15px' }}>
                        Release Phase
                      </InputLabel>
                      <Select
                        id='demo-simple-select-label'
                        label={'Release Phase'}
                        value={releasePhase}
                        onChange={e => setReleasePhase(e.target.value)}
                        size='small'
                        style={{ width: '100%', marginTop: '15px' }}
                      >
                        {['Unreleased', 'Engineering', 'Beta Candidate', 'Beta', 'General'].map(phase => (
                          <MenuItem
                            value={phase}
                            key={phase}
                            disabled={
                              RELEASE_PHASE_MAP[removeWhiteSpace(releasePhase)] < RELEASE_PHASE_MAP[removeWhiteSpace(phase)] ||
                              (releasePhase === 'Unreleased' && phase === 'General')
                            }
                          >
                            {phase}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Autocomplete
                      style={{ width: '100%', marginTop: '15px' }}
                      size='small'
                      options={phasesData ? phasesData : []}
                      filterOptions={(options, option) => {
                        let input = option.inputValue
                        if (input !== '' && !options.includes(input)) {
                          return [...options, input]
                        } else return options
                      }}
                      renderInput={params => <TextField {...params} label='Launch Phase' variant='outlined' />}
                      renderOption={(props, option) => (
                        <Box
                          sx={[
                            {
                              background: option === launchPhase ? '#f0f4fb' : 'inherit',
                              '&:hover': {
                                background: option === launchPhase ? '#e8edf9' : '#f5f5f5'
                              }
                            }
                          ]}
                        >
                          <li style={{ background: 'inherit' }} {...props}>
                            {option}
                            <IconButton
                              style={{ marginLeft: 'auto', height: '24px', width: '24px' }}
                              onClick={async e => {
                                await LaunchPhaseApi.deleteLaunchPhase(instance, accounts, option)
                                refetchPhases()
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </li>
                        </Box>
                      )}
                      value={launchPhase}
                      onInputChange={(_, e) => setLaunchPhase(e)}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      disableClearable
                      disableCloseOnSelect
                    />
                    <TextField label='Region' value={region} disabled size='small' style={{ width: '100%', marginTop: '15px' }} />
                    <TextField
                      label='Language'
                      value={language}
                      disabled
                      size='small'
                      style={{ width: '100%', marginTop: '15px' }}
                    />
                    <CenteredDiv>
                      <PrimaryButton
                        style={{ width: '100px', marginTop: '15px' }}
                        onClick={async () => {
                          setButtonsDisabled(true)
                          await UpdatesApi.editUpdate(instance, accounts, {
                            ...props.update,
                            description,
                            releasePhase,
                            launchPhase
                          })
                            .then(async () => await refetchUpdates())
                            .finally(() => {
                              enqueueSnackbar('Successfully updated information', SnackbarVariants.SUCCESS)
                              setButtonsDisabled(false)
                            })
                          if (!(phasesData.includes(launchPhase) || launchPhase === '')) {
                            await LaunchPhaseApi.createLaunchPhase(instance, accounts, launchPhase)
                            refetchPhases()
                          }
                        }}
                        disabled={
                          buttonsDisabled ||
                          (props.update &&
                            props.update.description === description &&
                            props.update.releasePhase === releasePhase &&
                            props.update.launchPhase === launchPhase)
                        }
                      >
                        Save
                      </PrimaryButton>
                    </CenteredDiv>
                  </CardContent>
                </Card>
                <Card
                  style={{
                    margin: '10px'
                  }}
                >
                  <CardContent>
                    <h3 style={{ margin: '0px' }}>Release Notes</h3>
                    <div style={{ width: '100%', marginTop: '15px', display: 'flex' }}>
                      <div style={{ width: '50%', margin: '10px' }}>
                        {(props.update && props.update.releaseNoteLastModified) || releaseNoteJustUploaded ? (
                          <PrimaryButton onClick={openReleaseNote} disabled={downloadingNotes} style={{ width: '100%' }}>
                            {downloadingNotes ? (
                              <>
                                <CircularProgress size={24} />
                                &nbsp;
                              </>
                            ) : (
                              ''
                            )}
                            {version}.pdf
                          </PrimaryButton>
                        ) : (
                          "Release notes haven't been uploaded"
                        )}
                      </div>
                      <div style={{ width: '50%', margin: '10px' }}>
                        <Upload
                          name='releaseNote'
                          method={
                            (props.update && props.update.releaseNoteLastModified) || releaseNoteJustUploaded ? 'PUT' : 'POST'
                          }
                          action={href}
                          headers={{
                            Authorization: `Bearer ${uploadToken}`
                          }}
                          multiple={false}
                          directory={false}
                          accept='.pdf'
                          disabled={buttonsDisabled || !href}
                          onStart={() => setUploading(true)}
                          onSuccess={async () => {
                            // This await may seem to be redundant, but it helps fix the issue where after uploading release notes for the first time it doesn't
                            // immediately show up in the UI. Do not remove this or change how this is used unless you have accounted for this issue.
                            await refetchUpdates()
                            setUploading(false)
                            setReleaseNoteJustUploaded(true)
                            enqueueSnackbar('File uploaded successfully!', SnackbarVariants.SUCCESS)
                          }}
                          onError={() => {
                            setUploading(false)
                            enqueueSnackbar('Unknown error occured!', SnackbarVariants.ERROR)
                          }}
                        >
                          <PrimaryButton disabled={buttonsDisabled} style={{ width: '100%' }}>
                            {uploading ? <CircularProgress size={24} /> : <PublishOutlined />}
                            &nbsp;Upload
                          </PrimaryButton>
                        </Upload>
                      </div>
                    </div>
                  </CardContent>
                </Card>
                <Card
                  style={{
                    margin: '10px'
                  }}
                >
                  <CardContent>
                    <h3 style={{ margin: '0px' }}>History</h3>
                    <ItemTimeline history={history} />

                    <CenteredDiv>
                      {isHidden ? (
                        <>
                          <RegularButton onClick={() => setHideUnhideModalOpen(true)} disabled={buttonsDisabled}>
                            Unhide
                          </RegularButton>
                          &emsp;
                          <RegularButton onClick={() => setDeleteModalOpen(true)} disabled={buttonsDisabled}>
                            Delete
                          </RegularButton>
                        </>
                      ) : (
                        <RegularButton onClick={() => setHideUnhideModalOpen(true)} disabled={buttonsDisabled}>
                          Hide
                        </RegularButton>
                      )}
                    </CenteredDiv>
                  </CardContent>
                </Card>
              </Grid>
              <Grid xl={8} lg={8} md={12} sm={12} xs={12} item>
                <div style={{ marginTop: '10px' }}>
                  <UpdatesModalUsersTable
                    styles={props.styles}
                    modalOpen={props.open}
                    refetch={refetch}
                    buttonsDisabled={buttonsDisabled}
                    setButtonsDisabled={setButtonsDisabled}
                    loading={isLoading || phasesLoading}
                    onClose={onClose}
                    users={
                      data && props.update
                        ? filterUsers(
                            filter,
                            data.filter(
                              u =>
                                ACCESS_LEVEL_MAP[u.updatesAccessLevel[env.basicAuthentication.fromCompany]] <
                                RELEASE_PHASE_MAP[removeWhiteSpace(releasePhase)]
                            )
                          )
                        : []
                    }
                    renderSwitch={id => {
                      return (
                        <Switch
                          checked={
                            usersGroupsOverrides[id] === 'groupOverrideAccess' ||
                            usersGroupsOverrides[id] === 'userOverrideAccess'
                              ? true
                              : false
                          }
                          onChange={e => onCheckChange(e, id)}
                          disabled={buttonsDisabled || usersGroupsOverrides[id] === 'groupOverrideAccess'}
                          color='primary'
                        />
                      )
                    }}
                    overridesComparator={(a, b) => {
                      if (usersGroupsOverrides[a] === usersGroupsOverrides[b]) {
                        return 0
                      } else if (
                        (usersGroupsOverrides[b] === 'groupOverrideAccess' && usersGroupsOverrides[a] === 'userOverrideAccess') ||
                        (usersGroupsOverrides[b] === 'groupOverrideAccess' && usersGroupsOverrides[a] === 'none') ||
                        (usersGroupsOverrides[b] === 'userOverrideAccess' && usersGroupsOverrides[a] === 'none')
                      ) {
                        return 1
                      } else {
                        return -1
                      }
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          }
        />
      </SlideUpDialog>
    </>
  )
}
