import { CustomDataGrid } from '@wavetronix/common-components'
import React, { useState } from 'react'
import { LEVEL_ROLE_ENUM_HASH } from '../../../utils/AccessLevelMap'
import { env } from '../../../index.js'
export default function UsersManagerTable({ users }) {
  const columns = [
    { field: 'givenName', headerName: 'First Name', flex: 1, disableColumnMenu: true },
    { field: 'surname', headerName: 'Last Name', flex: 1, disableColumnMenu: true },
    {
      field: 'updatesAccessLevel',
      headerName: 'Access',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: u => LEVEL_ROLE_ENUM_HASH[u.row.updatesAccessLevel[env.basicAuthentication.fromCompany]]
    }
  ]
  const [pageSize, setPageSize] = useState(50)

  return (
    <div style={{ margin: '10px 10px 10px 0px' }}>
      <CustomDataGrid
        rows={users ? users : []}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={p => setPageSize(p.pageSize)}
        rowsPerPageOptions={[10, 20, 50, 100]}
        disableSelectionOnClick
        disableExtendRowFullWidth={true}
      />
    </div>
  )
}
