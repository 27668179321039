import { useMsal } from '@azure/msal-react'
import {
  CircularProgress,
  DialogContent,
  Divider,
  Step,
  StepLabel,
  Stepper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Grid,
  Card,
  CardContent
} from '@mui/material'
import { ArrowLeftOutlined, ArrowRightOutlined, HelpOutline } from '@mui/icons-material'
import {
  CenteredDiv,
  CustomSelect,
  PrimaryButton,
  RegularButton,
  SnackbarVariants,
  WtxColors,
  SlideUpDialog
} from '@wavetronix/common-components'
import { useSnackbar } from 'notistack'
import React, { useEffect, useRef, useState } from 'react'
import LicenseDefinitionsApi from '../../api/LicenseDefinitionsApi'
import MasterFeaturesApi from '../../api/MasterFeaturesApi'
import compareAlphabetically from '../../utils/compareAlphabetically'
import onUnload from '../../utils/onUnload'
import CreateDefinitionDetails from '../CreateDefinitionDetails'
import CreateDefinitionFirstStep from '../CreateDefinitionFirstStep'
import LicenseDefFeaturesForm from '../LicenseDefFeaturesForm'
import ConfirmPublishModal from './ConfirmPublishModal'

const DEFAULT_FORM = {
  baseProductId: '',
  itemNumber: '',
  name: '',
  region: '',
  language: '',
  description: ''
}

const STEPS = ['Define License', 'Set Features', 'Confirm Definition']

export default function LicenseDefCreateModal(props) {
  const { instance, accounts } = useMsal()

  const [activeStep, setActiveStep] = useState(0)
  const [buttonsDisabled, setButtonsDisabled] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [form, setForm] = useState({
    ...DEFAULT_FORM,
    applicableItemNumbers: ['']
  })
  const [features, setFeatures] = useState([])
  const [featureMap, setFeatureMap] = useState({})
  const [featureNameMap, setFeatureNameMap] = useState({})
  const [featureDescriptionMap, setFeatureDescriptionMap] = useState({})
  const [draft, setDraft] = useState({})

  useEffect(() => {
    if (activeStep > 0) {
      window.onbeforeunload = onUnload
      return () => (window.onbeforeunload = undefined)
    }
  }, [activeStep])

  useEffect(() => {
    setFeatureMap(
      features.reduce(
        (a, b) => ({
          ...a,
          [b.id]: { value: '', type: b.possibleTypeList[0] }
        }),
        {}
      )
    )
    setFeatureNameMap(features.reduce((a, b) => ({ ...a, [b.id]: b.name }), {}))
    setFeatureDescriptionMap(features.reduce((a, b) => ({ ...a, [b.id]: b.description }), {}))
  }, [features])

  function reset() {
    setActiveStep(0)
    setForm({ ...DEFAULT_FORM, applicableItemNumbers: [''] })
    setFeatures([])
    setFeatureMap({})
    setFeatureNameMap({})
    setFeatureDescriptionMap({})
    setDraft({})
  }

  function onClose() {
    if (!buttonsDisabled) {
      props.onClose()
      reset()
    }
  }

  const nextButtonDisabled = () =>
    buttonsDisabled ||
    !form.baseProductId ||
    !form.name ||
    !form.itemNumber ||
    !form.region ||
    !form.language ||
    form.applicableItemNumbers.some(x => !x) ||
    !form.description

  const contentRef = useRef(null)

  async function handleNextStepClick() {
    if (activeStep === 0) {
      setButtonsDisabled(true)
      await LicenseDefinitionsApi.getOne(instance, accounts, form.itemNumber)
        .then(() => enqueueSnackbar('A product with this item number already exists.', SnackbarVariants.ERROR))
        .catch(async e => {
          if (e.response.status === 404) {
            await MasterFeaturesApi.getAvailableFeatures(instance, accounts, form.baseProductId)
              .then(features => {
                setFeatures(features)
                setActiveStep(1)
              })
              .catch(() => enqueueSnackbar('An error occurred.', SnackbarVariants.ERROR))
          } else {
            enqueueSnackbar('An error occurred.', SnackbarVariants.ERROR)
          }
        })
        .finally(() => setButtonsDisabled(false))
    } else {
      // activeStep must equal 1
      let newDraft = { ...form, features: [] }
      for (let f of features) {
        if (featureMap[f.id].type === 'Bool' && featureMap[f.id].value === 'true') {
          newDraft.features.push({
            id: f.id,
            value: featureMap[f.id].value,
            maxValue: f.maxValue,
            type: 'Bool'
          })
        } else if (featureMap[f.id].value) {
          newDraft.features.push({
            id: f.id,
            value: featureMap[f.id].value,
            maxValue: f.maxValue,
            type: featureMap[f.id].type
          })
        }
      }
      newDraft.features.sort((a, b) => compareAlphabetically(a.id, b.id))
      setDraft(newDraft)
      setActiveStep(2)
    }
    contentRef.current.scrollTo(0, 0)
  }

  async function saveDraft() {
    setButtonsDisabled(true)
    let key = enqueueSnackbar('Saving draft...', SnackbarVariants.LOADING)
    await LicenseDefinitionsApi.createLicense(instance, accounts, draft, false)
      .then(async () => {
        await props.refetch()
        closeSnackbar(key)
        enqueueSnackbar('Successfully saved draft.', SnackbarVariants.SUCCESS)
        props.onClose()
        reset()
      })
      .catch(() => enqueueSnackbar('Failed to save draft', SnackbarVariants.ERROR))
      .finally(() => setButtonsDisabled(false))
  }

  const [publishModalOpen, setPublishModalOpen] = useState(false)

  async function publish() {
    setButtonsDisabled(true)
    let key = enqueueSnackbar('Publishing...', SnackbarVariants.LOADING)
    await LicenseDefinitionsApi.createLicense(instance, accounts, draft, true)
      .then(async () => {
        await props.refetch()
        closeSnackbar(key)
        enqueueSnackbar('Successfully published license definition.', SnackbarVariants.SUCCESS)
        setPublishModalOpen(false)
        props.onClose()
        reset()
      })
      .catch(() => enqueueSnackbar('Failed to publish license definition', SnackbarVariants.ERROR))
      .finally(() => setButtonsDisabled(false))
  }

  return (
    <>
      <ConfirmPublishModal
        open={publishModalOpen}
        onClose={() => setPublishModalOpen(false)}
        styles={props.styles}
        draft={draft}
        onPublish={publish}
        buttonsDisabled={buttonsDisabled}
      />
      <SlideUpDialog
        id='licenseDefCreateModal'
        open={props.open}
        onClose={onClose}
        fullScreen
        title={<h3 style={{ margin: '0px' }}>New License Definition</h3>}
        actions={
          <div style={{ display: 'flex' }}>
            {activeStep > 0 ? (
              <RegularButton
                id='licenseDefCreateBackButton'
                disabled={buttonsDisabled}
                onClick={() => setActiveStep(s => s - 1)}
                style={{ marginRight: '10px' }}
              >
                <ArrowLeftOutlined />
                Go Back
              </RegularButton>
            ) : null}
            <div style={{ flex: 1 }} />
            {activeStep < 2 ? (
              <PrimaryButton
                id='licenseDefCreateNextButton'
                disabled={nextButtonDisabled()}
                onClick={handleNextStepClick}
                style={{ marginRight: '10px' }}
              >
                Next Step
                {buttonsDisabled ? <CircularProgress size={24} /> : <ArrowRightOutlined />}
              </PrimaryButton>
            ) : (
              <>
                <PrimaryButton
                  id='licenseDefCreatePublishButton'
                  disabled={buttonsDisabled}
                  onClick={() => setPublishModalOpen(true)}
                  style={{ marginRight: '10px' }}
                >
                  Publish
                </PrimaryButton>
                <RegularButton id='licenseDefCreateSaveDraftButton' disabled={buttonsDisabled} onClick={saveDraft}>
                  Save as Draft
                </RegularButton>
              </>
            )}
            <RegularButton id='licenseDefCreateCancelButton' onClick={onClose} disabled={buttonsDisabled}>
              Cancel
            </RegularButton>
          </div>
        }
      >
        <DialogContent ref={contentRef}>
          <div style={{ minHeight: '200px' }}>
            <Stepper activeStep={activeStep}>
              {STEPS.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === 0 ? (
              <CreateDefinitionFirstStep form={form} setForm={setForm} disabled={buttonsDisabled} variant='license definitions' />
            ) : activeStep === 1 ? (
              features.length > 0 ? (
                <CenteredDiv>
                  <div style={{ width: '90%' }}>
                    <LicenseDefFeaturesForm
                      featureMap={featureMap}
                      setFeatureMap={setFeatureMap}
                      disabled={buttonsDisabled}
                      features={features}
                    />
                  </div>
                </CenteredDiv>
              ) : (
                <CenteredDiv>No available features.</CenteredDiv>
              )
            ) : (
              <Grid container spacing={2}>
                <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                  <Card sx={{ marginTop: '10px' }}>
                    <CardContent>
                      <CreateDefinitionDetails draft={draft} />
                      <h4 style={{ margin: '0px', paddingTop: '15px', paddingBottom: '15px' }}>Applicable Item Numbers</h4>
                      {draft.applicableItemNumbers.map((n, i) => n + (i === draft.applicableItemNumbers.length - 1 ? '' : ', '))}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                  <CenteredDiv>
                    <h2>Features</h2>
                  </CenteredDiv>
                  {draft.features.length > 0 ? (
                    <>
                      <Divider />
                      <TableContainer>
                        <Table>
                          <TableBody>
                            {draft.features.map((f, i) => (
                              <TableRow key={i}>
                                <TableCell style={{ width: '75%' }}>
                                  <div style={{ display: 'flex' }}>
                                    {f.type === 'Bool' ? (
                                      <div>
                                        <Switch
                                          id={`${f.id}licenseDefCreateFeatureSwitch`}
                                          checked={f.value === 'true'}
                                          style={{ color: WtxColors.IQ_BLUE }}
                                          disabled
                                        />
                                        {featureNameMap[f.id]}
                                      </div>
                                    ) : (
                                      <div style={{ width: '75%' }}>
                                        <TextField
                                          id={`${f.id}licenseDefCreateFeatureNameTextField`}
                                          label={featureNameMap[f.id]}
                                          value={f.value}
                                          disabled
                                          size='small'
                                          style={{ width: '100%' }}
                                        />
                                      </div>
                                    )}
                                    <Tooltip title={featureDescriptionMap[f.id]}>
                                      <HelpOutline style={{ marginLeft: '8px', marginTop: '8px' }} />
                                    </Tooltip>
                                  </div>
                                </TableCell>
                                <TableCell style={{ width: '25%' }}>
                                  <CustomSelect
                                    id={`${f.id}licenseDefCreateFeatureSelect`}
                                    style={{ width: '100%' }}
                                    label='Type'
                                    value={f.type}
                                    options={[f.type]}
                                    disabled
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  ) : (
                    <CenteredDiv>No features selected.</CenteredDiv>
                  )}
                </Grid>
              </Grid>
            )}
          </div>
        </DialogContent>
      </SlideUpDialog>
    </>
  )
}
