import { Switch, TextField, Tooltip } from '@mui/material'
import { HelpOutline } from '@mui/icons-material'
import { CenteredDiv, SnackbarVariants } from '@wavetronix/common-components'
import { useSnackbar } from 'notistack'
import React from 'react'

export default function ProductDefFeaturesForm({ featureMap, setFeatureMap, disabled, features }) {
  const { enqueueSnackbar } = useSnackbar()

  const handleSwitchChange = (id, checked) =>
    disabled ? null : setFeatureMap(s => ({ ...s, [id]: checked ? 'true' : undefined }))

  const handleFeatureChange = (id, value) => {
    if (value === '') {
      setFeatureMap(s => ({ ...s, [id]: value }))
    } else {
      if (!Number.isNaN(Number(value))) {
        let feature = features.find(f => f.id === id)
        if (Number(value) <= feature.maxValue) {
          setFeatureMap(s => ({ ...s, [id]: value }))
        } else {
          enqueueSnackbar(`Max value for ${feature.name} is ${feature.maxValue}`, SnackbarVariants.ERROR)
        }
      }
    }
  }

  return features.length > 0 ? (
    features.map((f, i) => (
      <div key={i} style={{ width: '50%', display: 'inline-flex', marginBottom: '10px' }}>
        {i % 2 === 0 ? null : <br />}
        {f.possibleTypeList.includes('Bool') ? (
          <div>
            <Switch
              id={`${f.id}Switch`}
              checked={featureMap[f.id] === 'true'}
              color='primary'
              onChange={e => handleSwitchChange(f.id, e.target.checked)}
              disabled={disabled}
            />
            {f.name}
            <Tooltip title={f.description}>
              <HelpOutline style={{ marginLeft: '5px', marginBottom: '-7px' }} />
            </Tooltip>
          </div>
        ) : (
          <div style={{ width: '100%' }}>
            <TextField
              id={`${f.id}TextField`}
              label={f.name}
              default=''
              onChange={e => handleFeatureChange(f.id, e.target.value)}
              style={{ width: '80%' }}
              size='small'
              value={featureMap[f.id]}
              disabled={disabled}
            />
            <Tooltip title={f.description}>
              <HelpOutline style={{ marginLeft: '5px', marginBottom: '-17px' }} />
            </Tooltip>
          </div>
        )}
      </div>
    ))
  ) : (
    <CenteredDiv>No available features.</CenteredDiv>
  )
}
