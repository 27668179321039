import { TextField } from '@mui/material'
import React from 'react'

export default function CreateLicenseDefDetails({ draft }) {
  return (
    <>
      <TextField
        id='createLicenseDefDetailItemNumberTextField'
        label='Item Number'
        value={draft.id}
        disabled
        size='small'
        style={{ width: '100%', marginTop: '15px' }}
      />
      <TextField
        id='createLicenseDefDetailNameTextField'
        label='Product Name'
        value={draft.name}
        disabled
        size='small'
        style={{ width: '100%', marginTop: '15px' }}
      />
      <TextField
        id='createLicenseDefDetailDescriptionTextField'
        label='Description'
        value={draft.description}
        disabled
        size='small'
        style={{ width: '100%', marginTop: '15px' }}
        multiline
        rows={4}
      />
      <TextField
        id='createLicenseDefDetailRegionTextField'
        label='Region'
        value={draft.region}
        disabled
        size='small'
        style={{ width: '100%', marginTop: '15px' }}
      />
      <TextField
        id='createLicenseDefDetailLanguageTextField'
        label='Language'
        value={draft.language}
        disabled
        size='small'
        style={{ width: '100%', marginTop: '15px' }}
      />
    </>
  )
}
