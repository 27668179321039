import { useMsal } from '@azure/msal-react'
import { Chip, CircularProgress } from '@mui/material'
import { CenteredDiv, RegularButton, SnackbarVariants, SlideUpDialog, DeleteButton } from '@wavetronix/common-components'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import LicenseV2DefinitionsApi from '../../api/LicenseV2DefinitionsApi'
import ProductDefinitionsApi from '../../api/ProductDefinitionsApi'
import UpdatesApi from '../../api/UpdatesApi'
import getDeviceName from '../../utils/getDeviceName'

const PAST_TENSE_MAP = {
  Hide: 'hid',
  Unhide: 'unhid',
  Delete: 'deleted'
}

const API_CALL_MAP = {
  updates: {
    Hide: async (msalInstance, account, update) => await UpdatesApi.hideOrUnhideUpdate(msalInstance, account, update.id, true),
    Unhide: async (msalInstance, account, update) => await UpdatesApi.hideOrUnhideUpdate(msalInstance, account, update.id, false),
    Delete: async (msalInstance, account, update) => await UpdatesApi.deleteUpdate(msalInstance, account, update.id)
  },
  'product definitions': {
    Hide: async (msalInstance, account, productDef) =>
      ProductDefinitionsApi.hideUnhideDefinition(msalInstance, account, productDef.itemNumber, true),
    Unhide: (msalInstance, account, productDef) =>
      ProductDefinitionsApi.hideUnhideDefinition(msalInstance, account, productDef.itemNumber, false),
    Delete: (msalInstance, account, productDef) =>
      productDef.state === 'Draft'
        ? ProductDefinitionsApi.deleteDraft(msalInstance, account, productDef.itemNumber)
        : ProductDefinitionsApi.archive(msalInstance, account, productDef.itemNumber)
  },
  'license definitions': {
    Hide: async (msalInstance, account, licenseDef) =>
      LicenseV2DefinitionsApi.hideUnhideDefinition(msalInstance, account, licenseDef.id, true),
    Unhide: (msalInstance, account, licenseDef) =>
      LicenseV2DefinitionsApi.hideUnhideDefinition(msalInstance, account, licenseDef.id, false),
    Delete: (msalInstance, account, licenseDef) => LicenseV2DefinitionsApi.archive(msalInstance, account, licenseDef.id)
  }
}

export default function ConfirmModalV2(props) {
  const [buttonsDisabled, setButtonsDisabled] = useState(false)

  const onClose = () => (buttonsDisabled ? null : props.onClose())

  const { enqueueSnackbar } = useSnackbar()

  const { instance, accounts } = useMsal()
  return (
    <SlideUpDialog
      id='confirmV2Modal'
      open={props.open}
      onClose={onClose}
      maxWidth={'sm'}
      title={<h3 style={{ margin: '0px' }}>Confirm {props.action}</h3>}
      actions={
        <div>
          <DeleteButton
            id={`${props.action}DeleteButton`}
            style={{ marginRight: '10px' }}
            disabled={buttonsDisabled}
            onClick={async () => {
              setButtonsDisabled(true)
              await Promise.all(
                props.data.map(x => {
                  return API_CALL_MAP[props.variant.toLowerCase()][props.action](instance, accounts, x)
                })
              )
                .then(async () => {
                  await props.refresh()
                  let object = props.data.length > 1 ? props.variant : props.variant.substr(0, props.variant.length - 1)
                  enqueueSnackbar(`Successfully ${PAST_TENSE_MAP[props.action]} ${object}`, SnackbarVariants.SUCCESS)
                })
                .catch(e => {
                  enqueueSnackbar(`An error occurred: ${e.message}`, SnackbarVariants.ERROR)
                })
                .finally(() => {
                  setButtonsDisabled(false)
                  props.onClose()
                })
            }}
          >
            {buttonsDisabled ? (
              <>
                <CircularProgress size={24} />
                &nbsp;
              </>
            ) : (
              ''
            )}
            {props.action}
          </DeleteButton>
          <RegularButton id={`${props.action}CancelButton`} onClick={onClose} disabled={buttonsDisabled}>
            Cancel
          </RegularButton>
        </div>
      }
    >
      <div style={{ margin: '10px' }}>
        Are you sure you want to {props.action.toLowerCase()}:
        <div style={{ height: '55%', width: '100%', overflow: 'auto' }}>
          {props.data.length > 0 ? (
            props.data.map((u, i) => {
              return (
                <div key={`updates-${i}`} style={{ marginTop: '5px' }}>
                  {props.variant === 'license definitions' ? (
                    <Chip id={`${u.id}LicenseDefChip`} label={`${u.name} - ${u.id}`} variant='outlined' color='primary' />
                  ) : (
                    <Chip
                      id={`${u.id}ProductDefChip`}
                      label={`${getDeviceName(u.baseProductId)} - ${u.version}`}
                      variant='outlined'
                      color='primary'
                    />
                  )}
                </div>
              )
            })
          ) : (
            <CenteredDiv>
              <CircularProgress />
            </CenteredDiv>
          )}
        </div>
      </div>
    </SlideUpDialog>
  )
}
