import { useMsal } from '@azure/msal-react'
import { CircularProgress, Grid } from '@mui/material'
import {
  CenteredDiv,
  ErrorMessage,
  PrimaryButton,
  RegularButton,
  SnackbarVariants,
  SlideUpDialog
} from '@wavetronix/common-components'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import MasterFeaturesApi from '../../api/MasterFeaturesApi'
import ProductDefinitionsApi from '../../api/ProductDefinitionsApi'
import compareAlphabetically from '../../utils/compareAlphabetically'
import onUnload from '../../utils/onUnload'
import EditDefinitionDetails, { DEFAULT_FORM } from '../EditDefinitionDetails'
import ProductDefFeaturesForm from '../ProductDefFeaturesForm'
import ConfirmPublishModal from './ConfirmPublishModal'

const DEFAULT_PUBLISH_MODAL_STATE = {
  open: false,
  draft: undefined,
  onPublish: () => {}
}

export default function ProductDefinitionModal(props) {
  const { instance, accounts } = useMsal()
  const [buttonsDisabled, setButtonsDisabled] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [history, setHistory] = useState([])
  const { data, isLoading, refetch, error, isFetching } = useQuery({
    queryKey: ['ProductDefModalQuery'],
    queryFn: async () => {
      if (!props.productDef.baseProductId || !props.productDef.itemNumber) {
        setHistory([])
        return []
      }
      let featurePromise = MasterFeaturesApi.getAvailableFeatures(instance, accounts, props.productDef.baseProductId)
      let historyPromise = ProductDefinitionsApi.getHistory(instance, accounts, props.productDef.itemNumber)
      return await Promise.all([featurePromise, historyPromise]).then(values => {
        setHistory(values[1])
        return values[0]
      })
    }
  })

  const [oldFeatureMap, setOldFeatureMap] = useState({})
  const [newFeatureMap, setNewFeatureMap] = useState({})
  const [form, setForm] = useState(DEFAULT_FORM)
  const [id, setId] = useState('')

  useEffect(() => {
    refetch()
  }, [props.productDef, refetch])

  // This makes it so the form and feature map only changes if the user is looking at a different update
  useEffect(() => {
    if (props.productDef.id !== id) {
      if (props.productDef.features) {
        let featureMap = props.productDef.features.reduce((a, b) => ({ ...a, [b.id]: b.value }), {})
        setOldFeatureMap(featureMap)
        setNewFeatureMap({ ...featureMap })
      }
      let form = { ...DEFAULT_FORM }
      if (props.productDef.name) {
        form.name = props.productDef.name
      }
      if (props.productDef.region) {
        form.region = props.productDef.region
      }
      if (props.productDef.language) {
        form.language = props.productDef.language
      }
      if (props.productDef.description) {
        form.description = props.productDef.description
      }
      setForm(form)
      setId(props.productDef.id)
    }
  }, [props.productDef, id])

  const saveButtonsDisabled =
    (Object.entries(newFeatureMap).every(pair => pair[1] === oldFeatureMap[pair[0]]) &&
      Object.entries(form).every(pair => pair[1] === props.productDef[pair[0]])) ||
    !form.name

  useEffect(() => {
    if (saveButtonsDisabled) {
      window.onbeforeunload = undefined
    } else {
      window.onbeforeunload = onUnload
    }
    return () => (window.onbeforeunload = undefined)
  }, [saveButtonsDisabled])

  function buildDraft() {
    let draft = { ...props.productDef, features: [] }
    // delete the row id which does not correspond to the actual id/itemNumber.
    delete draft.id
    for (let f of data) {
      if (newFeatureMap[f.id]) {
        draft.features.push({
          id: f.id,
          value: newFeatureMap[f.id],
          maxValue: f.maxValue,
          type: f.possibleTypeList.includes('Bool') ? 'Bool' : 'Set'
        })
      }
    }
    draft.features.sort((a, b) => compareAlphabetically(a.id, b.id))
    for (const key in form) {
      draft[key] = form[key]
    }
    return draft
  }

  function onClose() {
    if (!buttonsDisabled) {
      props.onClose()
      setId('')
    }
  }

  const [publishModalState, setPublishModalState] = useState(DEFAULT_PUBLISH_MODAL_STATE)

  async function publish(itemNumber) {
    setButtonsDisabled(true)
    let key = enqueueSnackbar('Publishing...', SnackbarVariants.LOADING)
    await ProductDefinitionsApi.publish(instance, accounts, itemNumber)
      .then(async () => {
        await props.refetch()
        closeSnackbar(key)
        enqueueSnackbar('Successfully published draft', SnackbarVariants.SUCCESS)
        props.onClose()
        setId('')
      })
      .catch(() => {
        closeSnackbar(key)
        enqueueSnackbar('Failed to publish draft', SnackbarVariants.ERROR)
      })
      .finally(() => {
        setPublishModalState(DEFAULT_PUBLISH_MODAL_STATE)
        setButtonsDisabled(false)
      })
  }

  async function save(draft, publishBool) {
    setButtonsDisabled(true)
    let key = enqueueSnackbar('Saving...', SnackbarVariants.LOADING)
    await ProductDefinitionsApi.updateDraft(instance, accounts, draft, publishBool)
      .then(async () => {
        await props.refetch()
        closeSnackbar(key)
        enqueueSnackbar('Successfully saved draft.', SnackbarVariants.SUCCESS)
        if (publishBool) {
          await publish(draft.itemNumber)
        } else {
          props.onClose()
          setId('')
          setButtonsDisabled(false)
        }
      })
      .catch(() => {
        closeSnackbar(key)
        enqueueSnackbar('Failed to save draft', SnackbarVariants.ERROR)
        setButtonsDisabled(false)
      })
  }

  if (error) {
    return (
      <CenteredDiv>
        <ErrorMessage error={error} />
      </CenteredDiv>
    )
  }

  return (
    <>
      <ConfirmPublishModal
        open={publishModalState.open}
        onClose={() => setPublishModalState(DEFAULT_PUBLISH_MODAL_STATE)}
        styles={props.styles}
        draft={publishModalState.draft}
        onPublish={publishModalState.onPublish}
        buttonsDisabled={buttonsDisabled}
      />
      <SlideUpDialog
        id='productDefModal'
        open={props.open}
        onClose={onClose}
        fullScreen
        title={<h3 style={{ margin: '0px' }}>Edit Product Definition</h3>}
        actions={
          <div style={{ display: 'flex' }}>
            <PrimaryButton
              id='productDefSaveDraftButton'
              disabled={saveButtonsDisabled}
              onClick={() => save(buildDraft(), false)}
              style={{ marginRight: '10px' }}
            >
              Save Draft
            </PrimaryButton>
            {props.productDef.state === 'Draft' && saveButtonsDisabled ? (
              <RegularButton
                id='productDefPublishButton'
                onClick={() =>
                  setPublishModalState({
                    open: true,
                    draft: buildDraft(),
                    onPublish: draft => publish(draft.itemNumber)
                  })
                }
              >
                Publish
              </RegularButton>
            ) : (
              <RegularButton
                id='productDefSavePublishButton'
                disabled={saveButtonsDisabled}
                onClick={() =>
                  setPublishModalState({
                    open: true,
                    draft: buildDraft(),
                    onPublish: draft => save(draft, true)
                  })
                }
              >
                Save & Publish
              </RegularButton>
            )}
          </div>
        }
      >
        <div>
          <Grid container spacing={2}>
            <Grid xl={3} lg={3} md={12} sm={12} xs={12} item>
              <EditDefinitionDetails
                form={form}
                setForm={setForm}
                variant='Product'
                definition={props.productDef}
                history={history}
                refetch={props.refetch}
                onClose={onClose}
              />
            </Grid>
            <Grid xl={9} lg={9} md={12} sm={12} xs={12} item>
              <div style={{ marginTop: '10px' }}>
                {isLoading || (isFetching && data && data.length === 0) ? (
                  <CenteredDiv>
                    <CircularProgress />
                  </CenteredDiv>
                ) : data ? (
                  <>
                    <CenteredDiv>
                      <h3 style={{ margin: '0px', paddingBottom: '18px', paddingTop: '30px' }}>Features</h3>
                    </CenteredDiv>
                    <ProductDefFeaturesForm
                      featureMap={newFeatureMap}
                      setFeatureMap={setNewFeatureMap}
                      disabled={buttonsDisabled}
                      features={data}
                    />
                  </>
                ) : (
                  <h2>No Features</h2>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </SlideUpDialog>
    </>
  )
}
