import { FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Card, CardContent } from '@mui/material'
import { AddOutlined, DeleteOutlined } from '@mui/icons-material'
import { CenteredDiv, GhostButton, RegularButton, WtxColors } from '@wavetronix/common-components'
import React, { useState } from 'react'
import getDeviceName from '../utils/getDeviceName'
import ItemTimeline from './ItemTimeline'
import SelectLanguage from './selects/SelectLanguage'
import SelectRegion from './selects/SelectRegion'
import ConfirmModal from './modals/ConfirmModal'

export const DEFAULT_FORM = {
  name: '',
  region: '',
  language: '',
  description: ''
}

export default function EditDefinitionDetails({ variant, definition, form, setForm, history, refetch, onClose }) {
  const handleFormChange = (id, value) => setForm(f => ({ ...f, [id]: value }))

  const [hideUnhideModalState, setHideUnhideModalState] = useState({
    open: false,
    definition: {}
  })
  const [deleteModalState, setDeleteModalState] = useState({
    open: false,
    definition: {}
  })
  return (
    <>
      <Card sx={{ margin: '10px' }}>
        <CardContent>
          <FormControl style={{ width: '100%' }}>
            <InputLabel id='typeSelectLabel'>Base {variant}</InputLabel>
            <Select
              size='small'
              id='editDefDetailsProductSelect'
              labelId='typeSelectLabel'
              value={definition.baseProductId ? definition.baseProductId : ''}
              label={`Base ${variant}`}
              disabled
            >
              <MenuItem id={definition.baseProductId} value={definition.baseProductId}>
                {getDeviceName(definition.baseProductId)}
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            id='editDefDetailsNameTextField'
            size='small'
            style={{ width: '100%', marginTop: '15px' }}
            label='Product Name'
            onChange={e => handleFormChange('name', e.target.value)}
            value={form.name}
            required
          />
          <TextField
            id='editDefDetailsItemNumberTextField'
            size='small'
            style={{ width: '100%', marginTop: '15px' }}
            label='Item Number'
            value={definition.itemNumber ? definition.itemNumber : ''}
            disabled
          />
          <TextField
            id='editDefDetailsVersionTextField'
            style={{ width: '100%', marginTop: '15px' }}
            size='small'
            label='Version'
            value={definition.version ? definition.version : ''}
            disabled
          />
          <SelectRegion
            id='editDefDetailsRegionSelect'
            onChange={e => setForm(f => ({ ...f, region: e.target.value }))}
            style={{ width: '100%', marginTop: '15px' }}
            value={form.region}
            required
          />
          <SelectLanguage
            id='editDefDetailsLanguageSelect'
            onChange={e => setForm(f => ({ ...f, language: e.target.value }))}
            style={{ width: '100%', marginTop: '15px' }}
            value={form.language}
            required
          />
          <TextField
            id='editDefDetailsDescriptionTextField'
            style={{ width: '100%', marginTop: '15px' }}
            label='Description'
            onChange={e => handleFormChange('description', e.target.value)}
            value={form.description}
            required
            multiline
            rows={4}
            variant='outlined'
          />
          {variant === 'License' ? (
            <>
              <div style={{ width: '100%', marginTop: '15px', paddingBottom: '15px' }}>
                <b>Applicable Item Numbers</b>
              </div>
              {form.applicableItemNumbers.map((n, i) => (
                <CenteredDiv key={`applicableItemNumber-${i}`}>
                  <TextField
                    id={`${n}ApplicableItemNumberTextField`}
                    size='small'
                    onChange={e => {
                      let applicableItemNumbers = form.applicableItemNumbers
                      applicableItemNumbers[i] = e.target.value
                      setForm(f => ({
                        ...f,
                        applicableItemNumbers: applicableItemNumbers
                      }))
                    }}
                    style={{ width: '100%' }}
                    value={n}
                    required
                    label='Applicable Item Number'
                  />
                  <IconButton
                    id={`${n}ApplicableItemNumberDeleteButton`}
                    onClick={() => {
                      if (form.applicableItemNumbers.length > 1) {
                        let applicableItemNumbers = [...form.applicableItemNumbers]
                        applicableItemNumbers.splice(i, 1)
                        setForm(f => ({
                          ...f,
                          applicableItemNumbers: applicableItemNumbers
                        }))
                      }
                    }}
                    disabled={form.applicableItemNumbers.length < 2}
                    size='large'
                  >
                    <DeleteOutlined
                      style={{
                        color: form.applicableItemNumbers.length > 1 ? WtxColors.INNOVATION_RED : WtxColors.CONCRETE
                      }}
                    />
                  </IconButton>
                </CenteredDiv>
              ))}
              <CenteredDiv>
                <RegularButton
                  id='applicableItemNumberAddButton'
                  style={{ width: '100%', marginTop: '15px' }}
                  startIcon={<AddOutlined />}
                  onClick={() =>
                    setForm(f => ({
                      ...f,
                      applicableItemNumbers: [...form.applicableItemNumbers, '']
                    }))
                  }
                >
                  Add Applicable Item Number
                </RegularButton>
              </CenteredDiv>
            </>
          ) : null}

          <div style={{ marginTop: '15px' }}>
            <CenteredDiv>
              {definition.state === 'Hidden' ? (
                <div style={{ marginTop: '15px' }}>
                  <GhostButton
                    id='editDefDetailsDeleteHiddenButton'
                    style={{
                      backgroundColor: WtxColors.INNOVATION_RED,
                      borderColor: WtxColors.INNOVATION_RED,
                      marginRight: '10px'
                    }}
                    onClick={() => setDeleteModalState({ open: true, definition: definition })}
                  >
                    Delete
                  </GhostButton>
                  <RegularButton
                    id='editDefDetailsHideButton'
                    onClick={() => setHideUnhideModalState({ open: true, definition: definition })}
                  >
                    {definition.state === 'Hidden' ? 'Unhide' : 'Hide'}
                  </RegularButton>
                </div>
              ) : definition.state === 'Draft' ? (
                <GhostButton
                  id='editDefDetailsDeleteDraftButton'
                  style={{
                    backgroundColor: WtxColors.INNOVATION_RED,
                    borderColor: WtxColors.INNOVATION_RED,
                    float: 'right',
                    marginLeft: '15px'
                  }}
                  onClick={() => setDeleteModalState({ open: true, definition: definition })}
                >
                  Delete
                </GhostButton>
              ) : (
                <RegularButton
                  id='editDefDetailsDraftHideButton'
                  style={{ float: 'right' }}
                  onClick={() => setHideUnhideModalState({ open: true, definition: definition })}
                >
                  {definition.state === 'Hidden' ? 'Unhide' : 'Hide'}
                </RegularButton>
              )}
            </CenteredDiv>
          </div>
        </CardContent>
      </Card>
      <Card sx={{ margin: '10px' }}>
        <CardContent>
          <h3 style={{ margin: '0px' }}>History</h3>
          <ItemTimeline history={history} />
        </CardContent>
      </Card>
      <ConfirmModal
        open={hideUnhideModalState.open}
        onClose={() => {
          setHideUnhideModalState(s => ({
            ...s,
            open: false,
            definition: {}
          }))
          onClose()
        }}
        action={definition.state === 'Hidden' ? 'Unhide' : 'Hide'}
        data={[hideUnhideModalState.definition]}
        variant={variant + ' definitions'}
        refresh={refetch}
      />
      <ConfirmModal
        open={deleteModalState.open}
        onClose={() => {
          setDeleteModalState(s => ({ open: false, definition: {} }))
          onClose()
        }}
        action={'Delete'}
        data={[deleteModalState.definition]}
        variant={variant + ' definitions'}
        refresh={refetch}
      />
    </>
  )
}
