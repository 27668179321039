import axios from 'axios'
import { env } from '../index.js'
import { acquireAccessToken } from '@wavetronix/common-components'

let instance = null

class UsersApi {
  getUsers = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return axios
      .get(`${env.urls.gatekeeperURL}/users`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  editUser = async (msalInstance, accounts, userId, accessLevel) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return axios
      .put(
        `${env.urls.gatekeeperURL}/users/${userId}/accessLevel/${accessLevel}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
          timeout: 10000
        }
      )
      .then(res => res)
  }
}

const getInstance = () => {
  if (instance == null) {
    instance = new UsersApi()
  }
  return instance
}

export default getInstance()
