import { useMsal } from '@azure/msal-react'
import { CircularProgress, InputLabel, MenuItem, Select, FormControl, Card } from '@mui/material'
import { Alert } from '@mui/material'
import { CenteredDiv, SnackbarVariants, PrimaryButton } from '@wavetronix/common-components'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import UpdatesApi from '../../api/UpdatesApi'
import onUnload from '../../utils/onUnload'
import { RELEASE_PHASE_MAP, removeWhiteSpace } from '../../utils/ReleasePhaseMap'
import EditModal from './EditModal'
import UpdatesManagerTable from './ModalTables/UpdatesManagerTable'

export default function EditUpdatesModal(props) {
  const [buttonsDisabled, setButtonsDisabled] = useState(false)
  const [releasePhase, setReleasePhase] = useState('')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { instance, accounts } = useMsal()

  useEffect(() => {
    if (props.updates && props.updates.length > 0 && releasePhase !== props.updates[0].releasePhase) {
      window.onbeforeunload = onUnload
      return () => (window.onbeforeunload = undefined)
    }
  }, [releasePhase, props.updates])

  useEffect(
    () => setReleasePhase(props.updates && props.updates.length > 0 ? props.updates[0].releasePhase : ''),
    [props.updates]
  )

  const onClose = () => (buttonsDisabled ? null : props.onClose())

  return (
    <EditModal
      id='editUpdatesModal'
      onClose={onClose}
      buttonsDisabled={buttonsDisabled}
      open={props.open}
      title={'Edit Updates'}
      rightContent={<UpdatesManagerTable updates={props.updates ? props.updates : []} />}
      leftContent={
        <Card sx={{ margin: '10px' }}>
          <CenteredDiv>
            {!props.updates || props.updates.length === 0 ? (
              <CenteredDiv>
                <CircularProgress />
              </CenteredDiv>
            ) : props.updates.every(u => u.releasePhase === props.updates[0].releasePhase) ? (
              <div style={{ width: '100%' }}>
                <div style={{ width: '100%', padding: '15px' }}>
                  <FormControl style={{ width: '100%' }}>
                    <InputLabel id='editUpdatesReleasePhaseLabel' style={{ marginTop: '15px' }}>
                      Release Phase
                    </InputLabel>
                    <Select
                      labelId='editUpdatesReleasePhaseLabel'
                      id='editUpdatesReleasePhaseSelect'
                      label={'Release Phase'}
                      value={releasePhase}
                      onChange={e => setReleasePhase(e.target.value)}
                      size='small'
                      style={{ width: '100%', marginTop: '15px' }}
                    >
                      {['Unreleased', 'Engineering', 'Beta Candidate', 'Beta', 'General'].map(phase => (
                        <MenuItem
                          id={phase}
                          value={phase}
                          key={phase}
                          disabled={
                            RELEASE_PHASE_MAP[removeWhiteSpace(props.updates[0].releasePhase)] <
                              RELEASE_PHASE_MAP[removeWhiteSpace(phase)] ||
                            (props.updates[0].releasePhase === 'Unreleased' && phase === 'General')
                          }
                        >
                          {phase}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <CenteredDiv>
                  <PrimaryButton
                    id='saveEditUpdatesButton'
                    style={{ marginBottom: '15px' }}
                    disabled={releasePhase === props.updates[0].releasePhase || buttonsDisabled}
                    onClick={async () => {
                      setButtonsDisabled(true)
                      let key = enqueueSnackbar('Updating release phase...', {
                        variant: 'default',
                        anchorOrigin: { vertical: 'top', horizontal: 'center' },
                        action: <CircularProgress />,
                        persist: true
                      })
                      await Promise.all(
                        props.updates.map(u =>
                          UpdatesApi.editUpdate(instance, accounts, {
                            ...u,
                            releasePhase
                          })
                        )
                      )
                        .then(async () => {
                          await props.refresh()
                          closeSnackbar(key)
                          enqueueSnackbar('Successfully updated release phases', SnackbarVariants.SUCCESS)
                        })
                        .catch(() => {
                          closeSnackbar(key)
                          enqueueSnackbar('An error occurred', SnackbarVariants.ERROR)
                        })
                        .finally(() => {
                          props.onClose()
                          setButtonsDisabled(false)
                        })
                    }}
                  >
                    Save
                  </PrimaryButton>
                </CenteredDiv>
              </div>
            ) : (
              <Alert severity='warning' id='invalidUpdatesAlert'>
                The updates you have selected have multiple release phases and cannot be edited together.
              </Alert>
            )}
          </CenteredDiv>
        </Card>
      }
    />
  )
}
