import { Buffer } from 'buffer'
import axios from 'axios'
import { baseProductHash } from '../index.js'

export async function generateProductHash(env) {
  let credentials = Buffer.from(`${env.basicAuthentication.updatesUserName}:${env.basicAuthentication.updatesPassword}`).toString(
    'base64'
  )

  var data = await axios
    .get(`${env.urls.updatesURL}/api/v1/masterfeatures/baseproductid/hash`, {
      headers: { Authorization: `Basic ${credentials}` },
      timeout: 30000
    })
    .then(res => res.data)
    .catch(error => {
      console.log('Error retrieving base product id hash map')
      console.log(error)

      return []
    })

  return data.reduce((map, value) => {
    map[value.id] = value
    return map
  }, {})
}

export default function getDeviceName(id) {
  if (id in baseProductHash) return baseProductHash[id].productHashName
  else return id
}
