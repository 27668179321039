import { Tab, Tabs } from '@mui/material'
import { useHistory } from 'react-router'
import { WtxColors } from '@wavetronix/common-components'
import { env } from '../index.js'

const classes = {
  tab: {
    textTransform: 'none',
    fontFamily: 'Klavika',
    fontSize: '16px',
    alignItems: 'end',
    padding: '0px 16px 0px 0px',
    color: 'black'
  },
  tabParent: {
    width: '210px'
  }
}

export default function PageNavbar(props) {
  const history = useHistory()

  const onChange = path => {
    if (window.location.pathname !== `/${path}`) {
      history.push(`/${path}`)
    }
    props.onChangeCallback(path)
  }

  if (env.basicAuthentication.fromCompany === 'islandradar' || env.basicAuthentication.fromCompany === 'fathom') {
    return (
      <Tabs
        value={props.value}
        TabIndicatorProps={{
          sx: {
            backgroundColor: WtxColors.IQ_BLUE
          }
        }}
        orientation='vertical'
        sx={classes.tabParent}
      >
        <Tab id='updatesTab' sx={classes.tab} label='Updates' onClick={() => onChange('updates')} />
        <Tab id='usersTab' sx={classes.tab} label='Users' onClick={() => onChange('users')} />
        <Tab id='productsTab' sx={classes.tab} label='Product Management' onClick={() => onChange('baseproducthash')} />
      </Tabs>
    )
  } else {
    return (
      <Tabs
        value={props.value}
        TabIndicatorProps={{
          sx: {
            backgroundColor: WtxColors.IQ_BLUE
          }
        }}
        orientation='vertical'
        sx={classes.tabParent}
      >
        <Tab id='updatesTab' sx={classes.tab} label='Updates' onClick={() => onChange('updates')} />
        <Tab id='usersTab' sx={classes.tab} label='Users' onClick={() => onChange('users')} />
        <Tab id='productsDefTab' sx={classes.tab} label='Product Definitions' onClick={() => onChange('products')} />
        <Tab id='licensesDefTab' sx={classes.tab} label='License Definitions' onClick={() => onChange('licenses')} />
        <Tab id='productsTab' sx={classes.tab} label='Product Management' onClick={() => onChange('baseproducthash')} />
      </Tabs>
    )
  }
}
