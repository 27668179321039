import {
  SlideUpDialog,
  CustomSelect,
  getResourceUrl,
  CenteredDiv,
  PrimaryButton,
  SnackbarVariants,
  ErrorMessage
} from '@wavetronix/common-components'
import { DialogContent, Grid, TextField, CircularProgress, Switch } from '@mui/material'
import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import NexusAssetsApi from '../../api/NexusAssetsApi'
import MasterFeaturesApi from '../../api/MasterFeaturesApi'
import { useMsal } from '@azure/msal-react'
import { env } from '../../index.js'
import { useSnackbar } from 'notistack'
export default function CreateBaseProductHashModal({ open, onClose, product, productRefetch }) {
  const [productInfo, setProductInfo] = useState({})
  const { instance, accounts } = useMsal()
  const { enqueueSnackbar } = useSnackbar()

  const { data, isLoading, error } = useQuery({
    queryKey: ['productAssets'],
    queryFn: async () => await NexusAssetsApi.getAssets(instance, accounts)
  })

  useEffect(() => {
    if (product) {
      setProductInfo({ ...product })
    }
  }, [product])

  const createProductInfo = async () => {
    await MasterFeaturesApi.existsBaseProductHash(instance, accounts, productInfo.id).then(async response => {
      if (response === false) {
        await MasterFeaturesApi.createBaseProductHash(instance, accounts, productInfo)
          .then(response => enqueueSnackbar('Successfully created product info', SnackbarVariants.SUCCESS))
          .catch(error => enqueueSnackbar('Error creating product info', SnackbarVariants.ERROR))
        return true
      } else {
        enqueueSnackbar('This product already exists.', SnackbarVariants.ERROR)
        return false
      }
    })
  }

  if (error) {
    return (
      <CenteredDiv>
        <ErrorMessage error={error} />
      </CenteredDiv>
    )
  }

  return (
    <SlideUpDialog
      id='createBaseProductHashModal'
      open={open}
      onClose={onClose}
      title={<h3 style={{ margin: '0px' }}>Add Product Info</h3>}
      actions={
        <>
          <PrimaryButton
            id='createBaseProductHashCreateButton'
            disabled={productInfo.id === '' || productInfo.productHashName === ''}
            onClick={async () => {
              var res = await createProductInfo()
              if (res === true) {
                productRefetch()
                onClose()
              }
            }}
          >
            Create
          </PrimaryButton>
        </>
      }
    >
      <DialogContent>
        <Grid container>
          <Grid item sm={6}>
            <TextField
              id='createBaseProductHashIdTextField'
              sx={{ width: '100%' }}
              value={productInfo ? productInfo.id : ''}
              onChange={e => setProductInfo(info => ({ ...info, id: e.target.value }))}
              label={'Base Product Id'}
              size='small'
            />
            <TextField
              id='createBaseProductHashNameTextField'
              sx={{ width: '100%', marginTop: '10px' }}
              value={productInfo ? productInfo.productHashName : ''}
              onChange={e => setProductInfo(info => ({ ...info, productHashName: e.target.value }))}
              label={'Product Hash'}
              size='small'
            />
            <div style={{ marginTop: '10px', width: '100%' }}>
              <TextField
                id='createBaseProductHashOrderTextField'
                inputProps={{ min: 1 }}
                type='number'
                sx={{ width: '50%' }}
                value={productInfo ? productInfo.order : 0}
                onChange={e => setProductInfo(info => ({ ...info, order: e.target.value }))}
                label={'Order'}
                size='small'
              />
              <Switch
                id='createBaseProductHashSoftwareSwitch'
                color='primary'
                checked={productInfo ? productInfo.isSoftware : false}
                onChange={e => setProductInfo(info => ({ ...info, isSoftware: e.target.checked }))}
              />
              Is Software
            </div>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <CustomSelect
                id='createBaseProductHashImageSelect'
                style={{
                  marginTop: '10px',
                  width: '100%'
                }}
                label={'Image'}
                options={data ? data : []}
                value={data && productInfo && productInfo.image && data.includes(productInfo.image) ? productInfo.image : ''}
                onChange={e => setProductInfo(info => ({ ...info, image: e.target.value }))}
              />
            )}
          </Grid>
          <Grid item sm={6}>
            <CenteredDiv>
              {productInfo && productInfo.image ? (
                <div
                  style={{
                    maxWidth: '300px',
                    minWidth: '300px',
                    display: 'flex'
                  }}
                >
                  {' '}
                  <img
                    id='createBaseProductHashImage'
                    style={{
                      maxHeight: '150px',
                      minHeight: '30%',
                      maxWidth: '100%',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      marginLeft: 'auto',
                      marginRight: 'auto'
                    }}
                    alt={`${productInfo.image}`}
                    src={`${getResourceUrl(env.runMode)}/images/products/${productInfo.image}`}
                  />
                </div>
              ) : (
                <div>Select product image...</div>
              )}
            </CenteredDiv>
          </Grid>
        </Grid>
      </DialogContent>
    </SlideUpDialog>
  )
}
