import { CustomDataGrid } from '@wavetronix/common-components'
import React, { useState } from 'react'
import { LEVEL_ROLE_ENUM_HASH } from '../../../utils/AccessLevelMap'
import { env } from '../../../index.js'

export default function UpdatesModalUsersTable(props) {
  const columns = [
    {
      field: 'id',
      headerName: 'Override',
      flex: 1,
      disableColumnMenu: true,
      sortComparator: props.overridesComparator,
      renderCell: id => props.renderSwitch(id.value)
    },
    { field: 'givenName', headerName: 'First Name', flex: 1, disableColumnMenu: true },
    { field: 'surname', headerName: 'Last Name', flex: 1, disableColumnMenu: true },
    { field: 'company', headerName: 'Company', flex: 1, disableColumnMenu: true },
    {
      field: 'updatesAccessLevel',
      headerName: 'Access',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: u => LEVEL_ROLE_ENUM_HASH[u.row.updatesAccessLevel[env.basicAuthentication.fromCompany]]
    },
    { field: 'email', headerName: 'Email', flex: 1, disableColumnMenu: true }
  ]

  const [pageSize, setPageSize] = useState(50)

  return (
    <div style={{ height: '700px', display: 'block' }}>
      <CustomDataGrid
        rows={props.users ? props.users : []}
        autoHeight
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={p => setPageSize(p.pageSize)}
        rowsPerPageOptions={[10, 20, 50, 100]}
        disableSelectionOnClick
        loading={props.loading}
        disableExtendRowFullWidth={true}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'id',
                sort: 'asc'
              }
            ]
          }
        }}
      />
    </div>
  )
}
