import { CustomDataGrid } from '@wavetronix/common-components'
import React, { useState } from 'react'
import compareAlphabetically from '../../../utils/compareAlphabetically'
import getDeviceName from '../../../utils/getDeviceName'
import dayjs from 'dayjs'

export default function UsersModalUpdatesTable(props) {
  const columns = [
    {
      field: 'override',
      headerName: 'Override',
      flex: 1,
      valueGetter: params => params.row.id,
      disableColumnMenu: true,
      sortComparator: props.overridesComparator,
      renderCell: data => props.renderSwitch(data.row)
    },
    {
      field: 'baseProductId',
      headerName: 'Type',
      flex: 1,
      disableColumnMenu: true,
      sortComparator: compareAlphabetically,
      valueGetter: u => getDeviceName(u.value)
    },
    { field: 'itemNumber', headerName: 'Item No.', flex: 1, disableColumnMenu: true },
    { field: 'version', headerName: 'Version No.', flex: 1, disableColumnMenu: true },
    { field: 'releasePhase', headerName: 'Release Phase', flex: 1, disableColumnMenu: true },
    {
      field: 'lastModified',
      headerName: 'Last Modified',
      flex: 1,
      disableColumnMenu: true,
      sortComparator: compareAlphabetically,
      valueGetter: u => dayjs(u.value).format('MM/DD/YYYY hh:mm A')
    }
  ]

  const [pageSize, setPageSize] = useState(50)

  return (
    <div style={{ height: '700px' }}>
      <CustomDataGrid
        rows={props.updates ? props.updates : []}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={p => setPageSize(p.pageSize)}
        rowsPerPageOptions={[10, 20, 50, 100]}
        disableSelectionOnClick
        loading={props.loading}
        disableExtendRowFullWidth={true}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'override',
                sort: 'asc'
              }
            ]
          }
        }}
      />
    </div>
  )
}
