import { useMsal } from '@azure/msal-react'
import { Chip, CircularProgress, Box } from '@mui/material'
import {
  CenteredDiv,
  RegularButton,
  SnackbarVariants,
  SlideUpDialog,
  HasAccess,
  DeleteButton
} from '@wavetronix/common-components'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import LicenseDefinitionsApi from '../../api/LicenseDefinitionsApi'
import ProductDefinitionsApi from '../../api/ProductDefinitionsApi'
import UpdatesApi from '../../api/UpdatesApi'
import getDeviceName from '../../utils/getDeviceName'
import { env } from '../../index.js'
const PAST_TENSE_MAP = {
  Hide: 'hid',
  Unhide: 'unhid',
  Delete: 'deleted'
}

const API_CALL_MAP = {
  updates: {
    Hide: async (msalInstance, account, update) => await UpdatesApi.hideOrUnhideUpdate(msalInstance, account, update.id, true),
    Unhide: async (msalInstance, account, update) => await UpdatesApi.hideOrUnhideUpdate(msalInstance, account, update.id, false),
    Delete: async (msalInstance, account, update) => await UpdatesApi.deleteUpdate(msalInstance, account, update.id)
  },
  'product definitions': {
    Hide: async (msalInstance, account, productDef) =>
      ProductDefinitionsApi.hideUnhideDefinition(msalInstance, account, productDef.itemNumber, true),
    Unhide: (msalInstance, account, productDef) =>
      ProductDefinitionsApi.hideUnhideDefinition(msalInstance, account, productDef.itemNumber, false),
    Delete: (msalInstance, account, productDef) =>
      productDef.state === 'Draft'
        ? ProductDefinitionsApi.deleteDraft(msalInstance, account, productDef.itemNumber)
        : ProductDefinitionsApi.archive(msalInstance, account, productDef.itemNumber)
  },
  'license definitions': {
    Hide: async (msalInstance, account, licenseDef) =>
      LicenseDefinitionsApi.hideUnhideDefinition(msalInstance, account, licenseDef.itemNumber, true),
    Unhide: (msalInstance, account, licenseDef) =>
      LicenseDefinitionsApi.hideUnhideDefinition(msalInstance, account, licenseDef.itemNumber, false),
    Delete: (msalInstance, account, licenseDef) =>
      licenseDef.state === 'Draft'
        ? LicenseDefinitionsApi.deleteDraft(msalInstance, account, licenseDef.itemNumber)
        : LicenseDefinitionsApi.archive(msalInstance, account, licenseDef.itemNumber)
  }
}

export default function ConfirmModal(props) {
  const [buttonsDisabled, setButtonsDisabled] = useState(false)

  const onClose = () => (buttonsDisabled ? null : props.onClose())

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const { instance, accounts } = useMsal()

  return (
    <SlideUpDialog
      id='confirmModal'
      open={props.open}
      onClose={onClose}
      maxWidth={'sm'}
      title={<h3 style={{ margin: '0px' }}>Confirm {props.action}</h3>}
      actions={
        <Box sx={{ display: 'flex', direction: 'row' }}>
          {props.action === 'Delete' && props.variant === 'updates' ? (
            <HasAccess env={env} allowedRoles={['Gojira Team Member']}>
              <DeleteButton
                style={{ marginLeft: '0px', marginRight: '225px' }}
                onClick={async () => {
                  let loadingBar = enqueueSnackbar(`Attempting to ${props.action}...`, SnackbarVariants.LOADING)

                  setButtonsDisabled(true)
                  await Promise.all(
                    props.data.map(update => {
                      return UpdatesApi.permanentDeleteUpdate(instance, accounts, update.id)
                    })
                  )
                    .then(async () => {
                      await props.refresh()
                      let object = props.data.length > 1 ? props.variant : props.variant.substr(0, props.variant.length - 1)
                      enqueueSnackbar(`Successful permanent ${PAST_TENSE_MAP[props.action]} ${object}`, SnackbarVariants.SUCCESS)
                    })
                    .catch(e => {
                      enqueueSnackbar(`An error occurred: ${e.message}`, SnackbarVariants.ERROR)
                    })
                    .finally(() => {
                      setButtonsDisabled(false)
                      closeSnackbar(loadingBar)

                      props.onClose()
                    })
                }}
              >
                Permanent Delete
              </DeleteButton>
            </HasAccess>
          ) : null}
          <Box sx={{ flex: '1 1 auto' }} />
          <DeleteButton
            id={`${props.action}ConfirmButton`}
            variant='contained'
            disabled={buttonsDisabled}
            onClick={async () => {
              let loadingBar = enqueueSnackbar(`Attempting to ${props.action}...`, SnackbarVariants.LOADING)
              setButtonsDisabled(true)
              await Promise.all(
                props.data.map(x => {
                  return API_CALL_MAP[props.variant.toLowerCase()][props.action](instance, accounts, x)
                })
              )
                .then(async () => {
                  await props.refresh()
                  let object = props.data.length > 1 ? props.variant : props.variant.substr(0, props.variant.length - 1)
                  enqueueSnackbar(`Successfully ${PAST_TENSE_MAP[props.action]} ${object}`, SnackbarVariants.SUCCESS)
                })
                .catch(e => {
                  enqueueSnackbar(`An error occurred: ${e.message}`, SnackbarVariants.ERROR)
                })
                .finally(() => {
                  setButtonsDisabled(false)
                  closeSnackbar(loadingBar)
                  props.onClose()
                })
            }}
          >
            {props.action === 'Delete' && props.variant === 'updates' ? `Mark ${props.action}` : props.action}
          </DeleteButton>

          <RegularButton id={`${props.action}CancelButton`} onClick={onClose} disabled={buttonsDisabled}>
            Cancel
          </RegularButton>
        </Box>
      }
    >
      <div style={{ margin: '10px' }}>
        Are you sure you want to {props.action.toLowerCase()}:
        <div style={{ height: '55%', width: '100%', overflow: 'auto' }}>
          {props.data.length > 0 ? (
            props.data.map((u, i) => (
              <div key={`updates-${i}`} style={{ marginTop: '5px' }}>
                <Chip
                  id={u.baseProductId}
                  label={`${getDeviceName(u.baseProductId)} - ${u.version}`}
                  variant='outlined'
                  color='primary'
                />
              </div>
            ))
          ) : (
            <CenteredDiv>
              <CircularProgress />
            </CenteredDiv>
          )}
        </div>
      </div>
    </SlideUpDialog>
  )
}
